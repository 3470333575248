import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch  } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import OneSignal from 'react-onesignal';
import Register from "./components/Mobile/Register";
import "bootstrap/dist/css/bootstrap-grid.css";
import "./global.css";
import RoutesDesktop from "./RoutesDesktop";
import SideMenu from "./components/Desktop/SideMenu";
import SidebarUsers from "./components/Desktop/SideMenuUsers";
import Navbar from "./components/Desktop/NavBar";
import LoginPage from "./components/LoginPage";
import RoutesMobile from "./RoutesMobile";
import TopBar from "./components/Mobile/TopBar";
import FloatingMenu from "./components/Mobile/FloatingMenu";
import { useAuthentication } from "./useAuthentication"; // Import the custom hook
import RegisterSuccess from "./components/Mobile/RegistrationSuccess";
import ResetPassword from "./components/Mobile/ResetPassword";
import { useDispatch } from 'react-redux';
import { fetchMessagesById } from './redux/messageSlice';
import SidebarProducer from "./components/Desktop/SidebarProducer";
import SidebarClient from "./components/Desktop/SidebarClient";
import SidebarMarket from "./components/Desktop/SideMenuMarket";
import SidebarOrders from "./components/Desktop/SideMenuOrders";
import { AuthContext } from "./components/AuthContext";
import RegisterUser from "./components/Desktop/RegisterUser";




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn"));
  
  // const { isLoggedIn, setIsLoggedIn } = useAuthentication(); // Use the custom hook
//  console.log(localStorage)

 const dispatch = useDispatch();


 const [initialized, setInitialized] = useState(false);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    if (!initialized) {
        OneSignal.init({ appId: '9ad51b9e-0dae-48cb-9adc-63b265a480fb' }).then(() => {
            setInitialized(true);
            OneSignal.Slidedown.promptPush();
        });
    }
    const userId = localStorage.getItem("userId");
        if (userId) {
            dispatch(fetchMessagesById(userId));
        }
}, [initialized]);




  return (
    <div className="App">
      <Router>
        {!isLoggedIn ? (
          <Switch>
            <Route path="/register" component={Register} />
            <Route path="/users/registar" component={RegisterUser} /*allowedRoles={'admin'}*/ />   
            <Route path="/registration-success" component={RegisterSuccess} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route path="/" ><LoginPage onLogin={() => setIsLoggedIn(true)} /></Route>
          </Switch>
        ) : (
          <div className={`dashboard ${isMobile ? "mobile" : "desktop"}`}>
            {isMobile  && !localStorage.getItem("user").includes("admin") ? (
              <div className="mobile">
                <div className="top-container">
                  <TopBar />
                </div>
                <div className="main-container">
                  <RoutesMobile  />
                </div>
                <div className="floating-menu-container">
                  <FloatingMenu />
                </div>
              </div>
            ) : <InnerApp />}
          </div>
        )}
      </Router>
    </div>
  );
}

function InnerApp() {
  const location = useLocation();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const {user, isAuthenticated} = useContext(AuthContext);
console.log(isAuthenticated)
  let sideMenuClient, sideMenuDashboard, sideMenuMarket, sideMenuProducer, sideMenuOrders, sideMenuUsers = null
  
  if(isAuthenticated)
  {
   sideMenuDashboard = location.pathname.includes("/dashboard") || location.pathname.includes("/settings") || localStorage.user.includes('admin') && location.pathname.includes("/notifications")
  sideMenuUsers = location.pathname.includes("/users")
  sideMenuOrders = location.pathname.includes("/orders")
  sideMenuMarket = location.pathname.includes("/market")
  sideMenuProducer = user && user.includes("producer")
  sideMenuClient = user && user.includes("client")}


 useEffect(() => {
  
//console.log(localStorage)
}, [location]);

  return (
    
      <>
      {localStorage.getItem("user")!== null ? (
        <><div className="navbar-container">
                <Navbar />
              </div><div className="content-container">
                  {!isMobile && <div className="sidemenu-container">
                    {sideMenuDashboard && <SideMenu isMobile={isMobile} />}
                    {sideMenuUsers && <SidebarUsers isMobile={isMobile} />}
                    {sideMenuProducer && <SidebarProducer isMobile={isMobile} />}
                    {sideMenuClient && <SidebarClient isMobile={isMobile} />}
                    {sideMenuMarket && <SidebarMarket isMobile={isMobile}/>}
                    {sideMenuOrders && <SidebarOrders isMobile={isMobile}/>}
                  </div>}
                  <div className="main-container-desktop">
                    <RoutesDesktop />
                  </div>
                </div></>) : (<></>)}
      </>
   
  );
}

export default App;
