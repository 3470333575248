import React, { useState, useEffect, useContext } from "react";
import Spinner from "../../assets/WeblandSpinner";
import { useDispatch } from 'react-redux';
import { addProduct } from '../../redux/basketSlice';
import "./MobileMarket.css";
import { URL } from "../../config";
import AddItemModal from "./AddItemModal";
import { AuthContext } from "../AuthContext";

const MobileMarketItem = () => {
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {token} = useContext(AuthContext);
  const dispatch = useDispatch();

  const handleAddToBasket = () => {
    const productWithQuantity = {
      ...product,
      quantity: quantity,
    };
    setShowModal(true);
    dispatch(addProduct(productWithQuantity));
  };

  const handleCloseModal = () =>{
    setShowModal(false);
  }

  const currentURL = window.location.href;
  const parts = currentURL.split("/");
  const id = parts[parts.length - 1];

  const fetchProduct = () => {
    fetch(`${URL}/webland-api/product/${id}`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      }})
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  return (
    <div className="col-12">
      {isLoading ? (<div className="market-container-center">
        <Spinner/>
        </div>) : (

    <>
      <div className="title-wrapper" style={{ fontSize: "22px" }}>
        {String(product.type).toUpperCase()}
      </div>
      <div className="product-wrapper">
        <p className="description">{product.description}</p>
        <p className="package">Embalagem: {product.packageType}</p>
        <p className="dimensions">Dimensão: {product.dimensions}</p>
        <p className="price">
          Preço: <b>{product.price}€/uni</b>
        </p>
        <div className="quantity-controls">
          <label htmlFor="quantity">Quantidade</label>
          <div className="input-group">
            <input
              className="quantity-input"
              type="number"
              min="1"
              value={quantity}
              onChange={handleQuantityChange}
            />
            <div className="quantity-buttons">
              <span className="quantity-button-control" onClick={increaseQuantity}>
               <div style={{marginTop:"-0.3rem"}}>+</div> 
              </span>
              <span className="quantity-button-control" onClick={decreaseQuantity}>
                <div style={{marginTop:"-0.3rem"}}>-</div>
              </span>
            </div>
          </div>
        </div>

        <div className="select-buttons">
          <button
            className="col-12 default-button"
            style={{ marginBottom: "0.4rem" }}
            onClick={handleAddToBasket}
          >
            Adicionar
          </button>
          <button
            className="col-12 default-button"
            style={{ background: "var(--secondary-color)", marginTop: "0rem" }}
            onClick={() => window.history.back()}
          >
            Cancelar
          </button>
        </div>
      </div>
      </>)}
      {showModal && <AddItemModal isOpen={showModal} onClose={handleCloseModal} title={String(product.type).toUpperCase()} message={"Artigo adicionado com sucesso!!!"}/>}
    </div>
  );
};

export default MobileMarketItem;
