import { createSlice, createAsyncThunk, configureStore } from '@reduxjs/toolkit';
import { URL } from '../config';

// Async thunk to fetch messages
export const fetchMessagesById = createAsyncThunk(
  'messages/fetchMessagesById',
  async (userId) => {
    const response = await fetch(`${URL}/webland-api/user/messages/${userId}`);
    const data = await response.json();
    return data ? data : [];
  }
);

export const markMessageAsRead = createAsyncThunk(
  'messages/markMessageAsRead',
  async (messageId) => {
    const response = await fetch(`${URL}/webland-api/user/messages/${messageId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const data = await response.json();
    return data ? data : null;
  }
);

export const messageSlice = createSlice({
  name: 'messages',
  initialState: {
    count: 0,
    messages: [],
    status: 'idle'
  },
  reducers: {
    setMessages: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.messages = action.payload;
        state.count = action.payload.filter(message => !message.read).length;
      }
    },
    decrementCount: (state) => {
      state.count -= 1;
    },
    incrementCount: (state) => {
      state.count += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessagesById.fulfilled, (state, action) => {
      if (Array.isArray(action.payload)) {
        state.messages = action.payload;
        state.count = action.payload.filter(message => !message.read).length;
        state.status = 'loaded';
      }
    });
    builder.addCase(markMessageAsRead.fulfilled, (state, action) => {
      const messageId = action.meta.arg;
      const messageIndex = state.messages.findIndex(msg => msg._id === messageId);

      if (messageIndex > -1) {
        state.messages[messageIndex].read = true;
        state.count -= 1;
      }
    });
  },
});

export const { setMessages, decrementCount, incrementCount } = messageSlice.actions;

export const selectUnreadMessageCount = (state) => state.message.count;

export default messageSlice.reducer;
