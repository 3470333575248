import React, { useState, useEffect, useMemo, useContext } from "react";
import { Line, Bar } from "react-chartjs-2";
import { prepareBolotaChartData } from "./prepareChartData";
import "./Bolota.css";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import { AuthContext } from "../AuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BolotaHistory = () => {
  const [lineChartData, setLineChartData] = useState(null);
  const [barChartData, setBarChartData] = useState({ datasets: [] }); // Initialized with an empty datasets array

  const [isLoading, setIsLoading] = useState(false);

  const {token} = useContext(AuthContext);


  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color');
  const focusColor = getComputedStyle(document.documentElement).getPropertyValue('--focus-color');


  const sumQuantitiesByType = (data) => {
    return data?.reduce((acc, item) => {
      const type = item._id.acornType;
      acc[type] = (acc[type] || 0) + item.totalQuantity;
      return acc;
    }, {});
  };

  const getColorForAcornType = (acornType) => {
    const colorMap = {
      Carvalho: focusColor,
      Sobreiro: secondaryColor,
      Azinheira: primaryColor,
    };
    return colorMap[acornType] || "gray";
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${URL}/webland-api/warehouse/bolota-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
      const responseBar = await fetch(
        `${URL}/webland-api/warehouse/bolota-history-aggregate`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
      const data = await response.json();
      setBarChartData(prepareBarChartData(await responseBar.json()));

      const dataLineChart = prepareBolotaChartData(data);
      setLineChartData(dataLineChart);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching bolota history data:", error);
      setIsLoading(false);
    }
  };

  const prepareBarChartData = (data) => {
    const summedData = sumQuantitiesByType(data);
    console.log(summedData);

    const barChartData = {
      labels: ["2023"],
      datasets: [
        {
          label: "Sobreiro",
          data: [summedData["Sobreiro"]],
          backgroundColor: secondaryColor,
          stack: "Stack 0",
        },
        {
          label: "Carvalho",
          data: [summedData["Carvalho"]],
          backgroundColor: focusColor,
          stack: "Stack 0",
        },
      ],
    };

    return barChartData;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true, // To use the point style instead of the box
          generateLabels: chart => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, i) => ({
              text: dataset.label.toUpperCase(),
              fillStyle: dataset.borderColor, // Use the dataset border color
              strokeStyle: dataset.borderColor,
              lineWidth: dataset.borderWidth,
            
            }));
          },
        },
        position:"bottom"
      },
      title: {
        display: true,
        text: "Produção/Tempo",
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Disables gridlines for the x-axis
        },

        title: {
          display: true,
          text: "Data",
        },
      },
      y: {
        grid: {
          display: false, // Disables gridlines for the x-axis
        },
        stacked: false,
        title: {
          display: true,
          text: "Quantidade (kg)",
        },
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Total/Espécie",
      },
    },
    scales: {
      x: {
        title: {
          grid: {
            display: false, // Disables gridlines for the x-axis
          },
          display: false,
       
        },
      },
      y: {
        grid: {
          display: false, // Disables gridlines for the x-axis
        },
        stacked: true,
        title: {
          display: true,
          text: "Quantidade (Kg)",
        },
      },
    },
  };

  return (
    <div className="conversion-rate-container bolota-card" style={{ display: 'flex', height: '400px' }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="col-9" style={{ flex: 1, marginRight:"4rem" }}>
            {lineChartData && (
              <Line data={lineChartData} options={lineChartOptions} />
            )}
          </div>
          <div className="col-3" style={{flex:1, paddingBlock:"1rem"}}>
            {barChartData && (
              <Bar data={barChartData} options={barChartOptions} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BolotaHistory;
