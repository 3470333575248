import React, { useState, useEffect, useContext } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import Spinner from "../../assets/WeblandSpinner";
import "./OrdersClient.css"; 

const OrdersClient = () => {
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {token} = useContext(AuthContext)

  useEffect(() => {
    fetchOrders();
  }, []);



  const fetchOrders = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/order/orders/${localStorage.id}`,  {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        }});
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setOrders(data);
      setIsLoading(false);
      console.log("Encomendas", data)
    } catch (error) {
      console.error(error);
    }
  };

  const toggleExpand = (orderId) => {
    setExpandedOrderId((prevId) => (prevId === orderId ? null : orderId));
  };


  const handleUpdateStatus = async (orderId, newStatus) => {
    // await updateOrderStatus(orderId, newStatus); // Replace with your API call to update order status
    // fetchOrders();
  };

  return (
    <div className="user-tables-container-wrapper">
      <div className="tables-container mb-3">
        <div className="col-12 card-table">
          <div className="col-12 card-table-header">
            <div className="col-12 card-table-title">Histórico de Encomendas</div>
          </div>
          <div className="col-12 card-table-body">
            {isLoading ? (
              <div className="spinner-container">
                <Spinner />
              </div>
            ) : (
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Preço Total</th>
                    <th>Estado</th>
                    <th>Data</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <React.Fragment key={order._id}>
                      <tr
                        className={expandedOrderId === order._id ? "expanded selected-row" : "normal-row"}
                        onClick={() => toggleExpand(order._id)}
                      >
                        <td>{order._id}</td>
                        <td>{order.totalAmount}€</td>
                        <td>{order.status}</td>
                        <td>{order.orderDate}</td>
                        <td className="col-3"><button size={10}>Ver Fatura</button></td>
                        <td>
                          {expandedOrderId === order._id ? (
                            <RiArrowDropRightLine size={15} />
                          ) : (
                            <RiArrowDropDownLine size={15} />
                          )}
                        </td>
                      
                      </tr>
                      {expandedOrderId === order._id && (
                       
                         
                    order.products.map((productInOrder) => (
                         <div key={productInOrder.product._id} className="product">
                           <div>img</div>
                           <div>{productInOrder.product.type}</div>
                           <div>{productInOrder.product.price}€</div>
                           <div>{productInOrder.quantity}</div>
                         </div>
                       ))
                    
                     
                         
                      
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersClient;

