import React, { useState, useEffect } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
} from "react-icons/ri";
import { URL } from "../../config";
import { decrementCount } from "../../redux/messageSlice";
import { useDispatch } from "react-redux";
import Spinner from "../../assets/WeblandSpinner";
import "./Notifications.css";

const Notifications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15); // You can adjust this based on the screen height if needed
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [expandedNotificationIndex, setExpandedNotificationIndex] = useState(-1);
  const [messages, setMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const fetchMessagesById = async () => {
    try {
      const response = await fetch(
        `${URL}/webland-api/user/messages/${localStorage.getItem("userId")}`
      );
      const data = await response.json();
      if (data && Array.isArray(data) && data.length !== messages?.length) {
        setMessages(data);
        setIsLoading(false);
      }
      response.status===404 && setIsLoading(false)
    } catch (error) {
      console.error("Error fetching messages:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMessagesById();
  }, []);

  function formatDate(inputDate) {
    const [day, month, year] = inputDate.split("/");
    const monthNames = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${day} ${monthName}`;
  }

  const updateMessageReadStatus = async (messageId) => {
    try {
      const response = await fetch(
        `${URL}/webland-api/user/messages/${messageId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            read: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update read status.");
      }
      return response.json();
    } catch (error) {
      console.error("Error updating message read status:", error);
    }
  };

  const NotificationRow = React.memo(({ notification, index }) => {
    const isExpanded = expandedNotificationIndex === index;

    const handleExpand = async () => {
      // If the message is not already read, update its status
      if (!notification.read) {
        dispatch(decrementCount());
        const updatedMessage = await updateMessageReadStatus(notification._id);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === notification._id ? updatedMessage : msg
          )
        );
      }
      setExpandedNotificationIndex(isExpanded ? -1 : index);
    };

    return (
      <div
        className={`col-12 desktop-notifications-row  ${
          !notification.read ? "desktop-new-notifications" : ""
        }`}
        id={index}
      >
        <div
          className="col-12 desktop-notifications-summary"
          onClick={() => handleExpand()}
        >
           {!notification.read && <div className="new-notification-symbol" style={{ textAlign: "center" }}>
            {<b>NEW</b>}
          </div>}
          {notification.read && <div  style={{ width: "3rem", textAlign: "center" }}>
            {<b></b>}
          </div>}
          <div className="col-1" style={{ textAlign: "center" }}>
            {formatDate(new Date(notification.createdAt).toLocaleDateString())}
          </div>
          <div className="col-2" style={{ color: "var(--focus-color)", fontWeight:"600"}}>
            {String(notification.type).toUpperCase()}
          </div>
          <div className="col-6">{notification.title}</div>

          <div className="col-2 desktop-notifications-summary-right">
            <div className="btn-round-expand" style={{padding:0}}>
              <RiArrowDropDownLine size={15} />
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="col-12 desktop-notifications-details">
            <div className="col-3"></div>
            <div className="col-7 desktop-notifications-content">
              {notification.content}
            </div>
          </div>
        )}
      </div>
    );
  });
  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleNotifications = messages?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(messages?.length / itemsPerPage);

  const pageRange = {
    start: Math.max(1, currentPage - 2),
    end: Math.min(totalPages, currentPage + 2),
  };

  return (
    <>
      <div className="col-12 desktop-notifications-list-container">
        
        <div className="desktop-title-wrapper">Mensagens</div>
      </div>
      <div className="desktop-notifications-list-container">
        <div
          className=" mt-1 mb-2 p-2"
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "x-small",
            color: "var(--secondary-color)",
          }}
        >
         
          <div className="col-2" style={{ textAlign: "center"}}>Data</div>
          <div className="col-2">Tipo</div>
          <div className="col-6" style={{ textAlign: "center" }}>
            Título
          </div>

          <div className="col-2 desktop-notifications-summary-right"></div>
        </div>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <Spinner />
          </div>
        ) : (
          visibleNotifications?.map((notification, index) => (
            <div
            key={index}
              style={{
                animation: `fadeIn ease 0.5s ${index * 0.05}s forwards`,
                opacity: 0,
              }}
            >
              <NotificationRow
                key={notification._id || index}
                notification={notification}
                index={index}
              />
            </div>
          ))
        )}
      </div>
      {totalPages > 1 && (
        <div className="desktop-pagination">
          {(
            <button
              className="desktop-page-button-arrow"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1 }
            >
              <RiArrowDropLeftLine size={65} />
            </button>
          )}
          {Array.from(
            { length: pageRange.end - pageRange.start + 1 },
            (_, i) => {
              const page = pageRange.start + i;
              return (
                <button
                  key={page}
                  className={`desktop-page-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              );
            }
          )}
          {(
            <button
              className="desktop-page-button-arrow"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage >= totalPages }
            >
              <RiArrowDropRightLine size={65} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;
