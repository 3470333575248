
import { configureStore  } from '@reduxjs/toolkit';
import basketReducer from './basketSlice';
import messageReducer from './messageSlice';

export const store = configureStore({
  reducer: {
    basket: basketReducer,
    message: messageReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});
