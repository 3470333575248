import React, { useState, useEffect, useContext } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import "./MobileAccount.css";
import { AuthContext } from "../AuthContext";

const MobileAccount = () => {
  const [userData, setUserData] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {token} = useContext(AuthContext);
  const history = useHistory();
  const handlePasswordChange = () => {
    setShowChangePasswordModal(true);
  };

  const handleConfirmPasswordChange = (oldPassword, newPassword) => {
    setShowChangePasswordModal(false);
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };


  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const userId = localStorage.getItem("userId"); 
    if (userId) {
      fetch(`${URL}/webland-api/user/${userId}`,  {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        }})
      .then((response) => response.json())
      .then((data) => {
          setUserData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      <div className="col-12">
        <div className="title-wrapper">Conta</div>
      </div>
     
      {isLoading ?  <div className="col-12 warehouse-content-center">
          <Spinner />
        </div> 
        :  
        <div className="col-12 dashboard-container">
        <div className="input-group" style={{marginTop:"-2rem"}}>
          <label className="input-group-item" style={{padding:"0.1rem 0rem"}}>Username</label>
          <input
            className="input-group-item"
            type="text"
            value={userData?.username || ""}
            disabled={true}
          />
          <br/>
          <label className="input-group-item"  style={{padding:"0.1rem 0rem"}}>E-mail</label>
          <input
            className="input-group-item"
            type="text"
            value={userData?.email || ""}
            disabled={true}
          />
          <br/>
          <label className="input-group-item"  style={{padding:"0.1rem 0rem"}}>Password</label>
          <input
            className="input-group-item"
            type="password"
            value={userData?.password || ""}
            disabled={true}
          />
        </div>
        <button className="button-change" onClick={handlePasswordChange}>
          Alterar
        </button>
        <ChangePasswordModal
          isOpen={showChangePasswordModal}
          onClose={handleCloseChangePasswordModal}
          onConfirm={handleConfirmPasswordChange}
        />
        <br/>
        <br/>
        <button className="default-button" onClick={()=> history.push("/detalhes")}>
          + Detalhes
        </button>
      </div>}
    </>
  );
};

export default MobileAccount;
