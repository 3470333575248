import React, { useState, useEffect, useContext } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import SuccessModal from "./SuccessModal";
import "./AddBolotaModal.css";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import { useParams } from "react-router-dom";



const AddBolotaModal = ({ isOpen, onClose, onAdd }) => {
  const [selectedSpecies, setSelectedSpecies] = useState("Selecione a espécie");
  const [quantity, setQuantity] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [priceRangeData, setPriceRangeData] = useState({});
  const [userData, setUserData] = useState(null); // Variável para armazenar os dados do usuário
  const [useremail,setuseremail]= useState(null);
  const [idProducer,setidProducer]= useState(null);
  const {token} = useContext(AuthContext)
  const [speciesOptions, setSpeciesOptions] = useState([
    { label: "Carvalho Português", value: "carvalho_portugues" },
    { label: "Azinheira", value: "Azinheira" },
    { label: "Carvalho Negral", value: "carvalho_negral" },
    { label: "Carvalho Alvarinho", value: "carvalho_alvarinho" },
    { label: "Sobreiro", value: "sobreiro" }
  ]);
    
  

  const fetchUserData = async () => {
    
  
 
    console.log("localStorage",localStorage)
    let idteste="";
    if(localStorage.user==="admin"){
      const urlParams = new URLSearchParams(window.location.search);
      const EmailFromURL = urlParams.get("email");
      setuseremail(EmailFromURL);
    }
    else{
      idteste=localStorage.id;
    }
    
   

    try {
      const token = localStorage.getItem("token"); // Supondo que o token esteja armazenado no localStorage
      if (!token) {
        console.error("Token de autorização não encontrado");
        return;
      }
      const response = await fetch(`${URL}/webland-api/producer/${idteste}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Incluindo o token de autorização no cabeçalho da solicitação
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserData(data); // Armazene os dados do usuário na variável
        setuseremail(userData[0].email);
        console.log("data", userData[0].email);
      } else {
        console.error("Falha ao buscar dados do usuário");
      }
    } catch (error) {
      console.error("Erro:", error);
    }
  };
 






 
  const handleAddClick = async () => {
    if (selectedSpecies && quantity && price) {
      try {
        const response = await fetch(`${URL}/webland-api/producer/addStock`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
           body: JSON.stringify({
            email:useremail,
            acornType: selectedSpecies,
            quantity: parseFloat(quantity),
            price: parseFloat(price),
          }),
        });

        if (response.ok) {
          setSuccessModalOpen(true);
          onAdd(selectedSpecies, quantity, price);
          setQuantity("");
          setPrice("");
          
        } else {
          console.error("Failed to add stock");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    onClose(); 
  };

  useEffect(() => {
    const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const emailProducer = urlParams.get('email');
        console.log("emailProducer", emailProducer)
     
    const testeproducer = async (emailProducer) => {
      console.log("entrei cá", emailProducer)
     
        fetch(`${URL}/webland-api/producer/idByEmail/${emailProducer}`)
        .then((response) => response.json())
        .then((data) => {
          setidProducer(data);
       
        })
        .catch((error) => {
          console.error("Erro ao buscar os dados do usuário:", error);
        });
      }
      if (emailProducer) {
        testeproducer(emailProducer);
      }

    const fetchPriceRangeData = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/producer/price-range`);
        if (response.ok) {
          const data = await response.json();
          setPriceRangeData(data); // Store price range data in state
          console.log(priceRangeData);
        } else {
          console.error("Failed to fetch price range data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    
    fetchUserData();
    fetchPriceRangeData();
    setQuantity("");
    setPrice("");
    setSelectedSpecies("Selecione a espécie");
  }, [isOpen, idProducer]);

  const isPriceValid = () => {
    if (!price || price === "") {
      return false;
    }
  
    const selectedSpeciesPriceData = priceRangeData[selectedSpecies];
    if (!selectedSpeciesPriceData) {
      return false; // Não há dados de preço para a espécie selecionada
    }
  
    const { bottomPrice, topPrice } = selectedSpeciesPriceData;
    const parsedPrice = parseFloat(price);
    return parsedPrice >= 0 && parsedPrice <= topPrice;
  };
  
 
  return (
    <>
      {isOpen && (
        <div className="add-bolota-modal-overlay" style={{zIndex:100}}>
          <div className="col-6 add-bolota-modal-content">
            <button className="add-bolota-modal-close" onClick={onClose}>
              X
            </button>

            <div className="add-bolota-modal-title">Bolota</div>
            <div className="col-12 add-bolota-modal-form">
              <div className="add-bolota-dropdown">
                <div
                  className="add-bolota-dropdown-toggle"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedSpecies}
                  <RiArrowDropDownLine size={15} />
                </div>
                {dropdownOpen && (
                  <div className="add-bolota-dropdown-options">
                    {speciesOptions?.map((option) => (
                      <div
                        key={option.value}
                        className="add-bolota-dropdown-option"
                        onClick={() => {
                          setSelectedSpecies(option.value);
                          setDropdownOpen(false);
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12 input-group-item">
                <input
                  className="add-bolota-input"
                  type="number"
                  placeholder="Quantidade (kg)"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <input
                  className="add-bolota-input"
                  type="number"
                  placeholder={
                    !selectedSpecies.includes("Selecione a espécie")
                      ? `${priceRangeData[selectedSpecies]/*.bottomPrice/*} - ${priceRangeData[selectedSpecies]/*.topPrice*/} (€/kg)`
                      : "-.- (€/kg)"
                  }
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="add-bolota-modal-buttons">
              <button
                className="col-12 default-button"
                onClick={handleAddClick}
               /* disabled={
                  selectedSpecies.includes("Selecione a espécie") ||
                  !quantity ||
                  !isPriceValid()
                }*/
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      )}
      <SuccessModal
        isOpen={successModalOpen}
        onClose={closeSuccessModal}
        message="Quantidade adicionada com sucesso!"
        title="Bolota"
      />
    </>
  );
};

export default AddBolotaModal;
