import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet-routing-machine";
import L from "leaflet";
import ProducerIcon from "./ProducerIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import ClientIcon from "./Client.svg";
import TransformerIcon from "./TransformerIcon";
import { renderToString } from "react-dom/server";
import MapMarker from "./MapMarker";

import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "./Map.css";

const getCustomIcon = (locationType, locationId, markerHighlight) => {
  let iconComponent;
  //console.log(locationType, markerHighlight)
  if (locationType === "produtor") {
    iconComponent =
      locationId === markerHighlight ? (
        <ProducerIcon color={"var(--focus-color)"} />
      ) : (
        <ProducerIcon color={"var(--secondary-color)"} />
      );
  } else if (locationType === "transformador") {
    iconComponent = <TransformerIcon />;
  } else {
    iconComponent = (
      <div
        style={{
          backgroundColor: "var(--focus-dark-color)",
          width: "10px",
          height: "10px",
          marginTop:"1.5rem",
          borderRadius: "50%",
        }}
      ></div>
    );
  }

  const svgMarkup = renderToString(iconComponent);

  return L.divIcon({
    html: svgMarkup,
    iconSize: [30, 30],
    iconAnchor: [10, 30],
    className: "custom-marker",
    popupAnchor: [0, -30],
  });
};

const typeConverter = (value) => {
  switch (value) {
    case "produtor":
      return "producer";
    case "transformador":
      return "transformer";
    case "cliente":
      return "client";

    default:
      return "transporter";
  }
};

// function Routing({ producer, transformer, customer }) {
//   const map = useMap();
//   const history = useHistory();
//  console.log(transformer, producer, customer )
//   useEffect(() => {
//     if (!map) return;

//     // Define positions
//     const producerPosition = [producer.lat, producer.long];
//     const customerPosition = [customer.lat, customer.long];
//     //const transformerPosition = [transformer.lat, transformer.long];

//     // Leaflet Routing Machine with waypoints
//     const routingControl = L.Routing.control({
//       waypoints: [L.latLng(producerPosition), L.latLng(customerPosition)],
//       lineOptions: {
//         styles: [{ color: "var(--focus-color)", weight: 3 }],
//       },
//       show: false,
//       addWaypoints: false,
//       routeWhileDragging: true,
//       draggableWaypoints: false,
//       fitSelectedRoutes: true,
//       createMarker: function (i, waypoint, n) {
//         // Use your own marker here depending on the waypoint index
//         const markerIcon =
//           i === 0
//             ? getCustomIcon("produtor", producer._id, null)
//             : getCustomIcon("cliente", customer._id, null);
//         return L.marker(waypoint.latLng, {
//           icon: markerIcon,
//           draggable: false,
//         });
//       },
//     }).addTo(map);

//     // Listen for the 'routesfound' event
//     routingControl.on('routesfound', function(e) {
//         var routes = e.routes;
//         var summary = routes[0].summary;
//         // summary.totalDistance is the distance of the route in meters
//         console.log('Total distance: ' + summary.totalDistance + ' meters');
//         // You can convert this distance into any units you prefer.
//       });

//     // Clean up on unmount
//     return () => map.removeControl(routingControl);
//   }, [map, producer, customer]);

//   return null;
// }

function Routing({ producer, transformer, customer, routeDistance }) {
  const map = useMap();
  const [distances, setDistances] = useState({
    producerToTransformer: "0 km",
    transformerToCustomer: "0 km"
  });

  // This effect initializes the routing controls and the info control
  useEffect(() => {
    if (!map || !producer || !transformer || !customer) return;

    const producerPosition = L.latLng(producer.lat, producer.long);
    const transformerPosition = L.latLng(transformer.lat, transformer.long);
    const customerPosition = L.latLng(customer.lat, customer.long);

    // Initialize the first routing control
    const control1 = L.Routing.control({
      waypoints: [producerPosition, transformerPosition],
      lineOptions: { styles: [{ color: 'var(--secondary-color)', opacity: 0.6, weight: 4 }] },
      createMarker: () => null,
      addWaypoints: false,
      routeWhileDragging: false,
      show: false, // Don't automatically show route instructions
    }).addTo(map);

    // Initialize the second routing control
    const control2 = L.Routing.control({
      waypoints: [transformerPosition, customerPosition],
      lineOptions: { styles: [{ color: 'var(--focus-color)', opacity: 0.6, weight: 4 }] },
      createMarker: () => null,
      addWaypoints: false,
      routeWhileDragging: false,
      show: false, // Don't automatically show route instructions
    }).addTo(map);

    // Event listener to update distances when routes are found
    control1.on('routesfound', function(e) {
      const distance = (e.routes[0].summary.totalDistance / 1000).toFixed(2) + ' km';
      setDistances(d => ({ ...d, producerToTransformer: distance }));
    });

    control2.on('routesfound', function(e) {
      const distance = (e.routes[0].summary.totalDistance / 1000).toFixed(2) + ' km';
      setDistances(d => ({ ...d, transformerToCustomer: distance }));
    });

    // Create the info control to display distances
    // const infoControl = L.control({ position: 'topright' });
    // infoControl.onAdd = function () {
    //   const div = L.DomUtil.create('div', 'info');
    //   div.innerHTML = `<h4>Info da ROTA</h4>
    //                    <p>Produtor - Transformador: ${distances.producerToTransformer}</p>
    //                    <p>Transformador - Cliente: ${distances.transformerToCustomer}</p>`;
    //   return div;
    // };
    // infoControl.addTo(map);

    // Cleanup function to run when component unmounts
    return () => {
      if (control1) map.removeControl(control1);
      if (control2) map.removeControl(control2);
     // if (infoControl) map.removeControl(infoControl);
    };
  }, [map, producer, transformer, customer]);

  // This effect updates the info control whenever distances change
  useEffect(() => {
    const infoControlDiv = map?.getContainer().querySelector('.info');
    if (infoControlDiv) {
      infoControlDiv.innerHTML = `<h4>Info da ROTA</h4>
                                  <p>Produtor - Transformador: ${distances.producerToTransformer}</p>
                                  <p>Transformador - Cliente: ${distances.transformerToCustomer}</p>`;
    }
    routeDistance(distances)
  }, [distances, map]);

  return null;
}

const MapRoute = ({ producer, transformer, customer, routeData }) => {
  const history = useHistory();
  const mapRef = useRef();


  
  // Use useEffect for DOM manipulations
  useEffect(() => {
    const map1Container = document.getElementById("map2");
    if (map1Container) {
      const tilePane =
        map1Container.getElementsByClassName("leaflet-tile-pane")[0];
      tilePane.style.webkitFilter =
        "grayscale(80%) brightness(1.1) saturate(1.1)";
      tilePane.style.filter = "grayscale(100%) brightness(1.1) saturate(1.1)";
    }
  }, []);
  // Assuming producer and customer are objects with latitude and longitude properties
  const producerPosition = [producer.lat, producer.long];
  const transformerPosition = [transformer.lat, transformer.long]; // Now using transformer position
  const customerPosition = [customer.lat, customer.long];

  const handleDistances = (data) =>{

    routeData(data);
  }

  const waypoints = [
    { ...producer, type: 'produtor' }, 
    { ...transformer, type: 'transformador' }, 
    { ...customer, type: 'cliente' }
  ];
  // Define the positions for the polyline
  const polylinePositions = [producerPosition, customerPosition];

  const generateCustomMarker = (location, locationType) => {
    const icon = getCustomIcon(locationType, location?._id, null); // Adapted to use without markerHighlight

    return (
      <Marker position={[location.lat, location.long]} icon={icon}>
        <Popup className="map-popup-content-wrapper" keepInView={true}>
          {/* ... Popup content ... */}
          <button
            className="default-button"
            onClick={() =>
              history.push(
                `/dashboard/metrics/${typeConverter(locationType)}?id=${
                  location?._id
                }`
              )
            }
          >
            Selecionar
          </button>
        </Popup>
      </Marker>
    );
  };

  return (
    <MapContainer
      ref={mapRef}
      id="map2"
      center={[39.6, -8]}
      zoom={7}
      style={{ height: "45vh", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Markers for producer and customer */}
      {waypoints.map((location, index) => (
    <MapMarker key={index} location={location} markerHighlight={location} />
  ))}
     

      {/* Polyline to connect producer and customer */}
      <Polyline positions={polylinePositions} color="var(--primary-color)" />
      <Routing producer={producer} customer={customer} transformer={transformer} routeDistance={handleDistances}/>
    </MapContainer>
  );
};

export default MapRoute;
