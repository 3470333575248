import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import GeolocalAddress from "../Mobile/GeoLocalAddress.js";
import MySelect from "../Mobile/MySelect.js";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { URL } from "../../config";

const EditProducer = ({ userData, userId, onUpdateClient }) => {
  const [producerInfo, setProducerInfo] = useState(userData);
  const [producerDataFetched, setProducerDataFetched] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [optionSelectedCertifications, setoptionSelectedCertifications] = useState([]);
  const [type_service, settype_service] = useState([]);

  const optionsSpecies = [
    { value: 'carvalho_alvarinho', label: 'Carvalho Alvarinho (Q. robur)' },
    { value: 'carvalho_negral', label: 'Carvalho Negral (Q. pyrenaica)' },
    { value: 'carvalho_portugues', label: 'Carvalho Português (Q. faginea)' },
    { value: 'Azinheira', label: 'Azinheira (Q. rotundifolia)' },
    { value: 'Sobreiro', label: 'Sobreiro (Q. suber)' },
  ];


  // Função para buscar dados do produtor
  const fetchProducerData = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/producer/produceremail/${userData.email}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProducerInfo(data);
        setProducerDataFetched(true);
        console.log("resultado", data);
      } else {
        console.error("Erro na requisição:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do produtor:", error);
    }
  };
  
const fetchCertificates = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/certificates/get_certificates`);
      if (!response.ok) {
        throw new Error('Erro ao buscar os certificados');
      }
      const data = await response.json();
    // Filtrar certificados onde o type é "produtor"
    const producerCertNames = data
        .filter(cert => cert.type === "produtor")
        .map(cert => ({ value: cert.name, label: cert.name }));
      setCertificates(producerCertNames);
  
      const producerCert = data
        .filter(cert => cert.type === "transformador")
        .map(cert => ({ value: cert.type_service, label: cert.type_service }));
        settype_service(producerCert);
      console.log("certificado",data)
    } catch (error) {
      console.error("Erro ao buscar os certificados:", error.message);
    }
  };

  // useEffect para buscar dados do produtor quando o componente é montado
  useEffect(() => {
    if (!producerDataFetched) {
      fetchProducerData();
      fetchCertificates();
    }
  }, [userData.email, producerDataFetched]);

  // Função para lidar com mudanças nos inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProducerInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, [name]: value };
      onUpdateClient(updatedInfo); // Chama onUpdateClient com as alterações
      return updatedInfo;
    });
  };
  const handleSpecies = selected => {
    setOptionSelected(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
   setProducerInfo((prevInfo) => {
    const updatedInfo = { ...prevInfo,  species: selectedSpecies,  }
    onUpdateClient(updatedInfo);
    return updatedInfo;
});
  };
const handleCertifications = selected => {
    setoptionSelectedCertifications(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducerInfo((prevInfo) => {
        const updatedInfo = { ...prevInfo,   certifications: selectedCertifications,}
        onUpdateClient(updatedInfo);
        return updatedInfo;

    });
};

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const mappedSpecies = producerInfo?.species?.map(speciesValue => {
    return optionsSpecies.find(option => option.value === speciesValue);
  });
 
  const mappedCertifications = producerInfo?.certifications?.map(certificationValue => {
    return certificates.find(option => option.value === certificationValue);
  });


  const animatedComponents = makeAnimated();

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Editar Produtor
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={producerInfo?.name || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">NIF:</label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={producerInfo?.nif || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Telefone:</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={producerInfo?.phoneNumber || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      
        <div className="col-12" style={{ paddingLeft: "0rem" }}>
        <label htmlFor="sepcies">Espécies:</label>
       <div  className="col-xl-12 " style={{  paddingLeft:"0rem"}}>
        <MySelect required
          options={optionsSpecies}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ MultiValue, ...animatedComponents }}

          onChange={handleSpecies}
          allowSelectAll={true}
           value={mappedSpecies} 
          />
              
        </div>
        <div className="col-xl-12 ">
            <label htmlFor="role">Certificações:</label>
            <MySelect required
                options={certificates}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ MultiValue, ...animatedComponents }}

                onChange={handleCertifications}
                allowSelectAll={true}
                value={mappedCertifications} 
                />
          </div>
          </div>
     
 

      <div className="title-register-wrapper mt-3 mb-2">Morada</div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
        </h5>
        <h6 style={{ color: "var(--secondary-color)" }}>
          Clique no marcador para preenchimento automático da morada
        </h6>
        <GeolocalAddress
          onProducerInfoChange={(info) =>
            setProducerInfo((prevInfo) => {
              const updatedInfo = { ...prevInfo, ...info };
              onUpdateClient(updatedInfo);
              return updatedInfo;
            })
          }
          flyToMyLocation={true}
        />
      </div>

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={producerInfo?.lat || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={producerInfo?.long || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={producerInfo?.street || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="postalCode">Código Postal:</label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          value={producerInfo?.postalCode || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">Localidade:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={producerInfo?.city || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="region">Zona:</label>
        <input
          type="text"
          id="region"
          name="region"
          value={producerInfo?.region || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">País:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={producerInfo?.country || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default EditProducer;
