import React, { useState } from 'react';
import RegisterBolotaCard from './Bolota';
import BolotaHistory from './BolotaHistory';
import Certifications from './Certifications';
import ManageConversionRates from './System/ManageConversionRates';

function Rates() {
  const [activeTab, setActiveTab] = useState('manageConversionRates');

  return (
    <div className='col-12'>
        <ul className="tab-list">
            {/* <li 
              className={activeTab === 'bolotaHistory' ? 'active' : ''} 
              onClick={() => setActiveTab('bolotaHistory')}
            >
              Armazém
            </li>
            <li 
              className={activeTab === 'registerBolotaCard' ? 'active' : ''} 
              onClick={() => setActiveTab('registerBolotaCard')}
            >
              Intervalo de Preços
            </li> */}
            <li 
              className={activeTab === 'manageConversionRates' ? 'active' : ''} 
              onClick={() => setActiveTab('manageConversionRates')}
            >
              Tabelas de Rendimento
            </li>
            {/* <li 
              className={activeTab === 'certifications' ? 'active' : ''} 
              onClick={() => setActiveTab('certifications')}
            >
              Certificações
            </li> */}
        </ul>
        
        <div className="tab-content">
            {activeTab === 'bolotaHistory' && <BolotaHistory />}
            {activeTab === 'registerBolotaCard' && <RegisterBolotaCard />}
            {activeTab === 'manageConversionRates' && <ManageConversionRates />}
            {activeTab === 'certifications' && <Certifications />}
        </div>
    </div>
  );
}

export default Rates;

