import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem("user"));
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("isLoggedIn")); // Boolean conversion
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedUser = localStorage.getItem('user');

        if (storedToken && storedUser) {
            setToken(storedToken);
            setUser(storedUser);
            setIsAuthenticated(true);
        }
    }, []);

    const loginUser = (userData) => {
        // //console.log(userData)
        // localStorage.setItem('token', userData.token);
        // localStorage.setItem('user', userData.role); 

        setUser(userData.role);
        setToken(userData.token);
        setIsAuthenticated(true);
    };

    const logoutUser = () => {
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
        // Consider using a more integrated approach with React Router for redirection if applicable
        localStorage.clear();
        window.location.pathname = "/";
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, loginUser, logoutUser, token }}>
            {children}
        </AuthContext.Provider>
    );
};
