// DeleteConfirmationModal.js
import React from "react";
import "./DeleteModal.css"; // Substitua pelo nome do seu arquivo CSS

const DeleteConfirmationModal = ({ show, onClose, onConfirm ,title}) => {
  if (!show) return null;

  return (
    <div className="delete-modal-overlay">
      <div className="delete-modal-content">
      <button className="delete-modal-close-button" onClick={onClose} >
          X
        </button>
        <p>{title}</p>
        <div className="delete-modal-buttons">
          <button onClick={onConfirm}>Sim</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
