import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Admin/Dashboard";
import UsersAdmin from "./components/Admin/UsersAdmin";
import OrdersAdmin from "./components/Admin/OrdersAdmin";
import MarketAdmin from "./components/Admin/MarketAdmin";
import Producer from "./components/Producer/ProducerLayout";
import ProfileProducer from "./components/Producer/ProfileProducer";
import Client from "./components/Client/ClientLayout";
import Produtores from "./components/Admin/Produtores";

import Transformadores from "./components/Admin/Transformadores";
import ProducerMetrics from "./components/Admin/ProducerMetrics";
import ProducerOrders from "./components/Producer/ProducerMetrics";
import TransformadorMetrics from "./components/Admin/TranformadoresMetrics";
import RegisterUser from "./components/Desktop/RegisterUser";
import EditUser from "./components/Desktop/EditUser";
import Notifications from "./components/Desktop/Notifications";
import MarketClient from "./components/Client/DesktopMarket";
import DesktopBasket from "./components/Client/DesktopBasket";
import Settings from "./components/Admin/Settings";
import OrderClient from "./components/Client/OrdersClient";
import OrdersWithMap from "./components/Admin/OrdersWithMap";
import OrderDetails from "./components/Admin/OrderDetails";
import Clientes from "./components/Admin/Clientes";
import ClientMetrics from "./components/Admin/ClientMetrics";
import RegisterSuccess from "./components/Mobile/RegistrationSuccess";
import AdminProfile from "./components/Admin/AdminProfile";
import EditUsers from "./components/Admin/EditUsers";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import DashboardAdmin from "./components/Admin/DashboardAdmin";
import ForbiddenPage from './components/Forbidden403';
import NotFoundPage from "./components/NotFound404";
import Simulation from "./components/Admin/Simulation";
import UserInfoAdmin from "./components/Admin/UserInfoAdmin";
import MarketList from "./components/Admin/MarketList";
import ProductDetails from "./components/Admin/MarketProductDetails";
import Rates from "./components/Admin/AdminRates";

import AddProduct from "./components/Admin/AddProduct";
import EditProduct from "./components/Admin/EditProduct";




const Routes = () => {
  return (
    <Switch>
      
      {/* ROTAS PARA TODOS */}
      <Route path="/login" component={LoginPage} /> 
      <Route path="/users/registar" component={RegisterUser} /*allowedRoles={'admin'}*/ />
      <Route path="/registration-success" component={RegisterSuccess} />   
      {/* <ProtectedRoute exact path="/dashboard/admin" component={DashboardAdmin} />  */}
      <ProtectedRoute exact path="/notifications" component={Notifications} allowedRoles={['admin', 'client', 'producer']}/>  

    {/* ROTAS PARA O ADMINISTRADOR */}
      <ProtectedRoute exact path="/dashboard" component={DashboardAdmin} allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/dashboard/produtores" component={Produtores} allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/dashboard/metrics/producer" component={ProducerMetrics} allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/dashboard/transformadores" component={Transformadores} allowedRoles={'admin'} />  
      <ProtectedRoute exact path="/dashboard/metrics/transformadores" component={TransformadorMetrics} allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/dashboard/clientes" component={Clientes} allowedRoles={'admin'}/>
      <ProtectedRoute exact path="/dashboard/metrics/client" component={ClientMetrics} allowedRoles={'admin'}/>
      
      <ProtectedRoute exact path="/market" component={MarketAdmin} allowedRoles={'admin'} />
      <ProtectedRoute exact path="/market/list" component={MarketList} allowedRoles={'admin'} />
      <ProtectedRoute exact path="/market/product" component={ProductDetails} allowedRoles={'admin'} />
      <ProtectedRoute exact path="/market/AddProduct" component={AddProduct}  allowedRoles={'admin'}/> 
      <ProtectedRoute exact path="/market/EditProduct" component={EditProduct}  allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/market/rates" component={Rates}  allowedRoles={'admin'}/>
      
      <ProtectedRoute exact path="/users" component={UsersAdmin} allowedRoles={'admin'} />  
      <ProtectedRoute exact path="/users/info" component={UserInfoAdmin} allowedRoles={'admin'} />
     
 
      <ProtectedRoute exact path="/orders" component={OrdersAdmin} allowedRoles={'admin'} />  
      <ProtectedRoute exact path="/orders/map" component={OrdersWithMap} allowedRoles={'admin'} />   
      <ProtectedRoute exact path="/orders/details" component={OrderDetails} allowedRoles={'admin'} />
      <ProtectedRoute exact path="/orders/simulation" component={Simulation} allowedRoles={'admin'} />
      
      <ProtectedRoute exact path="/settings" component={Settings}  allowedRoles={'admin'}/>  
      <ProtectedRoute exact path="/users/profile" component={AdminProfile}  allowedRoles={'admin'}/>   
      
      
      {/* ROTAS PARA O PRODUTOR */}
      <ProtectedRoute exact path="/producer" component={Producer} allowedRoles={['producer', 'admin']} />
      <ProtectedRoute exact path="/encomendas" component={ProducerOrders} allowedRoles={'producer'} />
      <ProtectedRoute exact path="/ProfileProducer" component={ProfileProducer} allowedRoles={'producer'} />
     
      
      {/* ROTAS PARA O CLIENTE */}
      <ProtectedRoute exact path="/client-market" component={MarketClient}  allowedRoles={'client'}/>
      <ProtectedRoute exact path="/client-basket" component={DesktopBasket}  allowedRoles={'client'}/>
      <ProtectedRoute exact path="/orderclient" component={OrderClient}  allowedRoles={'client'}/>  
      <ProtectedRoute exact path="/editusers" component={EditUsers} allowedRoles={'admin'} />  
      <ProtectedRoute exact path="/EditUser" component={EditUser}  allowedRoles={['producer', 'admin']}  />  

      <Route path="/forbidden" component={ForbiddenPage}/>
      <Route path="*" component={NotFoundPage} />
      {/* <Redirect to="/login" /> */}
    </Switch>
  );
};

export default Routes;
