import React from "react";
import { Route, Switch } from "react-router-dom";
import MobileClient from "./components/Client/MobileClient";
import MobileMarket from "./components/Client/MobileMarket";
import MobileMarketItem from "./components/Client/MobileMarketDescription";
import MobileBasket from "./components/Client/MobileBasket";
import MobileProducer from "./components/Producer/MobileProducer";
import MobileWarehouse from "./components/Producer/MobileWarehouse";
import MobileOrder from "./components/Producer/MobileOrder";

import MobileAccount from "./components/Mobile/MobileAccount";
import MobileAccountDetalhes from "./components/Mobile/MobileAccountDetalhes";
import MobileNotifications from "./components/Mobile/MobileNotifications";

const RoutesMobile = () => {

  return (
    <Switch>

        {/*------------------- ROTAS PARA CLIENTE -------------------*/}
        <Route exact path="/client" component={MobileClient} />
        <Route path="/market" component={MobileMarket} />
        <Route path="/marketDescription/:id" component={MobileMarketItem} />
        <Route path="/basket" component={MobileBasket} />

        {/*------------------- ROTAS PARA PRODUTOR -------------------*/}
        <Route exact path="/producer" component={MobileProducer} />
        <Route path="/warehouse" component={MobileWarehouse} />
        <Route path="/order" component={MobileOrder} />

        {/*------------------- ROTAS AMBOS -------------------*/}
        <Route path="/account" component={MobileAccount} />
        <Route path="/detalhes" component={MobileAccountDetalhes} />
        <Route path="/notifications" component={MobileNotifications} />

    </Switch>
  );
};

export default RoutesMobile;
