import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { URL } from "../../config";
import { AiOutlineUserAdd } from "react-icons/ai";
import EditClient from "./EditClient";
import EditProducer from "./EditProducer";
import EditTransformer from "./EditTransformer";
import "./EditUser.css"; 
import SuccessModal from "./SuccessModal"; 
import { LoadingModal } from "./LoadingModal"; 

const EditUser = () => {
  const [userData, setUserData] = useState({});
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [error, setError] = useState("");
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get('id');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleClientInfo();
    fetch(`${URL}/webland-api/user/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setUsername(data.username);
        setEmail(data.email);
        setRole(data.role);
      })
      .catch((error) => {
        console.error("Erro ao buscar os dados do usuário:", error);
      });
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 

    try {
      const response = await fetch(`${URL}/webland-api/user/editUser/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          password,
          role,
          clientInfo,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error);
        setIsLoading(false);
        return;
      }
      console.log("dados enviados", clientInfo);
      setIsModalOpen(true); 
    } catch (error) {
      console.error("Erro ao editar o utilizador:", error);
      setError("Ocorreu um erro ao editar o utilizador. Tente novamente mais tarde.");
    } finally {
      setIsLoading(false); 
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    history.goBack();
  };
  

  const handleClientInfo = (updatedClientInfo) => {
    setClientInfo(updatedClientInfo);
  };

  return (
    <div className="register-user-container">
      <div className="col-md-4 register-user-form">
        <div className="title-register-wrapper">
          <AiOutlineUserAdd
            size={30}
            style={{ marginRight: "1rem", position: "relative", top: "0.3rem" }}
          />
          Editar utilizador
        </div>
        <br />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="username">
              Username:{" "}
              {isUsernameAvailable && username !== "" ? (
                <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                username !== "" && (
                  <span className="availability-icon" style={{ color: "red" }}>
                    &#x2717;
                  </span>
                )
              )}
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="form-control"
            />
            </div>
            <div className="form-group">
            <label htmlFor="email">
              Email:{" "}
              {isEmailAvailable && email !== "" ? (
                <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                email !== "" && (
                  <span className="availability-icon" style={{ color: "red" }}>
                    &#x2717;
                  </span>
                )
              )}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
            </div>
            <div className="form-group">
            <label htmlFor="role">Tipo de utilizador:</label>
            <select
              id="role"
              name="role"
              required
              style={{width: "70%"}}
              className="form-control"
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="producer">Produtor</option>
              <option value="transformer">Transformador</option>
              {localStorage.user === "admin" && (
              <option value="admin">Administrador</option>
            )}
              <option value="client">Cliente</option>
            </select>
          </div>
          <button className="default-button" type="submit">
            Salvar
          </button>
        </form>
        {error && <div>{error}</div>}
      </div>
      {role === "client" && (
        <div className="edit-client-container">
          <EditClient userData={userData} userId={userId} onUpdateClient={handleClientInfo} />
        </div>
      )}

{role === "producer" && (
        <div className="edit-client-container">
          <EditProducer userData={userData} userId={userId} onUpdateClient={handleClientInfo} />
        </div>
      )}
{role === "transformer" && (
        <div className="edit-client-container">
          <EditTransformer userData={userData} userId={userId} onUpdateClient={handleClientInfo} />
        </div>
      )}

       <SuccessModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Sucesso"
        message="As informações do utilizador foram atualizadas com sucesso."
      />
       <LoadingModal isOpen={isLoading} /> 
    </div>
  );
};

export default EditUser;
