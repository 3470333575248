import React, { useContext, useState } from "react";
import "./mobile-client.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import { selectBasket, removeProduct, clearBasket, updateQuantity  } from "../../redux/basketSlice";
import ConfirmModal from "./ConfirmModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { AuthContext } from "../AuthContext";
import { URL } from "../../config";

const DesktopBasket = () => {
  const [ShowConfirmModal, setShowConfirmModal] = useState(false);
  const {token} = useContext(AuthContext);
  
  const basket = useSelector(selectBasket);
  const dispatch = useDispatch();


  const handleUpdateQuantity = (productId, newQuantity) => {
    if(newQuantity >=1)
    dispatch(updateQuantity({ productId, quantity: newQuantity }));
  };

  const handleRemoveProduct = (productId) => {
    // Dispatch the action to remove the product
    dispatch(removeProduct(productId));
  };

  const handleShowModal = () =>{
    setShowConfirmModal(true)
    submitOrder()
  }

  const handleCloseModal = () =>{
    setShowConfirmModal(false)
    dispatch(clearBasket());
  }

  const submitOrder = async () => {
    const orderData = {
      customer: localStorage.id,
      products: basket.products.map(item => ({
        productId: item._id,
        type: item.type,
        quantity: item.quantity,
        // Include other necessary fields from the item
      })),
      totalAmount: totalIncludingDelivery,
      orderDate: new Date() // Or any other required date format
    };
  
    try {
      const response = await fetch(`${URL}/webland-api/order/addorder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Replace with your actual token
        },
        body: JSON.stringify(orderData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('Order response:', responseData);
      // Handle the response, e.g., showing a success message or redirecting
    } catch (error) {
      console.error('Error submitting order:', error);
      // Handle errors, e.g., showing an error message
    }
  };
  

  const Breadcrumb = () => {
    const history = useHistory();
  
    const navigateTo = (path) => {
      history.push(path);
    };
  
    return (
      <div className="breadcrumb-wrapper">
        <span className="breadcrumb-item" onClick={() => navigateTo('/client-market')}>
          Marketplace
        </span>
        <span> &gt; </span>
        <span className="breadcrumb-item" onClick={() => navigateTo('/client-basket')}>
          Cesto
        </span>
    
      
      </div>
    );
  };


  const totalProductPrice = basket.products.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const totalIncludingDelivery = totalProductPrice + 12;

  return (
    <>
      <div className="col-12 mb-4">
        <Breadcrumb />
      </div>
      {basket.products.length !== 0 ? (
        <>
          <div className="market-basket-container">
            {basket.products.map((item, index) => (
              <>
                <div className="col-12 market-basket-list" key={item._id}>
                  <div className="col-7 market-basket-list-info">
                    <p className="market-basket-list-title">
                      <span></span>
                      {String(item.type).toUpperCase()}
                    </p>
                    <div className="market-description">
                      <p
                        style={{
                          color: "var(--secondary-color)",
                          marginTop: "-0.2rem",
                        }}
                      >
                        Características
                      </p>
                      <div className="market-package">
                        Embalagem: {item.packageType}
                      </div>
                      <div className="market-dimensions">
                        Dimensão: {item.dimensions}
                      </div>
                      <div className="market-dimensions">Peso: </div>
                    </div>
                  </div>
                  <div className="col-3 market-basket-list-purchase">
                    <div>{item.price}€/uni</div>
                    <div>x {item.quantity}</div>
                    <div className="market-total">
                      Total:{" "}
                      <b style={{ fontSize: "22px" }}>
                        {item.price * item.quantity}€
                      </b>
                    </div>
                  </div>
                  <div className="market-order-control-buttons">

                  <button  className="market-quantity-item-btn" onClick={() => handleUpdateQuantity(item._id, item.quantity + 1)}>+</button>
                  <button  className="market-quantity-item-btn mt-1" onClick={() => handleUpdateQuantity(item._id, item.quantity - 1)}>-</button>
               
                  <button
                    className="market-remove-item-btn mt-3"
                    onClick={() => handleRemoveProduct(item._id)}
                    >
                    <FaRegTrashAlt color="white" size={13} />
                  </button>
                 
                    </div>
        
                </div>
                <br />
              </>
            ))}
          </div>

          <div className="market-basket-container">
            <div className="market-basket-list">
              <div className="col-7 market-basket-list-info">
                <p className="market-basket-list-title">
                  <span></span>
                  {String("entrega").toUpperCase()}
                </p>
                <div className="market-description">
                  <div
                    style={{
                      color: "var(--secondary-color)",
                      marginTop: "-0.2rem",
                    }}
                  >
                    Tempo estimado de entrega
                  </div>
                  <div className="market-package">8 Dias</div>
                  <div
                    style={{
                      color: "var(--secondary-color)",
                      marginTop: "-0.2rem",
                    }}
                  >
                    Custo de envio
                  </div>
                  <div className="market-package">12€</div>
                </div>
              </div>
              <div className="col-3 market-basket-list-purchase">
                <div className="market-total" style={{marginRight:"2.5rem"}}>
                  <div>Total: </div>
                  <b style={{ fontSize: "32px" }}>{totalIncludingDelivery}€</b>
                </div>
              </div>
            </div>
            <button className="col-12 market-default-button mt-4" style={{marginTop:"0rem"}} onClick={handleShowModal}>Confirmar</button>
          </div>
          {ShowConfirmModal && <ConfirmModal isOpen={ShowConfirmModal} onClose={handleCloseModal} title={""} message={"Encomenda registada com sucesso!!!"}/>}
        </>
      ) : (
        <div className="market-dashboard-container" style={{color:"var(--secondary-color)", fontSize:"larger"}}>Sem artigos no cesto</div>
      )}
    </>
  );
};

export default DesktopBasket;
