import React, { useEffect, useState,useContext } from "react";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import { useParams, withRouter  } from "react-router-dom";
import { AiOutlineContacts} from "react-icons/ai";
import { TbMapPinCheck } from "react-icons/tb";
import GeolocalAddress from "../Mobile/GeoLocalAddress";
import SuccessModal from "../Mobile/SuccessModal"; 
import MySelect from "../Mobile/MySelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const EditUser = ({ userToEdit, onClose, history  }) => {
  const [editedUser, setEditedUser] = useState({ ...userToEdit });
  const [producer,setProducer]= useState(null);
  const [rota,setRota]= useState(null);
  const [optionSelected, setOptionSelected] = useState([]);
  const [optionSelectedClient, setoptionSelectedClient] = useState([]);
  const [optionSelectedCertifications, setoptionSelectedCertifications] = useState([]);
  const [certificates, setCertificates] = useState([]);
 
  const [type_service, settype_service] = useState([]);
    const [optionSelectedactivity_sector, setoptionSelectedactivity_sector] = useState([]);

  const [isNifValid, setIsNifValid] = useState(true);
  const [isNifAvailable, setIsNifAvailable] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [user,setUser]= useState(null);
  const [error, setError] = useState("");
  const {token} = useContext(AuthContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { id } = useParams();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "nif") {
      const isValidNif = /^\d{9}$/.test(value);
      setIsNifValid(isValidNif);
    }

    if (name === "phoneNumber"){
     // console.log("numero", value)
      const isValidPhoneNumber = /^\d{9}$/.test(parseInt(value));    
      setIsValidPhone(isValidPhoneNumber);
    }
    
    setProducer((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  
  const optionsSpeciesClient = [
    { value: 'Industria_Alimentar', label: 'Indústria Alimentar' },
    { value: 'Industria_Quimica', label: 'Indústria Química' },
    { value: 'Retalhista', label: 'Retalhista' },
    { value: 'Restauracao', label: 'Restauração' },
    { value: 'Padaria_Pastelaria', label: 'Padaria/Pastelaria' },
    { value: 'Biomassa', label: 'Biomassa' },
    { value: 'Outra', label: 'Outra' },
  ];

  const [processTypes, setProcessTypes] = useState({
    secagem: false,
    descasque: false,
    taninos: false,
    torra: false,
    moagem: false,
    limpeza: false,
  });

  const optionsSpeciesTrans = [
    { value: 'Indústria Alimentar', label: 'Indústria Alimentar' },
    { value: 'Indústria Química', label: 'Indústria Química' },
    { value: 'Biorrefinarias', label: 'Biorrefinarias' },
    
  ];
  const optionsSpecies = [
    { value: 'carvalho_alvarinho', label: 'Carvalho Alvarinho (Q. robur)' },
    { value: 'carvalho_negral', label: 'Carvalho Negral (Q. pyrenaica)' },
    { value: 'carvalho_portugues', label: 'Carvalho Português (Q. faginea)' },
    { value: 'Azinheira', label: 'Azinheira (Q. rotundifolia)' },
    { value: 'Sobreiro', label: 'Sobreiro (Q. suber)' },
  ];
  const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'left', height: '100%', gap:10 }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null} 
            style={{ margin: 0, padding:0 }}
          />
          <label style={{ margin: 0 }}>{props.label}</label>
        </div>
      </components.Option>
    );
  };
  

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const mappedSpecies = producer?.species?.map(speciesValue => {
    return optionsSpecies.find(option => option.value === speciesValue);
  });
  // Mapear os valores de producer.certifications para objetos correspondentes em certificates
const mappedCertifications = producer?.certifications?.map(certificationValue => {
  return certificates.find(option => option.value === certificationValue);
});
const mappedactivity_sector = producer?.activity_sector?.map(activity_sector => {
  return optionsSpeciesTrans.find(option => option.value === activity_sector);
});
const mappedtype_service = producer?.type_service?.map(activity_sector => {
  return type_service.find(option => option.value === activity_sector);
});
const mappedSpeciesClient = producer?.category?.map(activity_sector => {
  return optionsSpeciesClient.find(option => option.value === activity_sector);
});
console.log("mappedSpeciesClient", mappedSpeciesClient)


  const handleProducerInfoChange = (info) => {
    setProducer((prevInfo) =>({
      ...prevInfo,
      ...info
    }));
  };

  const handleSpeciesClient = selected => {
    setoptionSelectedClient(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
    setProducer(prevInfo => ({
      ...prevInfo,
      category: selectedSpecies, // Salvando a string no estado
    }));
  };
  
  const handleAddressChange = (field, value) => {
    setProducer((prevInfo) => ({
      ...prevInfo,
      [field]: value,
    }));
  };
  const handleSpecies = selected => {
    setOptionSelected(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
   setProducer(prevInfo => ({
      ...prevInfo,
      species: selectedSpecies, // Salvando a string no estado
    }));
  };
  const handleCertifications = selected => {
    setoptionSelectedCertifications(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducer(prevInfo => ({
      ...prevInfo,
      certifications: selectedCertifications,
    }));
  };
  const handleCertificationsTrans = selected => {
    settype_service(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducer(prevInfo => ({
      ...prevInfo,
      type_service: selectedCertifications,
    }));
  };


  const handleactivity_sector=selected => {
    setoptionSelectedactivity_sector(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducer(prevInfo => ({
      ...prevInfo,
      activity_sector: selectedCertifications,
    }));
  };

  

  const handleProcessTypeChange = (e) => {
    const { name, checked } = e.target;
    console.log("o que recebo aqui",name ,checked)
    setProducer((prevProducerInfo) => ({
      ...prevProducerInfo,
       [name]: checked,
     }));
    setProcessTypes((prevProcessTypes) => ({
      ...prevProcessTypes,
      [name]: checked,
      
    }));
    console.log("o que recebo aqui",name ,checked)
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id");
    const userrole = urlParams.get("role");
console.log("local",userId, userrole);

if(userrole==="producer"){
  setRota("producer");
}
if(userrole==="client"){
  setRota("client");
}
if(userrole==="transformer"){
  setRota("transformer");
}
  const fetchData = async () => {
 

    try {
      const response = await fetch(`${URL}/webland-api/${rota}/editusers/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
       // throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setProducer(data[0]);
      setUser(data[1]);
     // if (data[0].secagem) {
        //setProcessTypes(data[0].secagem,data[0].descasque, data[0].taninos, data[0].torra, data[0].moagem, data[0].limpeza);

        const { secagem, descasque, taninos, torra, moagem, limpeza } = data;

      setProcessTypes({
        secagem: data[0].secagem || false,
        descasque: data[0].descasque || false,
        taninos: data[0].taninos || false,
        torra: data[0].torra || false,
        moagem: data[0].moagem || false,
        limpeza:  data[0].limpeza || false,
      });
     // }
      //console.log("data",processTypes);
  
    } catch (error) {
      console.error(error);
    }
  
}

const fetchCertificates = async () => {
  try {
    const response = await fetch(`${URL}/webland-api/certificates/get_certificates`);
    if (!response.ok) {
      throw new Error('Erro ao buscar os certificados');
    }
    const data = await response.json();
  // Filtrar certificados onde o type é "produtor"
  const producerCertNames = data
      .filter(cert => cert.type === "produtor")
      .map(cert => ({ value: cert.name, label: cert.name }));
    setCertificates(producerCertNames);

    const producerCert = data
      .filter(cert => cert.type === "transformador")
      .map(cert => ({ value: cert.type_service, label: cert.type_service }));
      settype_service(producerCert);
    console.log("certificado",data)
  } catch (error) {
    console.error("Erro ao buscar os certificados:", error.message);
  }
};

fetchData();
fetchCertificates();
  }, [rota]); 

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id");
    const userrole = urlParams.get("role");
//console.log("local",userId, userrole);
if(userrole==="producer"){
  setRota("producer");
}
if(userrole==="client"){
  setRota("client");
}
if(userrole==="transformer"){
  setRota("transformer");
}

const response = await fetch(`${URL}/webland-api/${rota}/editusers/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ producerData: producer, userData: user }),
    });
    if (!response.ok) {
     // throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Dados atualizados com sucesso",producer);
    setShowSuccessModal(true); 
    //onClose();
  } catch{}
};
  

  return (
    <div>
      <div className="col-6">
        <div className="">
        
          <h2>Editar Utilizador</h2>
          <form onSubmit={handleSaveChanges}>
            <div className="form-group">
              <label htmlFor="username">Nome de Utilizador:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={user?.username}
                onChange={handleInputChange}
             
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={user?.email}
                onChange={handleInputChange}
               
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Nova Senha:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={user?.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirmar Nova Senha:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={user?.confirmPassword}
                onChange={handleInputChange}
               
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Tipo de Utilizador:</label>
              <select
                id="role"
                name="role"
                value={user?.role}
                onChange={handleInputChange}
                required
              >
                <option value="producer">Produtor</option>
                <option value="client">Cliente</option>
                <option value="transformer">Transformador</option>
              </select>
            </div>


              
                <div className="producer-info">
                <div className="col-6 title-register-wrapper mt-3 mb-2">
                  <AiOutlineContacts
                    size={35}
                    style={{
                      marginRight: "1rem",
                      position: "relative",
                      top: "0.7rem",
                      color: "var(--primary-color)",
                    }}
                  />
                  Contactos
                </div>
                <br />
                <div className="gps-group-wrapper">
                <div className="form-group" style={{marginTop:"2%"}}>
               
                  <label htmlFor="name">Nome / Empresa: </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={producer?.name}
                    onChange={handleBasicInfoChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{marginLeft:"2%"}}>
                  <label htmlFor="nif">
                    NIF:{" "}
                    {isNifAvailable && isNifValid && producer?.nif !== "" ? (
                      <span className="availability-icon" style={{ color: "green" }}>
                        &#x2713;
                      </span>
                    ) : (
                      producer?.nif !== "" && (
                        <span className="availability-icon" style={{ color: "red" }}>
                          &#x2717;
                        </span>
                      )
                    )}
                  </label>
                  <input
                    type="text"
                    id="nif"
                    name="nif"
                    value={producer?.nif}
                    onChange={handleBasicInfoChange}
                    className="form-control"
                  />
                </div>
                </div>  
          
                <div className="col-xl-4 col-md-8 col-sm-8 form-group">
                  <label htmlFor="phoneNumber">Telefone:{producer?.phoneNumber !== "" ? (
                      <span className="availability-icon" style={{ color: "green" }}>
                        &#x2713;
                      </span>
                    ) : (
                      producer?.phoneNumber !== "" && (
                        <span className="availability-icon" style={{ color: "red" }}>
                          &#x2717;
                        </span>
                      )
                    )}</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={producer?.phoneNumber}
                    onChange={handleBasicInfoChange}
                    className="form-control"
                  />
                   
                </div>
                {(user?.role === "producer" ) && (
                  <div>
                <div className="col-xl-12 col-md-12 col-sm-12 form-group">
      Espécies:
       </div> 
       <div  className="col-xl-6 " style={{  paddingLeft:"0rem"}}>
        <MySelect required
          options={optionsSpecies}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}

          onChange={handleSpecies}
          allowSelectAll={true}
           value={mappedSpecies} 
          />
        </div>
        <div className="col-xl-6 ">
            <label htmlFor="role">Certificações:</label>
            <MySelect required
                options={certificates}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}

                onChange={handleCertifications}
                allowSelectAll={true}
                value={mappedCertifications} 
                />
          </div>
          </div>
            )}
{(user?.role === "transformer" ) && (
  <div>
<div className="col-xl-12">Sector de Atividade:</div>
      <div className="col-12">
        <div className="col-6" style={{ paddingLeft: "0rem" }}>
          <MySelect
            required
            options={optionsSpeciesTrans}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleactivity_sector}
            allowSelectAll={true}
            value={mappedactivity_sector}
          />
        </div>
        <div className="form-group">
          <label>Tipos de Processo:</label>
          <div className="checkbox-group">
            {Object.entries(processTypes).map(([process, value]) => (
              <label key={process} className="checkbox-label">
                <input
                  type="checkbox"
                  name={process}
                  checked={value}
                  onChange={handleProcessTypeChange}
                />
                {process.charAt(0).toUpperCase() + process.slice(1)}
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="col-xl-6">
        <label htmlFor="role">Tipo de Serviço:</label>
        <MySelect
          required
          options={type_service}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={handleCertificationsTrans}
          allowSelectAll={true}
          value={mappedtype_service}
        />
      </div>
      </div>
)}
{(user?.role === "client" ) && (
<div className="col-6 " style={{  paddingLeft:"0rem"}}>
            <label htmlFor="role">Área de Atividade:</label>
            <div  className="col-12" style={{  paddingLeft:"0rem"}}>
            <MySelect required
options={optionsSpeciesClient}
isMulti
closeMenuOnSelect={false}
hideSelectedOptions={false}
components={{ Option, MultiValue, animatedComponents }}

onChange={handleSpeciesClient}
allowSelectAll={true}
value={mappedSpeciesClient}
/>
</div>
      </div>
)}
                <div className="title-register-wrapper mt-3 mb-2">
                  <TbMapPinCheck
                    size={35}
                    style={{
                      marginRight: "1rem",
                      position: "relative",
                      top: "0.7rem",
                      color: "var(--primary-color)",
                    }}
                  />
                  Morada
                </div>
                <div className="col-xl-8 col-md-8 col-sm-8 form-group">
                  <h5 style={{ color: "var(--secondary-color)" }}>
                    Use o mapa para definir a localização GPS:
                  </h5>
                  <GeolocalAddress onProducerInfoChange={handleProducerInfoChange} />
                </div>
          
                <div className="col-xl-8 col-md-8 col-sm-8 form-group">
                  <label htmlFor="street">Rua:</label>
                  <input
                    type="text"
                    id="street"
                    name="street"
                    value={producer?.street}
                    onChange={(e) => handleAddressChange("street", e.target.value)}
                    className="form-control"
                  />
                </div>
               
                <div className="gps-group-wrapper">
                  <div className="form-group">
                    <label htmlFor="postalCode">Código Postal:</label>
                    <input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={producer?.postalCode}
                      onChange={(e) => handleAddressChange("postalCode", e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group"  style={{ marginLeft: "0.5rem" }}>
                  <label htmlFor="city">Localidade:</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={producer?.city}
                    onChange={(e) => handleAddressChange("city", e.target.value)}
                    className="form-control"
                  />
                </div>
                </div>
                <div className="gps-group-wrapper">
                <div className="form-group">
                    <label htmlFor="country">Zona:</label>
                    <input
                      type="text"
                      id="region"
                      name="region"
                      value={producer?.region}
                      onChange={(e) => handleAddressChange("region", e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group" style={{ marginLeft: "0.5rem" }}>
                    <label htmlFor="country">País:</label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      value={producer?.country}
                      onChange={(e) => handleAddressChange("country", e.target.value)}
                      className="form-control"
                    />
                  </div>
                  
                </div>
                <div className="gps-group-wrapper">
                  <div className="form-group">
                    <label htmlFor="lat">Latitude:</label>
                    <input
                      type="text"
                      id="lat"
                      name="lat"
                      value={producer?.lat}
                      onChange={(e) => handleAddressChange("lat", e.target.value)}
                      className="form-control"
                    />
                  </div>
          
                  <div className="form-group" style={{ marginLeft: "0.5rem" }}>
                    <label htmlFor="long">Longitude:</label>
                    <input
                      type="text"
                      id="long"
                      name="long"
                      value={producer?.long}
                      onChange={(e) => handleAddressChange("long", e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

             
     
        
            <button type="submit">Salvar Alterações</button>
          </form>
         
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal} // Passe o estado para controlar a exibição do modal
        onClose={() => {
          setShowSuccessModal(false); 
          history.push("/dashboard");
        }}
        title="Sucesso" 
        message="Os dados foram atualizados com sucesso." 
      />
    </div>
  );
};

export default EditUser;
