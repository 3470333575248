import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Logo from '../../assets/svg/LogoSVG';
import {AiOutlineCheckCircle} from "react-icons/ai"

const RegisterSuccess = () => {
  const history = useHistory();
  console.log("locaStorage", localStorage.getItem('user') )

  const handleClick = () => {
    // Verifica se localStorage.user é igual a 'admin'
    if (localStorage.getItem('user') === 'admin') {
      // Se for admin, redireciona para '/users/registar'
      history.push('/users/registar');
    } else {
      // Caso contrário, redireciona para '/'
      history.push('/');
    }
  };
    return (
        
        <><div className='col-12 title-wrapper' style={{marginTop:"5rem"}}><Logo /></div><div className="col-12 dashboard-container">
            <div className='title-wrapper' style={{marginTop:"8rem"}}>

            <AiOutlineCheckCircle size={65} color="var(--success-color)" />
            </div>
            <h2 style={{ color: "var(--primary-color)"}}>Registo com SUCESSO!</h2>

            <div className='col-8 container p-3' style={{ color: "var(--secondary-color)" , textAlign:"center" }}>

                <p>
                    A sua conta foi criada com sucesso. No entanto, o acesso à plataforma está pendente de ativação pelo administrador do sistema.
                </p>
                <p>
                    A sua ativação e acesso será posteriormente enviada para o email registado.
                </p>
                <p>
                   <b>Obrigado</b>
                </p>
            </div>
            <button className='col-8 default-button' onClick={handleClick}>OK</button>
        </div></>
  );
};

export default RegisterSuccess;
