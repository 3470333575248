import React, { useState, useContext, useEffect } from 'react';
import './Bolota.css';
import { AuthContext } from "../AuthContext";
import { URL } from "../../config";
import SuccessModal from '../Desktop/SuccessModal';
import ErrorModal from '../Desktop/ErrorModal';

import DeleteConfirmationModal from '../Desktop/DeleteModal';

const RegisterBolotaCard = () => {


  useEffect(() => {
    showBolota(); 
  }, []);

  const { token } = useContext(AuthContext); 
  
  const [bolotaData, setBolotaData] = useState({
    acornType: '',
    topPrice: '',
    bottomPrice: ''
  });
  const [showModal, setShowModal] = useState(false); 
  const [showModalError, setShowModalError] = useState(false); 

  const [bolotaRanges, setBolotaRanges] = useState([]); 
  const [selectedRange, setSelectedRange] = useState(null);
  const [rangeToDelete, setRangeToDelete] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);



  const handleUpdate = (range) => {
    setSelectedRange(range);
    setBolotaData({
      acornType: range.acornType,
      topPrice: range.topPrice,
      bottomPrice: range.bottomPrice
    });
  };
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBolotaData({ ...bolotaData, [name]: value });
  };

  const handleDelete = async () => {
    console.log("handleDelete foi chamado.");

    try {
    
  
      const response = await fetch(`${URL}/webland-api/range/delete/${rangeToDelete._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao excluir o intervalo de preço');
      }
  
      // Atualize a lista de intervalos de preço após a exclusão
      showBolota();
      // Feche o modal de exclusão
      setShowModalDelete(false);
      // Limpa o estado rangeToDelete após a exclusão
      setRangeToDelete(null);
    } catch (error) {
      console.error('Erro ao excluir o intervalo de preço:', error.message);
    }
  };
  
  
  

  const showBolota = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/range/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao obter os intervalos de preço de bolota');
      }

      const responseData = await response.json();
      console.log("teste",responseData)
      setBolotaRanges(responseData); 
    } catch (error) {
      console.error('Erro ao obter os intervalos de preço de bolota:', error.message);
    }
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
  
   const isDuplicateAcornType = bolotaRanges.some(range => range.acornType === bolotaData.acornType);

   if (isDuplicateAcornType) {
     console.error('Erro ao submeter o PriceRange: o tipo de bolota já existe.');
     setBolotaData({
      acornType: '',
      topPrice: '',
      bottomPrice: ''
    });
     setShowModalError(true);
     return;
   }

    try {
      const response = await fetch(`${URL}/webland-api/range/submitPriceRange`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Adicionado o token de autorização aos cabeçalhos
        },
        body: JSON.stringify(bolotaData),
      });

      if (!response.ok) {
        throw new Error('Erro ao submeter o PriceRange');
      }

      setShowModal(true); // Exibe o modal de sucesso após a submissão bem-sucedida
      showBolota();
      // Limpa o estado do formulário após a submissão
      setBolotaData({
        acornType: '',
        topPrice: '',
        bottomPrice: ''
      });
    } catch (error) {
      console.error('Erro ao submeter o PriceRange:', error.message);
    }
  };

  return (
    <div>
      <div className="conversion-rate-container bolota-card mt-2">
        <form className='col-12 form-wrapper' onSubmit={handleSubmit}>
          <fieldset className='col-10 fieldset-wrapper' >
            <legend>INTERVALO DE PREÇOS</legend>

            <label htmlFor="acornType">Tipo de Bolota:</label>
            <select
              id="acornType"
              name="acornType"
              value={bolotaData.acornType}
              onChange={handleInputChange}
            >
              <option value="">Selecione um tipo</option>
              <option value="Carvalho">Carvalho</option>
              <option value="Sobreiro">Sobreiro</option>
              <option value="Azinheira">Azinheira</option>
            </select>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="bottomPrice">Preço Mínimo:</label>
              <input
                id="bottomPrice"
                type="number"
                name="bottomPrice"
                value={bolotaData.bottomPrice}
                onChange={handleInputChange}
              />

              <label htmlFor="topPrice" style={{ marginLeft: "5rem" }}>Preço Máximo:</label>
              <input
                id="topPrice"
                type="number"
                name="topPrice"
                value={bolotaData.topPrice}
                onChange={handleInputChange}
              />
            </div>

          </fieldset>

          <div className="col-2 actions">
            <button type="submit" className="remove">Definir</button>
           
          </div>
        </form>
      </div>
      {showModalError && (
      <ErrorModal
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        message={"Este tipo de Bolota já existe. Insira outro tipo!"}
        title={"Erro"}
      />
    )}
      {showModal && (
        <SuccessModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          message={"Parâmetros definidos com sucesso"}
          title={"Price Range"}
        />
      )}
   
      {bolotaRanges !== null && (
        <div className="conversion-rate-container mt-2">
          <table className="settings-table">
            <thead>
              <tr>
                <th>Acorn Type</th>
                <th>Top Price</th>
                <th>Bottom Price</th>
              </tr>
            </thead>
            <tbody>
              {bolotaRanges?.map((range, index) => (
                <tr key={index}>
                  <td>{range.acornType}</td>
                  <td>{range.topPrice}</td>
                  <td>{range.bottomPrice}</td>
                  <td className='action-cell'>
                    <button onClick={() => handleUpdate(range)} disabled={true}>
                      Modificar
                    </button>
                 
                  <button
                  style={{marginLeft:"0.5rem"}}
  onClick={() => {
    setRangeToDelete(range);
    setShowModalDelete(true);
  }}
>
  Apagar
</button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {showModalDelete && (
  <DeleteConfirmationModal
    show={showModalDelete}
    onClose={() => {
      setShowModalDelete(false);
      //setItemToDelete(null);
      console.log("range",rangeToDelete)
    }}
    title={`Deseja eliminar o intervalo de preço para ${rangeToDelete?.acornType}?`}
    onConfirm={handleDelete}
  />
)}


        </div>
      )}
    </div>
  );
};

export default RegisterBolotaCard;
