import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import { TbMapPinCheck, TbGps } from "react-icons/tb";
import GeolocalAddress from "./GeoLocalAddress";
import { components } from "react-select";
import MySelect from "./MySelect.js";
import makeAnimated from "react-select/animated";
import { URL } from "../../config";


const NIF_VALIDATION_URL = "http://www.nif.pt/?json=1&q=509442013&key=eaefd8cc15a7bc5283f12347bc0d131e";

const RegisterTransformer = ({ onTranformerInfoChange }) => {
  const [isNifValid, setIsNifValid] = useState(true);
  const [isNifAvailable, setIsNifAvailable] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isToFly, setIsToFly] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  const [optionSelectedCertifications, setoptionSelectedCertifications] = useState([]);
  const [certificates, setCertificates] = useState([]);
 

  const [optionSelectedactivity_sector, setoptionSelectedactivity_sector] = useState([]);
  const [activity_sector, setactivity_sector] = useState([]);

  const [producerInfo, setProducerInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    region: "",
    postalCode: "",
    distrito: "",
    country: "",
    phoneNumber: "",
    lat: "",
    long: "",
    type_service: "",
    activity_sector: "",
    secagem: false,
    descasque: false,
    taninos: false,
    torra: false,
    moagem: false,
    limpeza: false,
  });

  const optionsSpecies = [
    { value: 'Indústria Alimentar', label: 'Indústria Alimentar' },
    { value: 'Indústria Química', label: 'Indústria Química' },
    { value: 'Biorrefinarias', label: 'Biorrefinarias' },
    
  ];

  const optionsCertifications=[
    { value: 'agricultura_biologica_europa', label: 'Agricultura biológica Europa' },
    { value: 'rainforest_alliance_standard', label: 'Rainforest Alliance Standard' },
    { value: 'global_gap', label: 'GLOBAL G.A.P' },
    { value: 'gestao_florestal_responsavel', label: 'Gestão florestal responsável - FSC' },
    { value: 'outra', label: 'Outra' },
  ]

  const [processTypes, setProcessTypes] = useState({
    secagem: false,
    descasque: false,
    taninos: false,
    torra: false,
    moagem: false,
    limpeza: false,
  });

  const handleProcessTypeChange = (e) => {
    const { name, checked } = e.target;
    setProducerInfo((prevProducerInfo) => ({
     ...prevProducerInfo,
      [name]: checked,
    }));
    setProcessTypes((prevProcessTypes) => ({
      ...prevProcessTypes,
      [name]: checked,
    }));
  };

  const CustomCheckbox = ({ isChecked }) => {
    return (
      <div style={{
        width: '16px',
        height: '16px',
        backgroundColor: isChecked ? 'var(--focus-color)' : 'transparent',
        border: '1px solid #d1d1d1',
        display: 'inline-block',
        cursor: 'pointer',
        // Add more styles for the unchecked state if needed
      }}>
        {isChecked && (
          <span style={{ // A simple checkmark, you can replace it with an icon
            color: 'white',
            // Add more styles to position the checkmark or use an icon
          }}>✓</span>
        )}
      </div>
    );
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'left', height: '100%', gap:10 }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null} 
            style={{ margin: 0, padding:0 }}
          />
          <label style={{ margin: 0 }}>{props.label}</label>
        </div>
      </components.Option>
    );
  };
  

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const handleTransformerInfoChange = (info) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      ...info
    }));
  };

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "nif") {
      const isValidNif = /^\d{9}$/.test(value);
      setIsNifValid(isValidNif);
    }

    if (name === "phoneNumber") {
      const isValidPhoneNumber = /^\d{9}$/.test(parseInt(value));
      setIsValidPhone(isValidPhoneNumber);
    }

    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleAddressChange = (field, value) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [field]: value,
    }));
  };

  const handleSpecies = selected => {
    setOptionSelected(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      species: selectedSpecies, // Salvando a string no estado
    }));
  };

  const handleCertifications = selected => {
    setoptionSelectedCertifications(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      type_service: selectedCertifications,
    }));
  };
  
  const handleactivity_sector=selected => {
    setoptionSelectedactivity_sector(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      activity_sector: selectedCertifications,
    }));
  };
  
  
/*
  const handleCertifications = (e) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      certifications: e.target.value,
    }));
  };*/

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/certificates/get_certificates`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os certificados');
        }
        const data = await response.json();
      // Filtrar certificados onde o type é "produtor"
      const producerCertNames = data
          .filter(cert => cert.type === "transformador")
          .map(cert => ({ value: cert.type_service, label: cert.type_service }));

      const transformerCertNames = data
          .filter(cert => cert.type === "transformador")
          .map(cert => ({ value: cert.activity_sector, label: cert.activity_sector }));
// Remover valores duplicados usando Set
      const uniqueTransformerCertNames = Array.from(new Set(transformerCertNames.map(cert => cert.value)))
          .map(value => {
          return {
    value,
    label: transformerCertNames.find(cert => cert.value === value).label
  };
});

        setactivity_sector(uniqueTransformerCertNames);
        setCertificates(producerCertNames);
      } catch (error) {
        console.error("Erro ao buscar os certificados:", error.message);
      }
    };

    fetchCertificates();
    if(onTranformerInfoChange){
      onTranformerInfoChange(producerInfo);
      //console.log("onTranformerInfoChange", producerInfo);
    }
  }, [producerInfo, onTranformerInfoChange]);

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Contactos
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={producerInfo.name}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">
          NIF:{" "}
          {isNifAvailable && isNifValid && producerInfo.nif !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.nif !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}
        </label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={producerInfo.nif}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      {/* <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={producerInfo.email}
          onChange={handleInputChange}
          className="form-control"
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="phoneNumber">
          Telefone:
          {isValidPhone && producerInfo.phoneNumber !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.phoneNumber !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}
        </label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={producerInfo.phoneNumber}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      <div className="col-xl-12">Sector de Atividade:</div>
      <div className="col-12">
        <div className="col-6" style={{ paddingLeft: "0rem" }}>
          <MySelect
            required
            options={optionsSpecies}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleactivity_sector}
            allowSelectAll={true}
            value={optionSelectedactivity_sector}
          />
        </div>
        <div className="form-group">
          <label>Tipos de Processo:</label>
          <div className="checkbox-group">
            {Object.entries(processTypes).map(([process, value]) => (
              <label key={process} className="checkbox-label">
                <input
                  type="checkbox"
                  name={process}
                  checked={value}
                  onChange={handleProcessTypeChange}
                />
                {process.charAt(0).toUpperCase() + process.slice(1)}
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="col-xl-6">
        <label htmlFor="role">Tipo de Serviço:</label>
        <MySelect
          required
          options={certificates}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={handleCertifications}
          allowSelectAll={true}
          value={optionSelectedCertifications}
        />
      </div>

      <div className="title-register-wrapper mt-3 mb-2">
        <TbMapPinCheck
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Morada
      </div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
          {/* <span  
        className="gps-pin-location-button" 
        onClick={() => setIsToFly(prevIsToFly => !prevIsToFly)}
        style={{background: !isToFly && "lightgrey"}}
        >
          <TbGps size={25} color="white"/>
        </span> */}
        </h5>
        <h6 style={{ color: "var(--secondary-color)" }}>
          Clique no marcador para preenchimento automático da morada
        </h6>
        <GeolocalAddress
          onProducerInfoChange={handleTransformerInfoChange}
          flyToMyLocation={true}
        />
      </div>

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={producerInfo.lat}
            onChange={(e) => handleAddressChange("lat", e.target.value)}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={producerInfo.long}
            onChange={(e) => handleAddressChange("long", e.target.value)}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={producerInfo.street}
          onChange={(e) => handleAddressChange("street", e.target.value)}
          className="form-control"
        />
      </div>

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="postalCode">Código Postal:</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={producerInfo.postalCode}
            onChange={(e) => handleAddressChange("postalCode", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="city">Localidade:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={producerInfo.city}
            onChange={(e) => handleAddressChange("city", e.target.value)}
            className="form-control"
          />
        </div>
      </div>
      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="country">Distrito:</label>
          <input
            type="text"
            id="distrito"
            name="distrito"
            value={producerInfo.distrito}
            onChange={(e) => handleAddressChange("distrito", e.target.value)}
            className="form-control"
          />
        </div>
     
      </div>
      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="country">Zona:</label>
          <input
            type="text"
            id="region"
            name="region"
            value={producerInfo.region}
            onChange={(e) => handleAddressChange("region", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="country">País:</label>
          <input
            type="text"
            id="country"
            name="country"
            value={producerInfo.country}
            onChange={(e) => handleAddressChange("country", e.target.value)}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterTransformer;
