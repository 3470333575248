import React, { useState, useEffect, useContext } from "react";
import "./MobileMarket.css";
import "../../global.css";
import { URL } from "../../config";
import { useHistory } from "react-router-dom";
import Spinner from "../../assets/WeblandSpinner";
import { AuthContext } from "../AuthContext";

const MobileMarket = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const {token} = useContext(AuthContext);

  const fetchProducts = () => {
    fetch(`${URL}/webland-api/product/products`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      }})
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleButtonClick = (id) => {
    history.push(`/marketDescription/${id}`);
  };

  return (
    <div className="col-12">
      <div className="title-wrapper">Market</div>

      {isLoading ? (
        <div className="col-12 market-container-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="col-12 market-group">
            {products?.map((product) => (
              <div key={product._id} className="product-card">
                <h4 className="type">{String(product.type).toUpperCase()}</h4>
                <h4 className="price">{product.price}€/uni</h4>

                <button
                  className="col-12 default-button market-btn"
                  style={{ textAlign: "center", padding: "0.5rem 0" }}
                  onClick={() => handleButtonClick(product._id)}
                >
                  Selecionar
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MobileMarket;
