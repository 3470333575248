
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ForbiddenPage = () => {
    const {user, logoutUser} = useContext(AuthContext);

    const history = useHistory();

    const handleLogout = () => {
        // logoutUser();
        history.goBack(); // Redirect to home page after logout
    };


    return (
        <div>
            <h1>403 Forbidden</h1>
            <p>You do not have permission to access this page.</p>
            <button onClick={handleLogout}>Return Home</button>
        </div>
    );
}

export default ForbiddenPage;
