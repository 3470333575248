import React, { useState, useEffect, useContext } from "react";
import AddBolotaModal from "../Mobile/AddBolotaModal";
import EditBolotaModal from "../Mobile/EditBolotaModal";
import { AiFillEdit } from "react-icons/ai";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import "./mobile-producer.css";
import { AuthContext } from "../AuthContext";

const MobileWarehouse = () => {
  const [producerData, setProducerData] = useState(null);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(localStorage.getItem("id"));
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const {token} = useContext(AuthContext);

  const handleAddProduct = (species, quantity, price) => {
    //console.log("Adding product:", species, quantity, price);
    setShowAddProductModal(false);
    fetchData();
  };

  const handleEditClick = (product) => {
    //console.log(product)
    setIsEditing(true);
    setEditedProduct(product);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    fetch(`${URL}/webland-api/producer/${id}`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      }})
      .then((response) => response.json())
      .then((data) => {
        setProducerData(data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const totalQuantity =
    producerData?.stockBolota.reduce((sum, product) => {
      return sum + product.quantity;
    }, 0) || 0;

  return (
    <div className="col-10 warehouse-container">
      <div className="title-wrapper mb-5">Armazém</div>

      {isLoading ? (
        <div className="col-12 warehouse-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          {producerData?.stockBolota.map((product, index) => (
            <div className="col-10 warehouse-content mb-3" key={index}>
              <div className="col-6 warehouse-content-name">
                {product.acornType}
              </div>
              <div className="col-6 warehouse-input-group">
                <div className="warehouse-input-item">
                <span className="edit-stock-btn" onClick={() => handleEditClick(product)}>
                    <AiFillEdit size={15} />
                  </span>
                  <span className="col-12 input-item-quantity">
                    {product.quantity}<span style={{fontSize:"small"}}> Kg</span>
                  </span>
                  <span className="col-12 input-item-price">
                    {product.price} €/kg
                  </span>
                </div>
              </div>
            

            </div>
          ))}

          <div className="col-12 warehouse-total">
            Total:{" "}
            <b style={{ padding: "0rem 1rem", fontSize: "3rem" }}>
              {totalQuantity}
            </b>{" "}
            Kgs
          </div>
          <div className="warehouse-add-btn">
            <button
              className="default-button"
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                padding: "0rem 3rem",
              }}
              onClick={() => setShowAddProductModal(true)}
            >
              +
            </button>
          </div>
          <div
            className="col-12 mt-3"
            style={{
              textAlign: "center",
              fontSize: "regular",
              color: "var(--secondary-color)",
            }}
          >
            Adicionar ao stock
          </div>
          <AddBolotaModal
            isOpen={showAddProductModal}
            onClose={() => setShowAddProductModal(false)}
            onAdd={handleAddProduct}
          />
            {isEditing && (
                <EditBolotaModal
                  isOpen={isEditing}
                  onClose={() => {

                    setIsEditing(false);
                    setEditedProduct(null);
                  }}
                  isEditMode={true}
                  initialProduct={editedProduct}
                />
              )}
        </>
      )}
    </div>
  );
};

export default MobileWarehouse;
