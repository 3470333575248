export const prepareBolotaChartData = (warehouseData) => {
    const options = { day: 'numeric', month: 'short' }

    const labels = warehouseData?.map(item =>
        new Date(String(item._id.year+"/"+item._id.month+"/"+item._id.day)).toLocaleDateString('pt-PT', options))
   

    
  
    // Step 2: Group data by acornType and date
    const groupedData = {};
    warehouseData?.forEach(item => {
      const dateStr =  new Date(String(item._id.year+"/"+item._id.month+"/"+item._id.day)).toLocaleDateString('pt-PT', options);
      groupedData[item._id.acornType] = groupedData[item._id.acornType] || {};
      groupedData[item._id.acornType][dateStr] = (groupedData[item._id.acornType][dateStr] || 0) + item.totalQuantity;
    });
  
    // Step 3: Create datasets
    const datasets = Object.entries(groupedData).map(([acornType, quantitiesByDate]) => {
        const data = labels.map(date => quantitiesByDate[date] || 0);
        return {
            label: acornType,
            data: data,
            borderColor: getBorderColorByAcornType(acornType), // You need to define this function
            borderWidth: 2, // Line thickness
            pointBackgroundColor:  getBorderColorByAcornType(acornType), // Point color same as line color
            pointBorderColor:  getBorderColorByAcornType(acornType), // Point border color same as line color
            pointRadius: 3,
            fill: false,
        };
    });
    
    // console.log(datasets)
    // Step 4: Return the chart data
    return { labels, datasets };
  };

  
  // Use this function to define the border colors for each acorn type
  const getBorderColorByAcornType = (acornType) => {

    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
    const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color');
    const focusColor = getComputedStyle(document.documentElement).getPropertyValue('--focus-color');

    const colorMap = {
      Carvalho: focusColor,
      Sobreiro: secondaryColor,
      Azinheira: primaryColor,
      
    };
    return colorMap[acornType] || 'gray';
  };
  