import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import AppTemp from './AppTemp'
import { FilterProvider } from "./FilterContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/AuthContext";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <FilterProvider>
          <App />
        </FilterProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
