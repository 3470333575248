import React from "react";
import { TbLogout } from "react-icons/tb";
import "./ModalExit.css";

const ModalExit = ({ onClose, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-cancel-btn"><button style={{background:"none", border:"none", color:"white", cursor:"pointer"}} onClick={onClose}>X</button></div>
        <div className="modal-logo"><TbLogout size={50}/></div>
        <p style={{fontSize:"1.5rem"}}>Tem a certeza?</p>
        <div className="modal-buttons-wrapper">
          <button className="btn-ok" onClick={onConfirm}>Sim</button>
          <button className="btn-no" onClick={onClose}>Não</button>
        </div>
      </div>
    </div>
  );
};

export default ModalExit;
