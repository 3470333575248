import * as React from "react"

const ProducerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="4.67mm"
    height="7.33mm"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 1400 2100"
    {...props}
  >
    <path
      d="M692.86 24.06c369.76 0 669.5 299.75 669.5 669.5 0 120.38-31.79 233.32-87.4 330.93h.59L702.8 2077.03 121.13 1042.06c-62.02-101.52-97.77-220.83-97.77-348.5 0-369.75 299.75-669.5 669.5-669.5zm0 374.22c163.08 0 295.28 132.2 295.28 295.28 0 163.08-132.2 295.28-295.28 295.28-163.08 0-295.28-132.2-295.28-295.28 0-163.08 132.2-295.28 295.28-295.28z"
      style={{
        fill: props.color,
      }}
    />
  </svg>
)

export default ProducerIcon
