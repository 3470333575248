import React, { useContext, useEffect, useState } from "react";
import { useFilter } from "../../FilterContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Map from "./Map/Map";
import "./Dashboard.css";
import { URL } from "../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Spinner from "../../assets/WeblandSpinner";
import { AuthContext } from "../AuthContext";
import DeleteConfirmationModal from "./DeleteModal";

const Transformadores = () => {
  const { filter } = useFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [produtoresData, setProdutoresData] = useState([]);
  const [transformadoresData, setTransformadoresData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({})
  const [selectedRow, setSelectedRow] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  

  const {token} = useContext(AuthContext)


  const [nameFilter, setNameFilter] = useState("");
  const [localFilter, setLocalFilter] = useState("");
  const [zonaFilter, setZonaFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleLocalFilterChange = (event) => {
    setLocalFilter(event.target.value);
  };

  const handleZonaFilterChange = (event) => {
    setZonaFilter(event.target.value);
  };

  const handleTipoFilterChange = (event) => {
    setTipoFilter(event.target.value);
  };
  const handleRowClick = (index, produtor) => {
    setSelectedRow(index);
    setSelectedRowData(produtor);
  };

  const handleMouseEnter = (index) => {
    // Handle mouse enter event, e.g., change row color on hover
    // Implement your own logic based on your requirements
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event, e.g., reset row color on hover out
    // Implement your own logic based on your requirements
  };
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    console.log("filtro",typeof(filter) === typeof("String"));
    if (typeof(filter) === typeof("String")) {
    console.log("filtro",filter.split("/"));
    setZonaFilter(filter.split("/")[3]);
    setTipoFilter(filter.split("/")[2]);
    } else applyFilters();
  }, [filter]);

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const requestData = {
        _id: userToDelete._id,
        email: userToDelete.email,
        role:"transformer",
        
        // Adicione outros dados que deseja enviar aqui
      };
      console.log("o que recebo",userToDelete);
  
      fetch(`${URL}/webland-api/user/deleteUser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Usuário excluído com sucesso",requestData);
            window.location.reload();
          } else {
            console.error("Erro ao excluir o usuário",requestData);
          }
        })
        .catch((error) => {
          console.error("Erro na solicitação para excluir o usuário:", error);
        });
    }
    setShowDeleteModal(false);
    
  };
  const handleCancelDelete = () => {
    // Feche o modal sem realizar a exclusão.
    setShowDeleteModal(false);
  };



  const SelectedRowInfo = () => {
    const {
      _id,
      address,
      name,
      nif,
      phoneNumber,
      email,
      distrito,
    } = selectedRowData;
  
   
    return (
      <div className="col-12 card-table-body p-0">
        <div className="col-12 selected-row-data mb-4">
        <div className="col-4"><strong><div>{name? name: " - "}</div></strong></div>
        <div className="col-2">
      <strong>Área</strong>
      <div>123</div>
    </div>
    <div className="col-2">
      <strong>Serviço</strong>
      <div>123</div>
    </div>

    <div className="col-2">
      <strong>Preço</strong>
      <div>123</div>
    </div>

    <div className="col-2">
      <strong>Custo Médio</strong>
      <div>123</div>
    </div>

       
        </div>
         <div className="col-12 selected-row-data square-box">
        
        <div className="col-3"><strong>Local:</strong>
        <div>  <strong>{distrito ? distrito: " - "}</strong> 
        <div>{address ? address: " - "}</div> 
        <div>{phoneNumber? phoneNumber: " - "}</div>
        <div>{email ? email:" - "}</div>
        {nif? nif:" - "}
        </div>
        
        </div>
       
        <div className="col-3"><strong>Serviços:</strong> <div>{name? name: " - "}</div></div>
        <div className="col-3"><strong>Custos:</strong> <div>{email ? email:" - "}</div></div>
        <div className="col-3"><strong>Prazos:</strong> <div>{phoneNumber? phoneNumber: " - "}</div></div>
        </div>
       
       
  
        <div className="col-12 mt-3">
          <button className="erase-btn" onClick={() => handleDeleteClick(selectedRowData)} disabled={ selectedRowData._id  ? false : true}>Apagar</button>
          <button className="edit-btn" onClick={() => history.push(`/editusers?id=${selectedRowData._id}&role=transformer`)}  disabled={ selectedRowData._id   ? false : true} >Editar</button>
          <button 
            className="metric-btn"
            disabled={ selectedRowData._id  ? false : true}
            onClick={() => history.push(`/dashboard/metrics/transformadores?id=${selectedRowData._id}`)}>
              Métricas
          </button>
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete} />
      </div>
    );
  };



  const handleCleanFilters = () => {
    setNameFilter("");
    setLocalFilter("");
    setZonaFilter("");
    setTipoFilter("");
  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  const [filteredTransformadoresData, setFilteredTransformadoresData] =
    useState([]);

  const history = useHistory();

  const [currentPageTransformador, setCurrentPageTransformador] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    if (isLoading) {
      fetchTransformadoresData();
    } else applyFilters();
  }, [filter, nameFilter, zonaFilter, localFilter, tipoFilter]);

  const fetchTransformadoresData = () => {
    fetch(`${URL}/webland-api/transformer/all` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const transformadoresWithType = data.map((transformador) => ({
          ...transformador,
          type: "transformador",
        }));
        const sortedTransformadores = transformadoresWithType
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setTransformadoresData(sortedTransformadores);
        setFilteredTransformadoresData(sortedTransformadores);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transformer data:", error);
      });
  };

  const applyFilters = () => {
    let filteredData = transformadoresData;

    if (nameFilter) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (localFilter) {
      filteredData = filteredData.filter((item) =>
        item.address.toLowerCase().includes(localFilter.toLowerCase())
      );
    }

    if (zonaFilter) {
      filteredData = filteredData.filter((item) =>
        item.region.toLowerCase().includes(zonaFilter.toLowerCase())
      );
    }

    setFilteredTransformadoresData(filteredData);
  };

  const startTransformadoresIdx = (currentPageTransformador - 1) * itemsPerPage;
  const endTransformadoresIdx = Math.min(
    startTransformadoresIdx + itemsPerPage,
    transformadoresData.length
  );

  return (
    <div className="col-12 row">
      <div className="col-xl-4 col-md-12 col-sm-12 map-container">
        {isLoading ? (
          <div className="col-12 spinner-container">
            <Spinner />
          </div>
        ) : (
          <Map locations={filteredTransformadoresData}  selectedMarker = {selectedRowData} />
        )}
      </div>

      <div className="col-xl-8 col-md-12 col-sm-12 tables-container-wrapper">
        <div className="tables-container">
          {" "}
          <div className="col-12 card-table">
            <div
              className="col-12 card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
            <div className="col-12 card-table-body"  style={{width:"100%", paddingRight:"0.5rem"}}>
              <div className="col-11 card-table-body-filter">
             
                <div
                  className="col-xl-1 col-md-2 col-sm-2"
                  style={{ textAlign:"left" }}
                >
                  Filtros
                </div>
                <div className="col-xl-2 col-md-2 col-sm-6">
                  <input
                    type="text"
                    value={nameFilter}
                    onChange={handleNameFilterChange}
                    placeholder="Nome"
                    style={{ padding: "0.15rem 0.5rem" }}
                  />
                </div>
                <div className="col-xl-2 col-md-2 col-sm-6">
                  <input
                    type="text"
                    value={localFilter}
                    onChange={handleLocalFilterChange}
                    placeholder="Local"
                    style={{ padding: "0.15rem 0.5rem", marginLeft:"0.5rem" }}
                  />
                </div>
                <div className="col-xl-1 col-md-2 col-sm-6" style={{width:"5%"}}>
                  <select value={zonaFilter} onChange={handleZonaFilterChange}  style={{ marginLeft:"0.5rem" }}>
                    <option value="">Zona</option>
                    <option value="Norte">Norte</option>
                    <option value="Centro">Centro</option>
                    <option value="Sul">Sul</option>
                    
                  </select>
                </div>
                <div className="col-xl-1 col-md-2 col-sm-6">
                  <select value={tipoFilter} onChange={handleZonaFilterChange}>
                    <option value="">Tipologia</option>
                    <option value="Alimentar">Alimentar</option>
                    <option value="Biorefinarias">Biorefinarias</option>
                    <option value="Química">Química</option>
                  </select>
                </div>
                <div className="col-xl-1 col-md-2 col-sm-6">
                  <button
                    className="filter-clean-button"
                    onClick={handleCleanFilters}
                  >
                    Limpar
                  </button>
                </div>
              </div>
              </div>
         
          </div>
          <div className="col-12 card-table">
            <div className="col-12 card-table-header">
              <div
                className="col-2 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageTransformador((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageTransformador === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageTransformador}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageTransformador((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(
                          filteredTransformadoresData.length / itemsPerPage
                        )
                      )
                    )
                  }
                  disabled={
                    endTransformadoresIdx >= filteredTransformadoresData.length
                  }
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-8 card-table-title">Transformadores</div>
              <div className="col-2 card-table-button">
                <button className="card-table-button-add">+</button>
              </div>
            </div>
            <div className="card-table-body" style={{width:"100%", paddingRight:"0.5rem"}}>
            <table className="dashboard-user-table"  style={{width:"100%", paddingRight:"0.5rem"}}>
                <thead style={{ fontSize: "x-small" }}>
                  <tr>
                    <th >Nome</th>
                    {/* <th >NIF</th> */}
                    <th >Local</th>
                    {/* <th>Latitude</th>
                  <th>Longitude</th> */}
                    <th >Zona</th>
                    <th >Tipo</th>
                    <th >Volume</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <div className="col-12 spinner-container">
                      <Spinner />
                    </div>
                  ) : (
                    filteredTransformadoresData
                      .slice(startTransformadoresIdx, endTransformadoresIdx)
                      .map((transformador, index) => (
                         <tr key={index}
            className={`${selectedRow === index ? 'tr-produtores selected-row' : 'tr-produtores'}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleRowClick(index, transformador)}
            style={{ borderBottom:"2px solid white"}}
          >
                          <td className="table-cell-nome">
                            {transformador.name}
                          </td>
                          {/* <td className="table-cell">{transformador.nif}</td> */}
                          <td className="table-cell">
                            {String(transformador.address).split(",")[String(transformador.address).split(",").length - 1]}
                          </td>
                          {/* <td>{produtor.lat}</td>
                    <td>{produtor.long}</td> */}
                          <td className="table-cell">{transformador.region}</td>
                          <td className="table-cell">
                            {transformador.transformationType}
                          </td>
                          <td
                            className="table-cell"
                            style={{
                              background: "var(--primary-color",
                              color: "white",
                              borderRadius: "0.2rem",
                              textAlign: "center",
                            }}
                          >
                            {!transformador.productQuantity
                              ? "0 "
                              : transformador.productQuantity}
                            kg
                          </td>
                          <td>
                            <button className="btn-detalhes">Ver +</button>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
{" "}
<div className="col-12 card-table mt-3">
            <div
              className="col-12 card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="col-12 card-table-body"  style={{width:"100%", paddingRight:"0.5rem"}}>
                <div className="col-11 card-table-body-filter">
                  
                  {SelectedRowInfo()}

                </div>
              </div>
              </div>



      </div>
    </div>
  );
};

export default Transformadores;
