import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Webland from "../../assets/svg/WeblandSVG";
import Acorn from "../../assets/svg/LogoAcornSVG";
import { MdOutlineWarehouse } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { LuPackageOpen } from "react-icons/lu";
import "./mobile-producer.css"

const MobileProducer = () => {
    const history = useHistory();
  return (
    <>
    <div className="col-12">
        <div className="col-12" style={{textAlign:"center"}}><Acorn/></div>
        <div className="dashboard-container">
            <button className="col-8 btn btn-market" onClick={()=> history.push("/warehouse")} style={{
      animation: `fadeIn ease 0.5s ${0.1}s forwards`,
      opacity: 0
    }}>
                <div>Armazém</div>
                <div><MdOutlineWarehouse size={40}/></div>
            </button>
            <button className="col-8 btn btn-account" onClick={()=> history.push("/account")}style={{
      animation: `fadeIn ease 0.5s ${0.2}s forwards`,
      opacity: 0
    }}>
                <div>Conta</div>
                <div><FaUserAlt size={35}/></div>
            </button>
            <button className="col-8 btn btn-order" onClick={()=> history.push("/order")} style={{
      animation: `fadeIn ease 0.5s ${0.3}s forwards`,
      opacity: 0
    }}>
                <div>Encomendas</div>
                <div><LuPackageOpen size={40}/></div>
            </button>
        </div>
     
    </div>
    <div className="col-12 floating-menu-container" style={{position:"fixed", bottom:"10px", zIndex:1}}><Webland/></div>
    </>
  );
};

export default MobileProducer;
