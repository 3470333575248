import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//import { getproductss, updateproductsStatus } from "./api"; // API functions to interact with the backend
import { URL } from "../../config";
import "./MarketAdmin.css";
import Spinner from "../../assets/WeblandSpinner";
import SuccessModal from "../Desktop/SuccessModal";
import DeleteConfirmationModal from "../Desktop/DeleteModal";
import { AuthContext } from "../AuthContext";
import {CiImageOff} from "react-icons/ci";

const MarketList = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token } = useContext(AuthContext);
    const history = useHistory();
  
    const fetchProducts = () => {
      fetch(`${URL}/webland-api/product/products`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {                 
          setProducts(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    };
  
    useEffect(() => {
      fetchProducts();
      console.log(products);
    }, []);
  
    const redirectToDetails = (productId) => {
      history.push(`/market/product?id=${productId}`);
    };
  
    return isLoading ? (
      <div className="col-12 spinner-container-market">
        <Spinner />
      </div>
    ) : (
      <div className="admin-market-wrapper">
        <div
          className="col-12 card-table-header"
          style={{ background: "var(--secondary-color)" }}
        >
          PRODUTOS
        </div>
        <div className="col-12 admin-card-table-body"  style={{width: "100%", height: "80vh"}}>
          <div className="table-scroll-container" style={{width: "100%", height: "100%"}}>
            <table className="market-list-table"   >
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Nome</th>
              
                  <th style={{width:"40%"}}>Descrição</th>
                  <th>Preço</th>
                  <th>Detalhes</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>
                    {product.imageData!==undefined && product.imageData!== ""? (
          <img
            src={product.imageData}
            alt={product.type}
            style={{ width: "100px", height: "100px", objectFit: "cover", marginBottom: "1rem" }}

          />
        ) : (
          <CiImageOff size={50} color="var(--secondary-color)"/> 
        )} </td>
                    <td>{product.type}</td>
                    <td>{product.description}</td>
                    <td>{product.price}€/uni</td>
                    <td>
                      <button
                        onClick={() => redirectToDetails(product._id)}
                      >
                        Detalhes
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default MarketList;
