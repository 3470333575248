import { createSlice } from '@reduxjs/toolkit';

const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    products: [],
  },
  reducers: {
    addProduct: (state, action) => {
      const { _id, type, price, quantity, dimensions, packageType } = action.payload;
      const existingProductIndex = state.products.findIndex((product) => product._id === _id);

      if (existingProductIndex !== -1) {
        // Product with the same _id exists, update the quantity
        state.products[existingProductIndex].quantity += quantity;
      } else {
        // Product with the _id doesn't exist, push a new product
        state.products.push({ _id, type, price, dimensions, packageType, quantity });
      }
    },
    removeProduct: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter((product) => product._id !== productId);
    },
    updateQuantity: (state, action) => {
        const { productId, quantity } = action.payload;
        const productToUpdate = state.products.find(item => item._id === productId);
  
        if (productToUpdate) {
          productToUpdate.quantity = quantity;
        }
      },

    clearBasket: (state, action) =>{
        state.products = [];
    }
  },
});

export const { addProduct, removeProduct, clearBasket, updateQuantity } = basketSlice.actions;
export const selectBasket = (state) => state.basket;
export default basketSlice.reducer;
