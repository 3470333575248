import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import Spinner from "../../assets/WeblandSpinner";
import { CiImageOff } from "react-icons/ci";
import { URL } from "../../config";
import DeleteConfirmationModal from "../Desktop/DeleteModal";
import SuccessModal from "../Desktop/SuccessModal";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productToDelete, setProductToDelete] = useState(null);
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get("id");

  const fetchProductDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${URL}/webland-api/product/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }

      const data = await response.json();
      setProduct(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
    console.log(productId);
  }, [productId]);

  const handleEdit = () => {
    history.push(`/market/EditProduct?id=${productId}`);
  };
console.log("teste",product)
const openDeleteConfirmationModal = (product) => {
  setProductToDelete(product);
  setIsDeleteModalOpen(true);
};
const closeDeleteConfirmationModal = () => {
  setIsDeleteModalOpen(false);
  setProductToDelete(null);
};

const handleConfirmDelete = () => {
  const menssageDelete = "Produto Eliminado com sucesso";
  fetch(
    `${URL}/webland-api/product/deleteproducts/${productId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      setIsLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
    setSuccessMessage(menssageDelete);
    setIsSuccessModalOpen(true);
    closeDeleteConfirmationModal(); // Fechar o modal após a exclusão
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    history.goBack(); 
   // fetchProductsAndUpdate();
  };

const handleDelete = () => {
  openDeleteConfirmationModal(product);
};
  if (isLoading) return <Spinner />;

  if (!product) return <div>Product not found</div>;

  return (
    <div className="product-details-container">
      <Breadcrumb productId={productId} url={"market"} />
      <h2 style={{ textTransform: "uppercase" }}>{product.type}</h2>

      <div className="row">
        {product.imageData!==undefined && product.imageData!== ""? (
          <img
            src={product.imageData}
            alt={product.type}
            style={{ width: "250px", height: "250px", objectFit: "cover", marginBottom: "1rem" }}
          />
        ) : (
          <CiImageOff size={250} color="var(--secondary-color)"/> 
        )}
       

        <p>{product.description}</p>
        <p>Tipo de Embalagem: {product.packageType}</p>
        <p>Preço por unidade: €{product.price}</p>
        <p>Peso: {product.weight}</p>
        <p>Stock: {product.stock}</p>
        <p>Origem: {product.origin}</p>
        <p>Certificação: {product.certification}</p>
        <p>Valor Nutricional: {product.nutritionalValue}</p>
        <p>PRV PVP: {product.prv_pvp}</p>
        <p>EAN 13: {product.EAN_13}</p>
        <p>ITF 14: {product.ITF_14}</p>
        <p>Pegada de Carbono: {product.carbonFootprint}</p>
        <p>Data de Validade: {product.expirationDate}</p>
        <div className="btn-container">
          <button onClick={handleEdit}>Editar</button>
         {/* <button onClick={() => history.goBack()}>Cancelar</button>*/}
          <button onClick={handleDelete}>Apagar</button>
        </div>
        <DeleteConfirmationModal
            show={isDeleteModalOpen}
            onClose={closeDeleteConfirmationModal}
            onConfirm={handleConfirmDelete}
            title="Tem certeza que deseja excluir este produto?"
          />
            <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={closeSuccessModal}
            title="Sucesso"
            message={successMessage}
          />
      </div>
    </div>
  );
};

export default ProductDetails;

const Breadcrumb = ({ productId, url }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="breadcrumb-wrapper">
      {/* <span className="breadcrumb-item" onClick={() => navigateTo('/')}>
          Home
        </span>
        <span> &gt; </span> */}
      <span className="breadcrumb-item" onClick={() => navigateTo("/market")}>
        {url ? url.toUpperCase() : "MARKET"}
      </span>
      <span> &gt; </span>
      <span
        className="breadcrumb-item"
        onClick={() => navigateTo("/market/list")}
      >
        Produtos
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item">{productId}</span>
    </div>
  );
};
