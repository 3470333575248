import React from "react";
import { BiSolidError } from "react-icons/bi";
import "./SuccessModal.css";

const ErrorModal = ({ isOpen, onClose, message, title }) => {
  console.log(isOpen, message, title);
  return (
    isOpen && (
      <div className="generic-success-modal-overlay" style={{zIndex:100}}>
        <div className="col-3 generic-success-modal-content">
          <div className="modal-title">{title}</div>
        <button className="generic-success-modal-close" onClick={onClose}>X</button>
        <BiSolidError  size={65} color="var(--primary-color)" />
          <div className="generic-success-message">{message}</div>
          <button className="col-12 default-button" onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    )
  );
};

export default ErrorModal;
