import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Logo from "../assets/svg/LogoSVG";
import { URL } from "../config";
import ModalForgotPassword from "./ModalForgotPassword";
import { useDispatch } from "react-redux";
import { fetchMessagesById } from "../redux/messageSlice";
import { AuthContext } from './AuthContext';
import "./LoginPage.css";

const LoginPage = ({ onLogin, user }) => {
  const { loginUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
   
    
    setTimeout(() => {
      if (mounted) setLoading(true);
      
    }, 1000);
    return () => {
      setMounted(false);
      window.removeEventListener("resize", handleResize);
    };
    
  }, [mounted, isMobile]);

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await fetch(`${URL}/webland-api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        dispatch(fetchMessagesById(data.id))
  
        if (!data.active) {
          setErrorMessage("A sua conta ainda não foi ativada");
          return;  // Exit early if the account is not active
        }
        if(data){

          loginUser(data);
          
          localStorage.setItem("user", data.role);
          localStorage.setItem("id", data.userId);
          localStorage.setItem("userId", data.id);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", data.token)
          
          onLogin(true);
        }

        if (data.role.includes("client")) {
          !isMobile && history.push("/client-market");
          isMobile && history.push("/market");
        } else if (data.role.includes("producer")) {
          history.push(`/${data.role}?id=${data.userId}`)
        } else if (data.role.includes("admin")) {
          history.replace("/dashboard");
        }
      } else {
        const errorData = await response.json();
       // console.log("Error message:", errorData.error);
        setShowForgotPassword(true);
        setErrorMessage(errorData.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Ocorreu um erro. Por favor, tente novamente.");
    } finally {
      setLoading(false); // Set loading to false after the login attempt is complete
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };

  const submitResetPassword = async () =>{
    const fetchUserEmail = await fetch(`${URL}/webland-api/user/check-username/${username}`);
    if (fetchUserEmail.ok) {
      const data = await fetchUserEmail.json();
      setEmail(data.email);
      setShowForgotPasswordModal(true);
      setErrorMessage(`Um email foi enviado para: ${data.email}`)
    }
  }

  const sendResetPasswordEmail = async () =>{
    const response = await fetch(`${URL}/webland-api/user/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });


    if (response.ok){
      setErrorMessage("Email enviado com sucesso!!!");
      setShowForgotPasswordModal(false)
    }

    else setErrorMessage("Ocorreu um erro")
    
    
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className={`login-page-container ${loading ? "" : "fade-in"}`}>
      <Logo />
      <form className={`login-input-group ${loading ? "" : "fade-in"}`}>
        <label
          className="login-input-group-item"
          style={{ padding: "0.1rem 0rem", marginBottom: "0.2rem" }}
        >
          username
        </label>
        <input
          className="login-input-group-item"
          type="text"
          placeholder=""
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setErrorMessage("");
          }}
        />
        <label
          className="login-input-group-item"
          style={{ padding: "0.1rem 0rem", marginBottom: "0.2rem" }}
        >
          password
        </label>
        <input
          className="login-input-group-item"
          type="password"
          placeholder=""
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorMessage("");
          }}
          onKeyDown={handleKeyPress}
        />
      </form>
      <div className={`error-message-wrapper ${loading ? "" : "fade-in"}`}>
        {errorMessage&&(
          <p className="error-message">{errorMessage}</p>
        )}

        {showForgotPassword && <div className="forgot-password-wrapper">Esqueceu-se da password? <a style={{color:"var(--focus-color)", textDecoration:"underline"}} onClick={submitResetPassword}>clique aqui</a></div>}
      </div>
      {
  showForgotPasswordModal && 
  <ModalForgotPassword 
    onClose={() => setShowForgotPasswordModal(false)} 
    onSubmit={sendResetPasswordEmail}
    email={email}
  />
}
      <button
        className={`default-button ${loading ? "" : "fade-in"}`}
        onClick={handleLogin}
        disabled={!username && !password ? true : false}
      >
        LOGIN
      </button>
      
        <div className="login-input-group">
          Ainda não tem conta?{" "}
          {isMobile ?
          <Link className="register-link-item" to="/register">
            REGISTAR
          </Link>
         
          :
          <Link className="register-link-item" to="/users/registar">
            REGISTAR
          </Link>
        
          }
        </div>
      
      <div className="login-input-group">
        <div
          className="footer-developer"
          style={{ fontSize: "small", marginTop: "3rem" }}
        >
          &copy;2023 -{" "}
          <span
            style={{ textDecoration: "none", color: "var(--secondary-color)" }}
          >
            developed by{" "}
          </span>
          <b style={{ color: "var(--primary-color)" }}>TECH</b> @{" "}
          <a
            href="http://www.morecolab.pt"
            style={{ textDecoration: "none", color: "var(--secondary-color)" }}
          >
            MORECOLAB.PT
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
