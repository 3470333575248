import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineUserAdd, AiOutlineContacts } from "react-icons/ai";
import "./AdminProfile.css";
import { URL } from "../../config";
import SuccessModal from "../Desktop/SuccessModal";
import DeleteConfirmationModal from "../Desktop/DeleteModal";

const AdminProfile = () => {
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
 
  });

  const [newAdminData, setNewAdminData] = useState({
    newUsername: "",
    newEmail: "",
    newPassword: "",
    newConfirmPassword: "",
  });

  const [adminUsers, setAdminUsers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentAdminForDeletion, setCurrentAdminForDeletion] = useState(null);

  const history = useHistory();

  useEffect(() => {    
    fetchAdminProfile();
    fetchAdmins();  
}, []);

  const fetchAdminProfile = () => {
   // Fetch current user data
   fetch(`${URL}/webland-api/user/${localStorage.getItem("userId")}`)
   .then((res) => res.json())
   .then((data) =>
     setUserData({
       ...userData,
       username: data.username,
       email: data.email,
     })
   )
   .catch((error) => console.error("Error fetching user data:", error));
  }

  const fetchAdmins = () => {
    fetch(`${URL}/webland-api/user/admin/all`)
    .then(res => res.json())
    .then(data => setAdminUsers(data))
    .catch(error => console.error('Error fetching admin users:', error));
  }

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Update user data
    // Check for password match if password is being changed
    if (userData.password !== userData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    fetch(`${URL}/webland-api/user/${localStorage.getItem("userId")}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error updating user data");
        return res.json();
      })
      .then(() => {
        alert("Admin atualizado com successo!");
        history.push("/dashboard"); // Redirect to dashboard or appropriate page
      })
      .catch((error) => console.error("Error updating user:", error));
  };

  const handleNewAdminInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdminData({ ...newAdminData, [name]: value });
  };

 

  const handleEditClick = () => {
    setEditable(!editable);
  };
  
  
  const handleNewAdminSubmit = (e) => {
    e.preventDefault();
  
    // Validate the new admin data before submission
    if (
      !newAdminData.newUsername ||
      !newAdminData.newEmail ||
      !newAdminData.newPassword ||
      newAdminData.newPassword !== newAdminData.newConfirmPassword
    ) {
      alert("Por favor preencha todos os campos!");
      return;
    }
  
    // Prepare the data to be sent to the backend
    const adminData = {
      username: newAdminData.newUsername,
      email: newAdminData.newEmail,
      password: newAdminData.newPassword,
      role: "admin", // Assign the 'admin' role to the new user
    };
  
    // POST request to your backend
    fetch(`${URL}/webland-api/user/register-admin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(adminData),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Erro ao criar novo utilizador admin");
        return res.json();
      })
      .then((data) => {
        setSuccessModalMessage('Novo utilizador admin criado com sucesso!');
        setShowSuccessModal(true); // Show success modal
        setNewAdminData({ newUsername: "", newEmail: "", newPassword: "", newConfirmPassword: "" }); // Reset form
        setAdminUsers([...adminUsers, data]); // Add the new admin to the state
      })
      .catch((error) => console.error("Erro ao criar novo utilizador admin:", error));
  };
  

   const handleDeleteClick = (adminId) => {
    setCurrentAdminForDeletion(adminId);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
   
      deleteAdmin(currentAdminForDeletion);
      setShowDeleteModal(false); // Hide the modal after confirming
      
  };

  const deleteAdmin = (adminId) => {
    fetch(`${URL}/webland-api/user/delete-admin/${adminId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      // Include the token in your API call for authentication
    })
    .then((res) => {
      if (!res.ok) throw new Error('Erro ao eliminar admin');
      setSuccessModalMessage('Admin eliminado com sucesso!');
      setShowSuccessModal(true); // Show success modal
      return res.json();
    })
    .then(() => {
      // Remove the deleted user from the state
      setAdminUsers(adminUsers.filter((user) => user._id !== adminId));
    })
    .catch((error) => console.error('Error:', error));
  };
  

  return (
    <><div
      className="admin-profile"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div className="col-6 ">
        <div className="col-12" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>

        <h1>Perfil Admin</h1>
        <button className="admin-edit-btn" onClick={handleEditClick}>{editable ? "Cancelar" : "Editar"}</button>
        </div>
        <form onSubmit={handleSubmit} className="col-6 admin-profile-form">
          <label>
            Utilizador:
            <input type="text" name="username" value={userData.username} onChange={handleInputChange} disabled={!editable} />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={userData.email} onChange={handleInputChange} disabled={!editable} />
          </label>
          {editable && (
            <>
              <label>
                Nova Password:
                <input type="password" name="password" value={userData.password} onChange={handleInputChange} />
              </label>
              <label>
                Confirmar Password:
                <input type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleInputChange} />
              </label>
              <button type="submit">Atualizar Perfil</button>
            </>
          )}
        </form>
      </div>
    

      <div className="col-6 ">
        <h1>Novo Admin</h1>
        <form onSubmit={handleNewAdminSubmit} className="col-11 admin-profile-form">
          <label>
            Utilizador:
            <input
              type="text"
              name="newUsername"
              value={newAdminData.newUsername}
              onChange={handleNewAdminInputChange} />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="newEmail"
              value={newAdminData.newEmail}
              onChange={handleNewAdminInputChange} />
          </label>
          <label>
             Password:
            <input
              type="password"
              name="newPassword"
              value={newAdminData.newPassword}
              onChange={handleNewAdminInputChange} />
          </label>
          <label>
            Confirmar Password:
            <input
              type="password"
              name="newConfirmPassword"
              value={newAdminData.newConfirmPassword}
              onChange={handleNewAdminInputChange} />
          </label>
          <button type="submit">Criar</button>
        </form>
      </div>
      <SuccessModal 
          isOpen={showSuccessModal}
          onClose={() =>{
            setShowSuccessModal(false);
            fetchAdmins();
          }}
          title="Success"
          message={successModalMessage}
        />
    </div> <div className="admin-profile mt-4" >
        <h2> Administradores do sistema </h2>
        <div className="admin-table-container" style={{height:"30vh"}}>
        <table className="admin-table">
          <thead>
            <tr>
              <th>Utilizador</th>
              <th>Email</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {adminUsers.length > 0 ? (
              adminUsers.map((admin) => (
                <tr key={admin._id}>
                  <td>{admin.username}</td>
                  <td>{admin.email}</td>
                  <td>
                    <button onClick={() => console.log("Desactivar", admin._id)}>Desativar</button>
                    <button onClick={() => handleDeleteClick(admin._id)}>Apagar</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>Sem Utilizadores Admin</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDelete}
          title={"Tem a cereteza que deseja eliminar este admin?"}
      />
      </div></>
  );
};

export default AdminProfile;
