// DeleteConfirmationModal.js
import React from "react";
import "./UsersAdmin.css"; // Substitua pelo nome do seu arquivo CSS

const DeleteConfirmationModal = ({ show, onClose, onConfirm }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <button className="close-button" onClick={onClose} >
          X
        </button>
        <p>Tem certeza de que deseja excluir este utilizador?</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>Sim</button>
          <button style={{marginLeft:"5%"}}  onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
