import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import {useHistory} from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import {AiFillHome, AiOutlineMinusSquare} from "react-icons/ai";
import {MdOutlineAddBox, MdOutlineMinusBox} from "react-icons/md";
import "./Metrics.css";
import { AuthContext } from "../AuthContext";


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

function TransformadorMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [transformadorData, settransformadorData] = useState({});
  const [transformadorId, settransformadorId] = useState(null);
  const [, dashboard, , type] = history.location.pathname.split("/");
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersType, setOrdersType] = useState({
    processing: 0,
    shipped: 0,
    delivered: 0
  });
  const [transformerOrders, settransformerOrders] = useState(null);
  const [allTranformerOrders, setallTranformerOrders] = useState(null);


  const {token} = useContext(AuthContext)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("id");

    if (idFromURL) {
      settransformadorId(idFromURL);
      fetchTransformerData(idFromURL);
      fetchtransformerOrders(idFromURL);
    }
    console.log("transformador",transformerOrders)
  }, []);

 

  const renderTransformer = () => {
    const {
      _id,
      address,
      name,
      nif,
      phoneNumber,
      email,
      distrito,
    } = transformadorData;

    return (
      <>
        <div className="col-12 transformer-info" style={{ display: "flex" }}>
          <div className="col-8 producer-info">
            <div className="col-xl-4 col-md-12 col-sm-12">
              <br />
              <div>
                <strong>{transformadorData[0].name}</strong>
              </div>
              <div>
                <strong>NIF:</strong> {transformadorData[0].nif}
              </div>
              <div>
                <strong>Email:</strong> {transformadorData[0].email}
              </div>
              <div>
                <strong>Telefone:</strong> {transformadorData[0].phoneNumber}
              </div>
              <br />
              <div>
                <strong>Morada:</strong> {transformadorData[0].address}
              </div>
              <div>
                <strong>Zona:</strong> {transformadorData[0].distrito}
              </div>
              <br />
            </div>
          </div>
          <div className="col-3 boolean-field" style={{ marginTop: "3%" }}>
          <div>
              <span>Secagem:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.secagem ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.secagem ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Descasque:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.descasque ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.descasque ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Limpeza:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.limpeza ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.limpeza ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Taninos:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.taninos ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.taninos ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Torra:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.torra ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.torra ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Moagem:</span>
              <span
                className={`status-indicator ${
                  transformadorData[0]?.moagem ? "yes" : "no"
                }`}
              >
                {transformadorData[0]?.moagem ? "Sim" : "Não"}
              </span>
            </div>
           
          </div>
        </div>
      </>
    );
  };

  const renderWarehouseHistory = (warehouseHistory) => {
    return warehouseHistory.map((item, index) => {
      const quantityColor = item.transactionType === "IN" ? "#43806c" : "#ad1126";
      return (
        <div key={index} className="col-12 warehouse-history-item">
          <span className="col-4">Espécie: {item.acornType}</span>
          <span className="col-5" style={{ color: quantityColor }}>
            Quantidade: <b>{item.quantity}</b>
          </span>
          <span className="col-3">Movimento: {item.transactionType === "IN" ? <MdOutlineAddBox color="#43806c"/> : <AiOutlineMinusSquare color="#ad1126"/>}</span>
        </div>
      );
    });
  };

  const fetchtransformerOrders = async (id) => {
    try {
      const orderResponse = await fetch(
        `${URL}/webland-api/order/transformador/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const orders = await orderResponse.json();
      setOrdersCount(orders.length); // Update the orderCount for each client
      settransformerOrders(orders.reverse());
      setallTranformerOrders(orders);
      console.log("orders",allTranformerOrders);
           
        const processingCount = orders.filter((item) => item.status === "Processing").length;
        const deliveredCount = orders.filter((item) => item.status === "Delivered").length;
        const shippedCount = orders.filter((item) => item.status === "Shipped").length;


        setOrdersType({ 
        processing: processingCount,
        delivered: deliveredCount,
        shipped: shippedCount
        });
    } catch (orderError) {
      console.error("Error fetching order data:", orderError);
    }
  };



  
  const fetchTransformerData = (id) => {
    fetch(`${URL}/webland-api/transformer/${id}` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data === 'object' && !Array.isArray(data)) {
          const dataArray = [data];
  
          const produtorWithType = dataArray.map((produtor) => ({
            ...produtor,
            //name: "produtor",
           // processingSteps: produtor.processingSteps ? produtor.processingSteps.map((item) => item.processingSteps) : [],
          }));
           console.log(produtorWithType)
          settransformadorData(produtorWithType);
          setIsLoading(false);
        } else {
          // Trate o caso em que os dados não estão no formato esperado.
        }
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };
  
  

  return isLoading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <div>
      <div className="col-12 breadcrumb-wrapper">
        <span className="col-12 breadcrumb-item"><AiFillHome size={15} style={{marginLeft:"0.5rem"}}/></span>
            <span className="col-12 breadcrumb-item" onClick={()=>history.push("/dashboard")}>{String(dashboard).toUpperCase()} &gt; </span>
            <span className="col-12 breadcrumb-item" onClick={()=>history.push("/dashboard/transformadores")}>Transformadores &gt;</span>
            <span className="col-12 breadcrumb-item" >{transformadorId}</span>
        </div>
        <div  className="col-12 producer-metrics-container">

      {renderTransformer()}
      
        </div>
        <div className="row">
        <div className="orders-history-container">
          <h5 className="card-title">Histórico Encomendas</h5>
          <div className="warehouse-history-header">
            <div className="warehouse-history-cell">Data da Encomenda</div>
            <div className="warehouse-history-cell">N. Encomenda</div>
            <div className="warehouse-history-cell">Produtor</div>
            <div className="warehouse-history-cell">Qt.</div>
            <div className="warehouse-history-cell">Estado</div>
            <div style={{width:"4.5rem"}}></div>
          </div>
          <div className="warehouse-history-body">
            {!isLoading && transformerOrders ? (
              transformerOrders && transformerOrders.map((item, index) => (
                <div key={index} className="warehouse-history-item">
                  <span className="warehouse-history-cell">
                    {new Date(item.orderDate).toLocaleDateString()}
                  </span>
                  <span className="warehouse-history-cell">{item._id}</span>
                  <span className="warehouse-history-cell">
                    {item?.selectedProducer?.producerId}
                  </span>
                  <span className="warehouse-history-cell">
                    {item.totalAmount}
                  </span>
                  <span className="warehouse-history-cell">{item.status}</span>
                  <button
                        style={{
                          padding: "0.5rem 1.0rem",
                          fontSize: "x-small",
                        }}
                        className="card"
                        onClick={() => {
                          //toggleSelectedOrder(order._id);
                          history.push(
                            `/orders/details?id=${item._id}&transformador=${transformadorId}`
                          );
                          //handleSelection({ ...order, clients });
                        }}
                      >
                        Detalhes
                      </button>
                </div>
              ))
            ) : (
              <div
                className="warehouse-history-item"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "2rem 0rem",
                }}
              >
                O Transformador ainda não possui encomendas
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default TransformadorMetrics;
