import React, { useState, useEffect, useContext } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MapRoute from "./Map/MapRoute";
import "./Simulation.css"
import ErrorModal from "../Desktop/ErrorModal";




const Simulation = () => {
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');

    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [routeDistance, setRouteDistance] = useState({
        producerToTransformer: "0 km",
        transformerToCustomer: "0 km"
      });
      
  
    // Add states for storing the options to be displayed in the drop-downs
    const [clientsOptions, setClientsOptions] = useState([]);
    const [productsOptions, setProductsOptions] = useState([]);
    const [producersList, setProducersList] = useState([]);
    const [transformersList, setTransformersList] = useState([]);
    const [selectedProducerInfo, setSelectedProducerInfo] = useState(null);
    const [selectedTransformerInfo, setSelectedTransformerInfo] = useState(null);
    const [selectedClientInfo, setSelectedClientInfo] = useState(null);
    const [rates, setRates] = useState([]);
    const [showModalError, setShowModalError] = useState(false);

    
  
    const { token } = useContext(AuthContext);

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1); // Function to move to the next step
      };
  
    // Fetch the options for the drop-downs
    useEffect(() => {   
      fetchClients();
      fetchProducts();
    }, []);

    
    useEffect(() => {
        if (selectedProducerInfo) {
            // Logic to proceed to the next step
            // For example, fetching transformers based on the selected producer
            fetchTransformers(amount, selectedProducerInfo, selectedClient);
        }
    }, [selectedProducerInfo, amount, selectedClient]); // Add all dependencies here
    
  

   // Inside your component

  
  // You don't need useEffect to call setRouteDistance because handleRouteData
  // will be triggered when the route data is updated from the child component
  

    const fetchProducts = () => {
        fetch(`${URL}/webland-api/product/products`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setProductsOptions(data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
       // console.log("teste", setProducts);
      };
      
    
      const fetchClients = () => {
        fetch(`${URL}/webland-api/client/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        })
          .then((response) => response.json())
          .then((data) => {
           
            const sortedClientes = data.sort((a, b) => a.name.localeCompare(b.name));
      
            // Finally, update your state with the resolved and sorted data
            setClientsOptions(sortedClientes);

            console.log(sortedClientes);
      
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching client data:", error);
          });
      };

      const fetchTransformers = async (totalAmount, selectedProducer, selectedClientId) => {
        setIsLoading(true);
        try {
          // Assuming you have the full details of clients somewhere, find the selected client's details
          const clientDetails = clientsOptions.find(client => client._id === selectedClientId);
          if (!clientDetails) {
            throw new Error('Client details not found');
          }
          setSelectedClientInfo(clientDetails)
          const clientLocation = { lat: clientDetails.lat, long: clientDetails.long };
      
          // Similarly, ensure you have the producer's location details. If `selectedProducerInfo` holds this data:
          const producerLocation = { lat: selectedProducerInfo.producer.lat, long: selectedProducerInfo.producer.long };
      
          const response = await fetch(`${URL}/webland-api/order/select-transformer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              totalAmount,
              producerLocation,
              clientLocation
            })
          });
      
          if (!response.ok) throw new Error('Network response was not ok.');
          const { selectedTransformer, pesoMaterialBrutoNecessario } = await response.json();
          console.log(selectedTransformer);
          setTransformersList(selectedTransformer); // Adjust according to your actual data structure
          nextStep() // Proceed to the transformer selection step
        } catch (error) {
          console.error('Error fetching transformers:', error);
        } finally {
          setIsLoading(false);
        }
      };

      const fetchRates = async () => {
        try {
          const ratesResponse = await fetch(`${URL}/webland-api/rates/conversion-rate`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
      
          if (!ratesResponse.ok) {
            throw new Error(`HTTP error! Rates status: ${ratesResponse.status}`);
          }
      
          const dataRates = await ratesResponse.json();
          
          setRates(new Set(dataRates.map(rate => rate.product._id)));  
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle the error state appropriately
        } 
      }
    
      useEffect(() => {
        fetchRates();      
      }, [rates]);
      
  
      const handleClientChange = (e) => {
        setStep(0)
        const clientId = e.target.value;
        setSelectedClient(clientId);
        setSelectedProducerInfo(null);
        setSelectedTransformerInfo(null);
        const clientInfo = clientsOptions.find(client => client._id === clientId);
        setSelectedClientInfo(clientInfo);
    };
    
  
    const handleProductChange = (e) => {
      const hasRates = [...rates].some(rate => rate === e.target.value);
      
      if (hasRates) {
        setSelectedProduct(e.target.value);
      } else {
        setShowModalError(true);
        setSelectedProduct("");
        // console.log("Produto não tem taxa de conversão");
      }
    };
  
    const handleAmountChange = (e) => {
      setAmount(e.target.value);
    };
  
    const handleSimulation = () => {
      setStep(0)
      selectProducer(selectedProduct, selectedClient, amount);
    };

    const selectProducer = async (productId, clientId, quantity) => {
        setIsLoading(true);
        try {
          const response = await fetch(`${URL}/webland-api/order/select-producer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ productId, clientId, quantity })
          });
          if (!response.ok) throw new Error('Network response was not ok.');
          const producers = await response.json();
          // Store selected producer in the state and move to next step
          setProducersList(producers[0]);
          console.log(producers);
          nextStep(); // Proceed to the transformer selection step
        } catch (error) {
          console.error('Error selecting producer:', error);
          // Handle the error (show a message to the user, etc.)
        }
        setIsLoading(false);
      };

      const onProducerSelected = (producerId) => {
        // Assuming producersList contains full details including location
        const selectedProducerDetails = producersList.find(p => p.producer._id === producerId);
        if (!selectedProducerDetails) {
          console.error("Selected producer details not found");
          return;
        }
        setSelectedProducerInfo(selectedProducerDetails); // Store the full details of the selected producer
      
        // Now you need the total amount for the transformer selection, assuming amount is directly usable
        // and you have the client's ID stored in `selectedClient`
        fetchTransformers(amount, selectedProducerDetails, selectedClient);
      };

      const onTransformerSelected = (transformerId) => {
        // Assuming producersList contains full details including location
        const selectedTransformerDetails = transformersList.find(p => p._id === transformerId);
        if (!selectedTransformerDetails) {
          console.error("Selected producer details not found");
          return;
        }
        setSelectedTransformerInfo(selectedTransformerDetails); // Store the full details of the selected producer
       console.log('Transformer selected:', selectedTransformerDetails);
        // Now you need the total amount for the transformer selection, assuming amount is directly usable
        // and you have the client's ID stored in `selectedClient`
        //fetchTransformers(amount, selectedProducerDetails, selectedClient);
        nextStep();
      };
      
      
      const renderProducerSelection = () => {
        return (
          <div className="producer-selection-container">
            {producersList?.map((producer) => (
              <div key={producer.producer._id} className="producer-card">
                <div className="producer-info">
                  <p>Nome: <b>{producer.producer.name}</b> </p>
                  <p>Localidade: <b>{producer.producer.city}</b> </p>
                  <p>Preço Total: <b>{producer.totalPrice.toFixed(2)} €</b> </p>
                  <p>Distância: <b>{(producer.distance/1000).toFixed(0)} Km</b>  </p>
                </div>
                <button
                  className="select-producer-btn"
                  onClick={() => onProducerSelected(producer.producer._id)}
                >
                  Selecionar Produtor
                </button>
              </div>
            ))}
          </div>
        );
      };

      const renderTransformerSelection = () => {
        return (
          <div className="producer-selection-container">
            {transformersList?.map((transformer) => (
              <div key={transformer._id} className="producer-card">
                <div className="producer-info">
                  <p>Nome: <b>{transformer.name}</b></p>
                  <p>Localidade: <b>{transformer.distrito}</b></p>
                  <p>Processos: <b>{transformer.processType}</b></p>
                  <p>Distância ao Produtor: {(transformer.distanceToProducer/1000).toFixed(2)} km</p>
                        <p>Distância ao Cliente: {(transformer.distanceToClient/1000).toFixed(2)} km</p>
                        <p>Distância Total: <b>{(transformer.distance/1000).toFixed(2)} km</b> </p>
                
                </div>
                <button
                  className="select-producer-btn"
                  onClick={() => onTransformerSelected(transformer._id)}
                >
                  Selecionar Transformador
                </button>
              </div>
            ))}
          </div>
        );
      };


      const renderStep = (stepNumber) => {
        switch(stepNumber) {
          case 1:
            // Logic for rendering the producer selection
            return (
              <div className="simulation-step">
                <h2>1. Selecione o Produtor</h2>
                {renderProducerSelection()}
              </div>
            );
          case 2:
            // Logic for rendering the transformer selection
            return (
              <div className="simulation-step">
                <h2>2. Selecione o Transformador</h2>
                {renderTransformerSelection()}
              </div>
            );
            case 3:
                // Ensure producer, transformer, and customer data are ready and structured as needed
                // Assuming `selectedProducerInfo`, `selectedTransformer`, `selectedCustomer` hold the necessary details
                console.log(selectedProducerInfo, selectedTransformerInfo, selectedClientInfo);
                // These should include at least {lat, long} but could also contain additional data for popups, etc.
                const producerCoordinates = { 
                  lat: selectedProducerInfo.producer.lat, 
                  long: selectedProducerInfo.producer.long,        
                  ...selectedProducerInfo // Assuming this contains additional details
                };
                const transformerCoordinates = { 
                  lat: selectedTransformerInfo.lat, 
                  long: selectedTransformerInfo.long,      
                  ...selectedTransformerInfo // Add additional transformer details if available
                };
                const customerCoordinates = { 
                  lat: selectedClientInfo.lat, 
                  long: selectedClientInfo.long,
                  ...selectedClientInfo // Add additional customer details if available
                };

                
                return (
                  <div className="simulation-step">
                    <h2>3. Encomenda Atribuída</h2>
                    {/* Render MapRoute with the prepared coordinates */}
                    {step === 3 && selectedProducerInfo && selectedTransformerInfo && selectedClientInfo && (
                    <MapRoute
                        producer={producerCoordinates}
                        transformer={transformerCoordinates}
                        customer={customerCoordinates}
                        routeData={(data) => console.log(data)} // Handle route data as needed
                    />
                    )}
                    {/* <div className="distance-info">
                          {(
                            <div>
                              <div className="route-legend">
                                <span className="route-color producer-to-transformer"></span>
                                <span>Produtor ao Transformador</span>
                                <span className="route-distance">
                                  {routeDistance?.producerToTransformer}
                                </span>
                              </div>
                              <div className="route-legend">
                                <span className="route-color transformer-to-customer"></span>
                                <span>Transformador ao Cliente</span>
                                <span className="route-distance">
                                  {routeDistance?.transformerToCustomer}
                                </span>
                              </div>
                              <div className="route-legend">
                                <span className="route-color"></span>
                                <span>Distância Total</span>
                                <span className="route-distance">
                                  <b>{parseFloat(routeDistance?.transformerToCustomer.split("km")[0]) + parseFloat(routeDistance?.producerToTransformer.split("km")[0])} km</b>
                                </span>
                              </div>
                            </div>
                          )}
                        </div> */}
                  </div>
                );
              
        }
      };
      

  return (
    
   
       <><div className="bolota-card" style={{ margin:0, marginRight: "1.5rem" }}>

        <div className="settings">
        <div>
          <label htmlFor="client-dropdown">Cliente:</label>
          <select id="client-dropdown" value={selectedClient} onChange={handleClientChange}>
          <option value="" disabled>Por favor, selecione um cliente</option>
            {clientsOptions.map((client) => (
              <option key={client._id} value={client._id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="product-dropdown">Produto:</label>
          <select id="product-dropdown"  value={selectedProduct} onChange={handleProductChange}>
          <option value="" disabled>Por favor, selecione um produto</option>
            {productsOptions.map((product) => (
              <option key={product._id} value={product._id}>
                {product.type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="amount">Quantidade:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={handleAmountChange}
            />
        </div>
        <button className="simulate-btn" onClick={handleSimulation}>SIMULAR</button>
      </div>
            {showModalError && <ErrorModal isOpen={showModalError} onClose={()=> setShowModalError(false)} title={"Erro"} message={"Este produto ainda não tem tabela de rendimento associado"} /> }

      

       
     



    </div><div className="card-table-header mt-3" style={{marginRight: "1.5rem", padding: "0.5rem 0rem" }}>

        <div className="col-12 card-table-title">Simular Encomenda</div>

      </div>
      
      <div className="card-table-body" style={{marginRight: "1.5rem", height:"62vh", alignItems:"start"}}>
      { isLoading ? <div className={"order-spinner"}><Spinner/> </div> :  
      
      <div className="table-scroll-container" style={{height:"100%"}}>
        {selectedClientInfo && (
  <div className="selected-step-info">
    <p>Cliente selecionado: {selectedClientInfo.name}</p>
    <p>Localidade: {selectedClientInfo.city || 'N/A'}</p>
    <p>Email: {selectedClientInfo.email || 'N/A'}</p>
    {/* Add more fields as needed */}
  </div>
)}

        {selectedProducerInfo && (
      <div className="selected-step-info">
        <p>Produtor selecionado: {selectedProducerInfo.producer.name}</p>
        <p>Localidade: {selectedProducerInfo.producer.city}</p>
        <p>Preço Total: {selectedProducerInfo.totalPrice.toFixed(2)} €</p>
        <p>Distância: {(selectedProducerInfo.distance/1000).toFixed(0)} km</p>
      </div>
    )}
     {selectedTransformerInfo && (
      <div className="selected-step-info">
        <p>Transformador selecionado: {selectedTransformerInfo.name}</p>
        <p>Localidade: {selectedTransformerInfo.distrito}</p>
        <p>Processo: {selectedTransformerInfo.processType}</p>
        <p>Distância: {(selectedTransformerInfo.distance/1000).toFixed(0)} km</p>
      </div>
    )}
      {renderStep(step)}
        </div>}
      </div>
      </>
       
     
   
  );
};

export default Simulation;
