import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Webland from "../../assets/svg/WeblandSVG";
import Acorn from "../../assets/svg/LogoAcornSVG";
import { MdStorefront } from "react-icons/md";
import { FaUserAlt, FaShoppingBasket } from "react-icons/fa";
import "./mobile-client.css"

const MobileClient = () => {
    const history = useHistory();
  return (
    <>
    <div className="col-12">
        <div className="col-12" style={{textAlign:"center"}}><Acorn/></div>
        <div className="dashboard-container">
            <button className="col-8 btn btn-market" onClick={()=> history.push("/market")}>
                <div>Market</div>
                <div><MdStorefront size={40}/></div>
            </button>
            <button className="col-8 btn btn-account" onClick={()=> history.push("/account")}>
                <div>Conta</div>
                <div><FaUserAlt size={35}/></div>
            </button>
            <button className="col-8 btn btn-basket" onClick={()=> history.push("/basket")}>
                <div>Cesto</div>
                <div><FaShoppingBasket size={40}/></div>
            </button>
        </div>
     
    </div>
    <div className="col-12 floating-menu-container" style={{position:"fixed", bottom:"10px", zIndex:1}}><Webland/></div>
    </>
  );
};

export default MobileClient;
