import React, { useState, useEffect, useContext } from "react";
import SuccessModal from "./SuccessModal";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";

const EditBolotaModal = ({ isOpen, onClose, onEdit, initialProduct }) => {
  const [quantity, setQuantity] = useState(initialProduct?.quantity?.toString() || '');
  const [price, setPrice] = useState(initialProduct?.price?.toString() || '');
  const [bolota, setBolota] = useState(initialProduct?.acornType?.toString() || '');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [userData, setUserData] = useState(null); // Variável para armazenar os dados do usuário
  const [useremail,setuseremail]= useState(null);
  const {token} = useContext(AuthContext)


  


  


  useEffect(() => {

    
    console.log("localStorage",localStorage)
    let idteste="";
    if(localStorage.user==="admin"){
      const urlParams = new URLSearchParams(window.location.search);
      const EmailFromURL = urlParams.get("email");
      setuseremail(EmailFromURL);
    }
    
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token de autorização não encontrado");
          return;
        }
  
        const response = await fetch(`${URL}/webland-api/producer/${localStorage.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          setuseremail(data[0].email); // Move esta linha para dentro do bloco if
        } else {
          console.error("Falha ao buscar dados do usuário");
        }
      } catch (error) {
        console.error("Erro:", error);
      }
    };
  
    fetchUserData();
  }, [userData]); // Adicione userData como uma dependência
  
  
  const addOrUpdateStock = async () => {
    const url = `${URL}/webland-api/producer/updateStock`;

    const data = {
      email: useremail,
      acornType: bolota,
      quantity: quantity,
      price: price,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSuccessModalOpen(true);
      } else {
        console.error('Failed to add/update stock');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const closeSuccessModal = () => {
    setSuccessModalOpen(false); // Close the success modal
    onClose(); // Close the edit modal
  };
  

  return (
    <>
      {isOpen && (
        <div className="add-bolota-modal-overlay" style={{zIndex:100}}>
          <div className="col-5 add-bolota-modal-content">
            <button className="add-bolota-modal-close" onClick={onClose}>
              X
            </button>

            <div className="add-bolota-modal-title">Stock - {bolota}</div>
            <div className="col-12 add-bolota-modal-form">
              <div className="col-12 input-group-item">
                <div className="label">
                  <label className="col-4" style={{textAlign:"left"}}>Quantidade</label>
                  <input
                    className="col-6 add-bolota-input"
                    type="number"
                    placeholder="Nova Quantidade (kg)"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
                <div className="label">
                  <label className="col-4" style={{textAlign:"left"}}>Preço</label>
                  <input
                    className="col-6 add-bolota-input"
                    type="number"
                    placeholder="Novo Preço (€/kg)"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="add-bolota-modal-buttons">
              <button
                className="col-12 default-button"
                onClick={()=>addOrUpdateStock(bolota, quantity, price)}
                disabled={!quantity || !price}
              >
                Alterar
              </button>
            </div>
          </div>
        </div>
      )}
    
       {successModalOpen && (
        <SuccessModal
          isOpen={successModalOpen}
          onClose={closeSuccessModal}
          message="Stock alterado com sucesso!"
          title="Stock"
        />
      )}
    </>
  );
};

export default EditBolotaModal;
