import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import GeolocalAddress from "../Mobile/GeoLocalAddress.js";
import MySelect from "../Mobile/MySelect.js";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { URL } from "../../config";

const EditTransformer = ({ userData, userId, onUpdateClient }) => {
  const [transformerInfo, setTransformerInfo] = useState(userData);
  const [transformerDataFetched, setTransformerDataFetched] = useState(false);
  const [type_service, settype_service] = useState([]);
  const [optionSelectedactivity_sector, setoptionSelectedactivity_sector] = useState([]);
  const [certificates, setCertificates] = useState([]);


  const [processTypes, setProcessTypes] = useState({
    secagem: false,
    descasque: false,
    taninos: false,
    torra: false,
    moagem: false,
    limpeza: false,
  });

  const optionsSpeciesTrans = [
    { value: 'Indústria Alimentar', label: 'Indústria Alimentar' },
    { value: 'Indústria Química', label: 'Indústria Química' },
    { value: 'Biorrefinarias', label: 'Biorrefinarias' },
    
  ];

  
  const fetchTransformerData = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/transformer/transformermail/${userData.email}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setTransformerInfo(data);
        setTransformerDataFetched(true);
      } else {
        console.error("Erro na requisição:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do transformador:", error);
    }
  };

  
const fetchCertificates = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/certificates/get_certificates`);
      if (!response.ok) {
        throw new Error('Erro ao buscar os certificados');
      }
      const data = await response.json();

      const producerCert = data
        .filter(cert => cert.type === "transformador")
        .map(cert => ({ value: cert.type_service, label: cert.type_service }));
        settype_service(producerCert);
      console.log("certificado",data)
    } catch (error) {
      console.error("Erro ao buscar os certificados:", error.message);
    }
  };

 
  useEffect(() => {
    if (!transformerDataFetched) {
      fetchTransformerData();
      fetchCertificates();
    }
  }, [userData.email, transformerDataFetched]);

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTransformerInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, [name]: value };
      onUpdateClient(updatedInfo); 
      return updatedInfo;
    });
  };

  const handleCertificationsTrans = selected => {
    settype_service(selected);
    const selectedCertifications = selected.map(item => item.value);
    setTransformerInfo((prevInfo) => {
        const updatedInfo = { ...prevInfo, type_service: selectedCertifications,  }
        onUpdateClient(updatedInfo);
        return updatedInfo;
    });
};
     

  const handleactivity_sector=selected => {
    setoptionSelectedactivity_sector(selected);
    const selectedCertifications = selected.map(item => item.value);
    setTransformerInfo((prevInfo) => {
        const updatedInfo = { ...prevInfo,  activity_sector: selectedCertifications,  }
        onUpdateClient(updatedInfo);
        return updatedInfo;
    });
};

const handleProcessTypeChange = (e) => {
    const { name, checked } = e.target;
    console.log("o que recebo aqui", name, checked);

    // Atualize o estado processTypes
    setProcessTypes((prevProcessTypes) => ({
      ...prevProcessTypes,
      [name]: checked,
    }));

    // Atualize o estado transformerInfo
    setTransformerInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, [name]: checked };
      onUpdateClient(updatedInfo);
      return updatedInfo;
    });
  };


 

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const mappedtype_service = transformerInfo?.type_service?.map(activity_sector => {
    return type_service.find(option => option.value === activity_sector);
  });
  const mappedactivity_sector = transformerInfo?.activity_sector?.map(activity_sector => {
    return optionsSpeciesTrans.find(option => option.value === activity_sector);
  });

  const animatedComponents = makeAnimated();

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Editar Transformador
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={transformerInfo?.name || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">NIF:</label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={transformerInfo?.nif || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Telefone:</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={transformerInfo?.phoneNumber || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="col-xl-12">Sector de Atividade:</div>
    
        <div className="col-6" style={{ paddingLeft: "0rem" }}>
          <MySelect
            required
            options={optionsSpeciesTrans}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ MultiValue, ...animatedComponents }}
            onChange={handleactivity_sector}
            allowSelectAll={true}
            value={mappedactivity_sector}
          />
        </div>
        <div className="form-group">
          <label>Tipos de Processo:</label>
          <div className="checkbox-group">
            {Object.entries(processTypes).map(([process, value]) => (
              <label key={process} className="checkbox-label">
                <input
                  type="checkbox"
                  name={process}
                  checked={transformerInfo[process]} 
                  onChange={handleProcessTypeChange}
                />
                {process.charAt(0).toUpperCase() + process.slice(1)}
              </label>
            ))}
          </div>
          </div>

      <div className="col-xl-6">
        <label htmlFor="role">Tipo de Serviço:</label>
        <MySelect
          required
          options={type_service}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ MultiValue, ...animatedComponents }}
          onChange={handleCertificationsTrans}
          allowSelectAll={true}
          value={mappedtype_service}
        />
      </div>
  

      <div className="title-register-wrapper mt-3 mb-2">Morada</div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
        </h5>
        <h6 style={{ color: "var(--secondary-color)" }}>
          Clique no marcador para preenchimento automático da morada
        </h6>
        <GeolocalAddress
          onProducerInfoChange={(info) =>
            setTransformerInfo((prevInfo) => {
              const updatedInfo = { ...prevInfo, ...info };
              onUpdateClient(updatedInfo);
              return updatedInfo;
            })
          }
          flyToMyLocation={true}
        />
      </div>

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={transformerInfo?.lat || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={transformerInfo?.long || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={transformerInfo?.street || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="postalCode">Código Postal:</label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          value={transformerInfo?.postalCode || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">Localidade:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={transformerInfo?.city || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="region">Zona:</label>
        <input
          type="text"
          id="region"
          name="region"
          value={transformerInfo?.region || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">País:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={transformerInfo?.country || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default EditTransformer;
