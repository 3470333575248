import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./FloatingMenu.css";
import { MdOutlineWarehouse, MdStorefront } from "react-icons/md";
import { FaUserAlt, FaShoppingBasket } from "react-icons/fa";
import { LuPackageOpen } from "react-icons/lu";
import { useSelector } from "react-redux";
import { selectBasket } from "../../redux/basketSlice";

const FloatingMenu = () => {
  const [activeItem, setActiveItem] = useState("warehouse");
  const user = localStorage.getItem("user");
  const history = useHistory();
  const location = useLocation();
 
  const basket = useSelector(selectBasket);
  const numberOfProducts = basket.products.length;

 

  useEffect(() => {
    // Update the active item based on the URL path
    const path = location.pathname.split("/")[1];
    setActiveItem(path);
  }, [location]);

  const handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        setActiveItem(user.includes("producer") ? "warehouse" : "market");
        history.push(user.includes("producer") ? "/warehouse" : "/market");
        break;
      case 1:
        setActiveItem("account");
        history.push("/account");
        break;
      case 2:
        setActiveItem(user.includes("producer") ? "order" : "basket");
        history.push(user.includes("producer") ? "/order" : "/basket");
        break;
      default:
        return;
    }
  };

  return (
    <div className={`col-12 floating-menu ${activeItem!=="producer" ? "slide-from-bottom" :""}`}>
      <div
        className={`col-4 floating-menu-item ${
          activeItem.includes("warehouse") || activeItem.includes("market") ? "active" : ""
        }`}
        onClick={() => handleMenuItemClick(0)}
      >
        {user.includes("producer") ? <MdOutlineWarehouse size={30} /> : <MdStorefront size={30} />}
      </div>
      <div
        className={`col-4 floating-menu-item ${activeItem.includes("account") || activeItem.includes("detalhes") ? "active" : ""}`}
        onClick={() => handleMenuItemClick(1)}
      >
        <FaUserAlt size={30} />
      </div>
      <div
        className={`col-4 floating-menu-item ${activeItem.includes("order") || activeItem.includes("basket") ? "active" : ""}`}
        onClick={() => handleMenuItemClick(2)}
      >
        {user.includes("producer") ? 
            <LuPackageOpen size={30} color="white" /> 
            : (<>
            {numberOfProducts!== 0 ? 
            <span className={activeItem.includes("basket") ?  `basket-count-negative`: `basket-count`}>{numberOfProducts}</span> : ""}
       
              <FaShoppingBasket size={30} style={{marginLeft: numberOfProducts!== 0 ? "-1.1rem" : 0}}/>
             
            </>)
        }
      </div>
    </div>
  );
};

export default FloatingMenu;
