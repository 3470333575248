import React, { useContext, useEffect, useState, useCallback } from "react";
import { useFilter } from "../../FilterContext";
import Map from "./Map/Map";
import "./Dashboard.css";
import { URL } from "../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {MdMoreHoriz} from "react-icons/md"
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { AuthContext } from "../AuthContext";
import "./Produtores.css"
import DeleteConfirmationModal from "./DeleteModal";


const Produtores = () => {
  const { filter } = useFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [produtoresData, setProdutoresData] = useState([]);
  const [filteredProdutoresData, setFilteredProdutoresData] = useState([]);
  const [currentPageProdutor, setCurrentPageProdutor] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {token} = useContext(AuthContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const history = useHistory();

  const handleRowClick = (index, produtor) => {
    setSelectedRow(index);
    setSelectedRowData(produtor);
  };

  const handleMouseEnter = (index) => {
    // Handle mouse enter event, e.g., change row color on hover
    // Implement your own logic based on your requirements
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event, e.g., reset row color on hover out
    // Implement your own logic based on your requirements
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const requestData = {
        _id: userToDelete._id,
        email: userToDelete.email,
        role:"producer",
        
        // Adicione outros dados que deseja enviar aqui
      };
      console.log("o que recebo",userToDelete);
  
      fetch(`${URL}/webland-api/user/deleteUser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Usuário excluído com sucesso",requestData);
            window.location.reload();
          } else {
            console.error("Erro ao excluir o usuário",requestData);
          }
        })
        .catch((error) => {
          console.error("Erro na solicitação para excluir o usuário:", error);
        });
    }
    setShowDeleteModal(false);
    
  };
  const handleCancelDelete = () => {
    // Feche o modal sem realizar a exclusão.
    setShowDeleteModal(false);
  };


  const SelectedRowInfo = () => {
    const {
      _id,
      address,
      bolotaSpecies,
      createdAt,
      email,
      lat,
      long,
      name,
      nif,
      phoneNumber,
      quantity,
      region,
      stockBolota,
      type,
      updatedAt,
      warehouseHistory,
    } = selectedRowData;
  
   
    return (
        <div className="data-produtores-container">
      
        <div className="col-12 selected-row-data" >

        <div className="col-3"><strong>NIF:</strong> {nif}</div>
        </div>
         <div className="col-12 selected-row-data">
        <div className="col-3"><strong>Name:</strong> <div>{name? name: " - "}</div></div>
        <div className="col-3"><strong>Email:</strong> <div>{email ? email:" - "}</div></div>
        <div className="col-3"><strong>Phone Number:</strong> <div>{phoneNumber? phoneNumber: " - "}</div></div>
        <div className="col-3"><strong>Address:</strong><div> {address ? address: " - "}</div></div>
        </div>
        <div className="col-12 selected-row-data mt-2 mb-3">
        <div className="col-3"><strong>Region:</strong><div> {region ? region : " - "}</div></div>
        <div className="col-3"><strong>Bolota Species:</strong> <div>{bolotaSpecies ? bolotaSpecies :" - "}</div></div>
        <div className="col-3"><strong>Latitude:</strong><div> {lat ? lat : " - "}</div></div>
        <div className="col-3"><strong>Longitude:</strong> <div>{long ? long : " - "}</div></div>
        </div>
        <div></div>
        <div className="col-12 selected-row-data"></div>
        <div className="col-3"><strong>Quantity:</strong> {quantity? quantity.join(', ') : " - "}</div>
        
        <div className="col-3"><strong>Type:</strong> {type ? type : " - "}</div>
        <div className="col-12 mt-2">
          <button className="erase-btn" onClick={() => handleDeleteClick(selectedRowData)} disabled={ selectedRowData._id  ? false : true}>Apagar</button>
          <button className="edit-btn" onClick={() => history.push(`/editusers?id=${selectedRowData._id}&role=producer`)}  disabled={ selectedRowData._id   ? false : true} >Editar</button>
          <button 
            className="metric-btn"
            disabled={ selectedRowData._id  ? false : true}
            onClick={() => history.push(`/dashboard/metrics/producer?id=${selectedRowData._id}`)}>
              Métricas
          </button>
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete} />
      </div>
     
    );
  };

  // Custom hook for debouncing values
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

  

  const [nameFilter, setNameFilter] = useState("");
  const [localFilter, setLocalFilter] = useState("");
  const [zonaFilter, setZonaFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleLocalFilterChange = (event) => {
    setLocalFilter(event.target.value);
  };

  const handleZonaFilterChange = (event) => {
    setZonaFilter(event.target.value);
  };

  const handleTipoFilterChange = (event) => {
    setTipoFilter(event.target.value);
  };

  const handleCleanFilters = () => {
    setNameFilter("");
    setLocalFilter("");
    setZonaFilter("");
    setTipoFilter("");
    setSelectedRowData("");
    setSelectedRow("")
  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  const itemsPerPage = 13;

  const debouncedNameFilter = useDebounce(nameFilter, 500); // 500ms delay
const debouncedLocalFilter = useDebounce(localFilter, 500);
const debouncedZonaFilter = useDebounce(zonaFilter, 500);
const debouncedTipoFilter = useDebounce(tipoFilter, 500);

useEffect(() => {
  if (isLoading) {
    fetchProdutoresData();
  } 
}, [selectedRowData, selectedRowData]);


  const fetchProdutoresData = () => {
    fetch(`${URL}/webland-api/producer/all` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const produtoresWithType = data.map((produtor) => ({
          ...produtor,
          type: "produtor",
          quantity: produtor.stockBolota.map((item) => item.quantity),
        }));
        const sortedProdutores = produtoresWithType
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setProdutoresData(sortedProdutores);
        setFilteredProdutoresData(sortedProdutores);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };

  const createSpeciesButtons = (species) => {
    const allSpecies = ["Azinheira", "Carvalho", "Sobreiro"];
    const speciesMapping = { Azinheira: "A", Carvalho: "C", Sobreiro: "S" };
    const speciesColors = {
      Carvalho: "var(--secondary-color)",
      Azinheira: "var(--secondary-color)",
      Sobreiro: "var(--secondary-color)",
      default: "lightgray",
    };
    const speciesArray = species?.split(",").map((s) => s.trim());

    return allSpecies?.map((speciesKey) => {
      const letter = speciesMapping[speciesKey];
      const isPresent = speciesArray?.some(
        (sp) => sp.toLowerCase() === speciesKey.toLowerCase()
      );
      const color = isPresent
        ? speciesColors[speciesKey]
        : speciesColors["default"];

      return (
        <span
          key={speciesKey}
          className="species-span"
          style={{ backgroundColor: color }}
        >
          {letter}
        </span>
      );
    });
  };

  useEffect(() => {
    console.log("filtro",typeof(filter) === typeof("String"));
    if (typeof(filter) === typeof("String")) {
    console.log("filtro",filter.split("/"));
    setZonaFilter(filter.split("/")[3]);
    setTipoFilter(filter.split("/")[2]);
    } else applyFilters();
  }, [filter]);

  useEffect(() => {
    applyFilters();
  }, [debouncedNameFilter, debouncedLocalFilter, debouncedZonaFilter, debouncedTipoFilter]);
  
  // Make sure applyFilters is wrapped with useCallback and has correct dependencies
  const applyFilters = useCallback(() => {
    let filteredData = produtoresData;
  
    // Apply filters using debounced filter values
    if (debouncedNameFilter) {
      filteredData = filteredData.filter(item =>
        item.name?.toLowerCase().includes(debouncedNameFilter.toLowerCase())
      );
    }
  
    if (debouncedLocalFilter) {
      filteredData = filteredData.filter(item =>
        item.address?.toLowerCase().includes(debouncedLocalFilter.toLowerCase())
      );
    }
  
    if (debouncedZonaFilter) {
      filteredData = filteredData.filter(item =>
        item.region?.toLowerCase().includes(debouncedZonaFilter.toLowerCase())
      );
    }
  
    if (debouncedTipoFilter) {
      filteredData = filteredData.filter(item =>
        item.bolotaSpecies?.toLowerCase().includes(debouncedTipoFilter.toLowerCase())
      );
    }
  
    setFilteredProdutoresData(filteredData);
  }, [debouncedNameFilter, debouncedLocalFilter, debouncedZonaFilter, debouncedTipoFilter, produtoresData]);
  
  

  const startProdutoresIdx = (currentPageProdutor - 1) * itemsPerPage;
  const endProdutoresIdx = Math.min(
    startProdutoresIdx + itemsPerPage,
    produtoresData.length
  );

  return (
    <div className="col-12 row">
      <div className="col-xl-4 col-md-12 col-sm-12 map-container">
        {isLoading ? (
          <div className="col-12 spinner-container">
            <Spinner />
          </div>
        ) : (
          <Map locations={filteredProdutoresData} selectedMarker = {selectedRowData}/>
        )}
      </div>

      <div className="col-xl-8 col-md-12 col-sm-12 tables-container-wrapper">
        <div className="tables-container">
         
          <div className="card-table" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body">
                <div className="col-11 card-table-body-filter">
                  <div
                    className="col-xl-1 col-md-2 col-sm-2"
                    style={{ textAlign: "left" }}
                  >
                    Filtros
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={nameFilter}
                      onChange={handleNameFilterChange}
                      placeholder="Nome"
                      style={{ padding: "0.15rem 0.5rem" }}
                    />
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={localFilter}
                      onChange={handleLocalFilterChange}
                      placeholder="Local"
                      style={{
                        padding: "0.15rem 0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>
                  <div
                    className="col-xl-1 col-md-2 col-sm-6"
                    style={{ width: "5%" }}
                  >
                    <select
                      value={zonaFilter}
                      onChange={handleZonaFilterChange}
                      style={{ marginLeft: "0.5rem" }}
                    >
                      <option value="">Zona</option>
                      <option value="Norte">Norte</option>
                      <option value="Centro">Centro</option>
                      <option value="Sul">Sul</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <select
                      value={tipoFilter}
                      onChange={handleTipoFilterChange}
                    >
                      <option value="">Espécie</option>
                      <option value="Azinheira">Azinheira</option>
                      <option value="Carvalho">Carvalho</option>
                      <option value="Sobreiro">Sobreiro</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <button
                      className="filter-clean-button"
                      onClick={handleCleanFilters}
                    >
                      Limpar
                    </button>
                  </div>
                </div>
              </div>
          
          </div>
          <div className="card-table" style={{background:"white"}}>
            <div className="card-table-header">
              <div
                className="col-2 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageProdutor === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageProdutor}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredProdutoresData.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={endProdutoresIdx >= filteredProdutoresData.length}
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-8 card-table-title">Produtores</div>
              <div className="col-2 card-table-button">
                {/* <button className="card-table-button-add"> <MdMoreHoriz /></button> */}
              </div>
            </div>
            <div className="dashboard-card-table-body" >
              <table className="dashboard-user-table" style={{borderCollapse:"collapse", width:"99%", height:"41vh", marginBottom:"0.5rem"}}>
                <thead style={{ fontSize: "x-small" }}> 
    <tr >
      <th >Nome</th>
      <th >Local</th>
      <th >Zona</th>
      <th >Espécie</th>
      <th >Stock</th>
    </tr>
  </thead>
  <tbody>
    {isLoading ? (
      <div className="col-12 spinner-container">
        <Spinner />
      </div>
    ) : (
      filteredProdutoresData
        .slice(startProdutoresIdx, endProdutoresIdx)
        .map((produtor, index) => (
          <tr key={index}
            className={`${selectedRow === index ? 'tr-produtores selected-row' : 'tr-produtores'}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleRowClick(index, produtor)}
            style={{ borderBottom:"2px solid white"}}
          >
            <td className="">{produtor.name}</td>
            <td className="table-cell">{String(produtor.address).split(",")[String(produtor.address).split(",").length - 1]}</td>
            <td className="table-cell">{produtor.region}</td>
            <td className="producer-category-wrapper">{createSpeciesButtons(produtor.bolotaSpecies)}</td>
            <td className="table-cell stock-cell" style={{marginBottom:"0.5rem"}}>
              {produtor.quantity
                ? produtor.quantity.reduce((acc, val) => acc + val, 0) + " kg"
                : "0 kg"}
            </td>
          </tr>
        ))
    )}
  </tbody>
</table>

            </div>
          </div>
        </div>
        {" "}
          <div className="card-table mt-1" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body" style={{margin:0, padding:0}}>
              
                  {SelectedRowInfo()}
                 
                 
             
              </div>
          
          </div>
      </div>
    </div>
  );
};

export default Produtores;
