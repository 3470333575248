import React, { useState, useEffect, useContext } from "react";
import SuccessModal from "./SuccessModal";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import { RiArrowDropDownLine } from "react-icons/ri"; // Importe o ícone RiArrowDropDownLine
import "./EditBolotaDesktop.css";


const EditBolotaDesktop = ({ isOpen, onClose, onEdit, initialProduct }) => {
    const [quantity, setQuantity] = useState(initialProduct?.quantity?.toString() || '');
    const [price, setPrice] = useState(initialProduct?.price?.toString() || '');
    const [bolota, setBolota] = useState(initialProduct?.acornType?.toString() || '');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [useremail, setUserEmail] = useState(null);
    const [idProdutor, setIdProdutor] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false); // Estado para controlar a abertura do dropdown
    const [selectedSpecies, setSelectedSpecies] = useState(''); // Estado para controlar a espécie selecionada no dropdown
    const { token } = useContext(AuthContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        setIdProdutor(id);
        console.log("id produtor",idProdutor)
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    console.error("Token de autorização não encontrado");
                    return;
                }

                const response = await fetch(`${URL}/webland-api/admin/${idProdutor}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        //"Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserData(data);
                    setUserEmail(data[0]?.email); 
                } else {
                    console.error("Falha ao buscar dados do usuário");
                }
            } catch (error) {
                console.error("Erro:", error);
            }
        };

        fetchUserData();
    }, [idProdutor]); // Corrigido para usar idProdutor como dependência

    const addOrUpdateStock = async () => {
        const url = `${URL}/webland-api/admin/updateStock`;

        const data = {
            email: useremail,
            acornType: selectedSpecies,
            quantity: quantity,
            price: price,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSuccessModalOpen(true);
            } else {
                console.error('Failed to add/update stock');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
        onClose();
    };

    return (
        <>
            {isOpen && (
                <div className="add-bolota-modal-overlay" style={{ zIndex: 100 }}>
                    <div className="col-3 add-bolota-modal-content">
                        <button className="add-bolota-modal-close" onClick={onClose}>
                            X
                        </button>

                        <div className="add-bolota-modal-title"> {bolota}</div>
                        <div className="col-12 add-bolota-modal-form">
                            <div className="add-bolota-modal-title">Alterar</div>
                            <div className="col-12 add-bolota-modal-form">
                                <div className="add-bolota-dropdown">
                                    <div
                                        className="add-bolota-dropdown-toggle"
                                        onClick={() => setDropdownOpen(!dropdownOpen)}
                                    >
                                        {selectedSpecies || 'Selecione uma espécie'}
                                        <RiArrowDropDownLine size={15} />
                                    </div>
                                    {dropdownOpen && (
                                        <div className="add-bolota-dropdown-options">
                                            {userData && userData[0]?.species.map((option) => ( // Corrigido para verificar se userData existe antes de acessar a propriedade 'species'
                                                <div
                                                    key={option}
                                                    className="add-bolota-dropdown-option"
                                                    onClick={() => {
                                                        setSelectedSpecies(option);
                                                        setDropdownOpen(false);
                                                    }}
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 input-group-item">
                                    <div className="label">
                                        <label className="col-4" style={{ textAlign: "left" }}>Quantidade</label>
                                        <input
                                            className="col-6 add-bolota-input"
                                            type="number"
                                            placeholder="Nova Quantidade (kg)"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </div>
                                    <div className="label">
                                        <label className="col-4" style={{ textAlign: "left" }}>Preço</label>
                                        <input
                                            className="col-6 add-bolota-input"
                                            type="number"
                                            placeholder="Novo Preço (€/kg)"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-bolota-modal-buttons">
                                <button
                                    className="col-12 default-button"
                                    onClick={addOrUpdateStock}
                                    disabled={!quantity || !price}
                                >
                                    Alterar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {successModalOpen && (
                <SuccessModal
                    isOpen={successModalOpen}
                    onClose={closeSuccessModal}
                    message="Stock alterado com sucesso!"
                    title="Stock"
                />
            )}
        </>
    );
};

export default EditBolotaDesktop;
