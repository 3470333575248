import React, { useState, useEffect, useContext } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
} from "react-icons/ri";
import { URL } from "../../config";
import { decrementCount } from "../../redux/messageSlice";
import { useDispatch } from "react-redux";
import Spinner from "../../assets/WeblandSpinner";

import "./mobile-notifications.css";
import { AuthContext } from "../AuthContext";

const MobileNotifications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7); // You can adjust this based on the screen height if needed
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [expandedNotificationIndex, setExpandedNotificationIndex] = useState(-1);
  const [messages, setMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {token} = useContext(AuthContext)


  const dispatch = useDispatch();

  // Dummy notifications data
  // const dummyNotifications = [
  //   {
  //     date: "2023-08-17",
  //     title: "Notification Title 1",
  //     content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //     isNew: true,
  //   },
  //   {
  //     date: "2023-08-18",
  //     title: "Notification Title 2",
  //     content:
  //       "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     isNew: false,
  //   },
  //   // Add more dummy notifications as needed
  // ];

 


  const fetchMessagesById = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/user/messages/${localStorage.getItem("userId")}`,  {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        }});
      const data = await response.json();
      if (data && Array.isArray(data) && data.length !== messages?.length) {
        setMessages(data);
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  useEffect(() =>{
    fetchMessagesById();
  }, [])

  function formatDate(inputDate) {   
    const [day, month, year] = inputDate.split('/');  
    const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${day} ${monthName}`;
  }

  const updateMessageReadStatus = async (messageId) => {
    try {
      const response = await fetch(`${URL}/webland-api/user/messages/${messageId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          read: true
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update read status.');
      }
      return response.json();
    } catch (error) {
      console.error("Error updating message read status:", error);
    }
  }
  

  const NotificationRow = React.memo(({ notification, index }) => {
    const isExpanded = expandedNotificationIndex === index;

    const handleExpand = async () => {
      // If the message is not already read, update its status
      if (!notification.read) {
        dispatch(decrementCount());
        const updatedMessage = await updateMessageReadStatus(notification._id);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === notification._id ? updatedMessage : msg
          )
        );
      }
      setExpandedNotificationIndex(isExpanded ? -1 : index);
   };
   

    return (
      <div
        className={`col-12 notification-row ${!notification.read ? "new-notification" : ""}`}
      >
          <div
            className="col-12 notification-summary"
            onClick={()=>handleExpand()}
          >
            <div className="col-3">{formatDate(new Date(notification.createdAt).toLocaleDateString())}</div>
            <div className="col-2" style={{ color: "var(--focus-color)" }}>{String(notification.type).toUpperCase()}</div>
            <div className="col-5">{notification.title}</div>

            <div className="col-2 notification-summary-right">
              <div className="btn-round-expand">

                <RiArrowDropDownLine size={15} />
              </div>
            </div>
          </div>
          {isExpanded && (
            <div className="col-10 notification-details">
              <div> {notification.content}</div>
            </div>
          )}
        </div>
    );
  });
  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleNotifications = messages?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(messages?.length / itemsPerPage);

  const pageRange = {
    start: Math.max(1, currentPage - 2),
    end: Math.min(totalPages, currentPage + 2),
  };

  return (
    <>
      <div className="col-12">
        <div className="title-wrapper">Mensagens</div>
      </div>
      <div className="notification-list-container">
        <div
          className=" mt-1 mb-2 p-2"
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "x-small",
            color: "var(--secondary-color)",
          }}
        >
          <div className="col-3">Data</div>
          <div className="col-2">Tipo</div>
          <div className="col-5" style={{ textAlign: "center" }}>
            Título
          </div>

          <div className="col-2 notification-summary-right"></div>
        </div>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <Spinner />
          </div>
        ) : (
          visibleNotifications?.map((notification, index) => (
            <div
              style={{
                animation: `fadeIn ease 0.5s ${index * 0.05}s forwards`,
                opacity: 0,
              }}
            >
              <NotificationRow
                key={notification._id || index}
                notification={notification}
                index={index}
              />
            </div>
          ))
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          {currentPage > 1 && (
            <button
              className="page-button-arrow"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <RiArrowDropLeftLine size={65} />
            </button>
          )}
          {Array.from(
            { length: pageRange.end - pageRange.start + 1 },
            (_, i) => {
              const page = pageRange.start + i;
              return (
                <button
                  key={page}
                  className={`page-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              );
            }
          )}
          {currentPage < totalPages && (
            <button
              className="page-button-arrow"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <RiArrowDropRightLine size={65} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default MobileNotifications;
