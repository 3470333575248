import React, { useState, useEffect, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//import { getproductss, updateproductsStatus } from "./api"; // API functions to interact with the backend
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import { useDispatch } from "react-redux";
import { addProduct } from "../../redux/basketSlice";
import AddItemModal from "./AddItemModal";
import "./DesktopMarket.css";
import { AuthContext } from "../AuthContext";

const MarketClient = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [dataRates, setRatesData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const {token} = useContext(AuthContext)

  const dispatch = useDispatch();

  const validProductIds = useMemo(() => {
    return new Set(dataRates.map(rate => rate.product._id));
  }, [dataRates]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleEdit = () => {};

  const handleCancel = () => {
    setSelectedProduct(null)
  };

  const history = useHistory();

const fetchProducts = async () => {
  try {
    const response = await fetch(`${URL}/webland-api/product/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    const data = await response.json();
    setProducts(data);
    setIsLoading(false);
  } catch (error) {
    console.error(error);
    setIsLoading(false);
  }
};

  const fetchRates = async () => {
    try {
      const ratesResponse = await fetch(`${URL}/webland-api/rates/conversion-rate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!ratesResponse.ok) {
        throw new Error(`HTTP error! Rates status: ${ratesResponse.status}`);
      }
  
      const dataRates = await ratesResponse.json();
      setRatesData(dataRates);  
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error state appropriately
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (dataRates.length > 0) {
      console.log('Updated dataRates:', dataRates);
      
     
    }
  }, [dataRates]);

  useEffect(() => {
    // This effect filters products based on dataRates
    const filtered = products.filter(product =>
        dataRates.some(rate => rate.product._id === product._id)
    );
    setFilteredProducts(filtered);
}, [products, dataRates]);
  

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchProducts(), fetchRates()]);
   
      setIsLoading(false);
    };
  
    setIsLoading(true);
    fetchData();
  }, []);
  

  const handleUpdateStatus = async (productsId, newStatus) => {
    // await updateproductsStatus(productsId, newStatus); // Replace with your API call to update products status
    //fetchproductss();
  };

  const ProductDetail = ({
    product,
    disabled,
    isEditing,
    onEdit,
    onCancel,
    onSave,
    onInputChange,
  }) => {
    const handleCloseModal = () => {
      setShowModal(false);
    };

    console.log('product:', product.type, disabled)

    const handleAddToBasket = () => {
      const productWithQuantity = {
        ...product,
        quantity: quantity,
      };
      setShowModal(true);
      dispatch(addProduct(productWithQuantity));
    };

    const increaseQuantity = () => {
      setQuantity(quantity + 1);
    };

    const decreaseQuantity = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    const handleQuantityChange = (event) => {
      const newQuantity = parseInt(event.target.value, 10);
      if (!isNaN(newQuantity)) {
        setQuantity(newQuantity);
      }
    };

    return (
      <div className="col-12">
        {isLoading ? (
          <div className="market-container-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="market-title-wrapper" style={{ fontSize: "22px" }}>
              {String(product.type).toUpperCase()}
            </div>
            <div className="market-product-wrapper">
              <p className="market-description">{product.description}</p>
              <p className="market-package">Embalagem: {product.packageType}</p>
              <p className="market-dimensions">
                Dimensão: {product.dimensions}
              </p>
              <p className="market-price">
                Preço: <b>{product.price}€/uni</b>
              </p>
              <div className="market-quantity-controls">
                <label htmlFor="market-quantity">Quantidade</label>
                <div className="market-input-group">
                  <input
                    className="market-quantity-input"
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  <div className="market-quantity-buttons">
                    <span
                      className="market-quantity-button-control"
                      onClick={increaseQuantity}
                    >
                      <div style={{ marginTop: "-0.3rem" }}>+</div>
                    </span>
                    <span
                      className="market-quantity-button-control"
                      onClick={decreaseQuantity}
                    >
                      <div style={{ marginTop: "-0.3rem" }}>-</div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 market-select-buttons">
                <button               
                
                  onClick={handleAddToBasket}
                  disabled={disabled}
                >
                  Adicionar
                </button>
                <button
              
                 
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </>
        )}
        {showModal && (
          <AddItemModal
            isOpen={showModal}
            onClose={handleCloseModal}
            title={String(product.type).toUpperCase()}
            message={"Artigo adicionado com sucesso!!!"}
          />
        )}
      </div>
    );
  };

  return isLoading ? (
    <div className="col-12 spinner-container-market">
      <Spinner />
    </div>
  ) : (
    <div className="col-12 market-desktop-container">
      <div className="col-4 market-product-selection">
        {products.map((product, index) => (
          <div
            key={index}
            className={"market-product-card" + (selectedProduct && selectedProduct._id === product._id? " selected" : "")}
            onClick={() => setSelectedProduct(product)}
          >
            <div className="market-product-image"></div>
            <div className="market-product-title">{product.type}</div>
            <button className="market-select-button"
           // disabled={!dataRates.some(rate => rate.product._id === product._id)}
            >
              Selecionar
            </button>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className="col-7 market-product-details">
          <div className="market-details-body">
            <ProductDetail product={selectedProduct}  disabled={!validProductIds.has(selectedProduct._id)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketClient;
