import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { URL } from "../../config";
import "./AddProduct.css";
import Spinner from "../../assets/WeblandSpinner";
import SuccessModal from "../Desktop/SuccessModal";
import { AuthContext } from "../AuthContext";

const AddProduct = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const history = useHistory();
  
  const [newProductData, setNewProductData] = useState({
    type: "",
    description: "",
    packageType: "",
    price: "",
    weight: "",
    certification: "",
    nutritionalValue: "",
    expirationDate: "",
    origin: "",
    prv_pvp: "",
    stock: "",
    EAN_13: "",
    ITF_14: "",
    carbonFootprint: "",
    imageData: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleNewProduct = async () => {
    const formData = new FormData();
    for (const key in newProductData) {
      formData.append(key, newProductData[key]);
    }
console.log("bodu",formData)
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${URL}/webland-api/product/addproducts`, options);
      if (!response.ok) {
        throw new Error("Failed to add product");
      }
      setSuccessMessage("Produto criado com sucesso!");
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setIsLoading(false);
  }, [imagePreview]);

  return isLoading ? (
    <div className="col-12 spinner-container-market">
      <Spinner />
    </div>
  ) : (
    <div className="admin-market-wrapper">
      <div className="col-12 card-table-header" style={{ background: "var(--secondary-color)" }}>
        NOVO PRODUTO
      </div>

      <div className="col-12 admin-card-table-body"  style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <div className="table-scroll-container">
          <ProductDetail
            product={newProductData}
            onInputChange={handleInputChange}
            onSave={handleNewProduct}
            setImagePreview={setImagePreview} 
            imagePreview={imagePreview} 
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            title="Sucesso"
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};


const ProductDetail = ({ product, onInputChange, onSave ,setImagePreview,imagePreview}) => {
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Exibir a imagem selecionada
            const base64Image = reader.result;
            console.log(base64Image);
            setImagePreview(base64Image);
          };
          reader.readAsDataURL(file);
          // Atualizar o estado com os dados da imagem
          onInputChange({ target: { name: "imageData", value: file } });
        }
      };

      const base64ToImageUrl = (base64String, format) => {
        console.log("imagem ,",`${base64String}`)
        return `${base64String}`;
      };
      console.log("teste", imagePreview)
  return (
 
    <div className="col-12 product-detail-card" style={{ height:"50%",padding: "1.5rem" }}>
      <div className="col-4 product-image" style={{ height:"100%",padding: "1.5rem" }}>
        <div className="product-image-container" style={{ height: "20rem" , overflow:"hidden"}}>
        <form encType="multipart/form-data" >
          <div>
         
            <input type="file" onChange={handleImageChange} accept="image/*" />
          </div>
        </form>
        {product.imageData && (
            <div>
             
                        <img src={imagePreview} alt="Imagem selecionada"  width={"50%"}/>

            </div>
          )}
        </div>
        <div className="col-11 product-info-container"  style={{ marginTop:"2%"}}>
        <div className="market-input-group">
          <label>Tipo</label>
          <input
            type="text"
            name="type"
            value={product.type}
            //disabled={!isEditing}
            onChange={onInputChange}></input>
        </div>
        <div className="market-input-group">
          <label>Tipo de embalagem</label>
          <input
            type="text"
            name="packageType"
            value={product.packageType}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
        <div className="market-input-group">
          <label>Preço por Unidade</label>
          <input
            type="number"
            name="price"
            value={product.price}
            min={0}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
      
      <div className="market-input-group">
          <label>Peso</label>
          <input
            type="number"
            name="weight"
            value={product.weight}
            //disabled={!isEditing}
            min={0}
            onChange={onInputChange}
          />
        </div>
        <div className="market-input-group">
          <label>  stock
</label>
          <input
            type="Number"
            name="stock"
            value={product.stock}
            min={0}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
        
        <div className="market-input-group">
          <label>origem</label>
          <input
            type="text"
            name="origin"
            value={product.origin}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
        </div>
      </div>
      <div className="col-5 product-info-container">
        
        <div className="market-input-group" style={{height:"20%"}}>
          <label>Descrição</label>
          <textarea
            name="description"
            value={product.description}
            //disabled={!isEditing}
            onChange={onInputChange}
          ></textarea>
        </div>
       
       

        <div className="market-input-group">
          <label>certificação</label>
          <input
            type="text"
            name="certification"
            value={product.certification}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>


        <div className="market-input-group">
          <label>Valor nutricional</label>
          <input
            type="text"
            name="nutritionalValue"
            value={product.nutritionalValue}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>

       


        <div className="market-input-group">
          <label>prv_pvp</label>
          <input
            type="text"
            name="prv_pvp"
            value={product.prv_pvp}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
        
      

        <div className="market-input-group">
          <label>EAN_13</label>
          <input
            type="text"
            name="EAN_13"
            value={product.EAN_13}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>

        <div className="market-input-group">
          <label>ITF_14</label>
          <input
            type="text"
            name="ITF_14"
            value={product.ITF_14}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>
        <div className="market-input-group">
          <label>carbonFootprint</label>
          <input
            type="text"
            name="carbonFootprint"
            value={product.carbonFootprint}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>

        <div className="market-input-group">
          <label>expirationDate</label>
          <input
            type="text"
            name="expirationDate"
            value={product.expirationDate}
            //disabled={!isEditing}
            onChange={onInputChange}
          />
        </div>


      </div>

      <div className="col-1 product-action-buttons">
        <button className="btn-save" onClick={onSave}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default AddProduct;
