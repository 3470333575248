import React, { useState } from "react";
import "./mobile-client.css";
import { useSelector, useDispatch } from "react-redux";
import { selectBasket, removeProduct, clearBasket, updateQuantity  } from "../../redux/basketSlice";
import ConfirmModal from "./ConfirmModal";
import { FaRegTrashAlt } from "react-icons/fa";

const MobileBasket = () => {
  const [ShowConfirmModal, setShowConfirmModal] = useState(false);
  
  const basket = useSelector(selectBasket);
  const dispatch = useDispatch();


  const handleUpdateQuantity = (productId, newQuantity) => {
    if(newQuantity >=1)
    dispatch(updateQuantity({ productId, quantity: newQuantity }));
  };

  const handleRemoveProduct = (productId) => {
    // Dispatch the action to remove the product
    dispatch(removeProduct(productId));
  };

  const handleShowModal = () =>{
    setShowConfirmModal(true)
  }

  const handleCloseModal = () =>{
    setShowConfirmModal(false)
    dispatch(clearBasket());
  }


  const totalProductPrice = basket.products.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const totalIncludingDelivery = totalProductPrice + 12;

  return (
    <>
      <div className="col-12">
        <div className="title-wrapper">Encomenda</div>
      </div>
      {basket.products.length !== 0 ? (
        <>
          <div className="basket-container">
            {basket.products.map((item, index) => (
              <>
                <div className="basket-list" key={item._id}>
                  <div className="col-7 basket-list-info">
                    <p className="basket-list-title">
                      <span></span>
                      {String(item.type).toUpperCase()}
                    </p>
                    <div className="description">
                      <p
                        style={{
                          color: "var(--secondary-color)",
                          marginTop: "-0.2rem",
                        }}
                      >
                        Características
                      </p>
                      <div className="package">
                        Embalagem: {item.packageType}
                      </div>
                      <div className="dimensions">
                        Dimensão: {item.dimensions}
                      </div>
                      <div className="dimensions">Peso: </div>
                    </div>
                  </div>
                  <div className="col-3 basket-list-purchase">
                    <div>{item.price}€/uni</div>
                    <div>x {item.quantity}</div>
                    <div className="total">
                      Total:{" "}
                      <b style={{ fontSize: "22px" }}>
                        {item.price * item.quantity}€
                      </b>
                    </div>
                  </div>
                  <div className="order-control-buttons">

                  <button  className="quantity-item-btn" onClick={() => handleUpdateQuantity(item._id, item.quantity + 1)}>+</button>
                  <button  className="quantity-item-btn mt-1" onClick={() => handleUpdateQuantity(item._id, item.quantity - 1)}>-</button>
               
                  <button
                    className="remove-item-btn mt-3"
                    onClick={() => handleRemoveProduct(item._id)}
                    >
                    <FaRegTrashAlt color="white" size={13} />
                  </button>
                 
                    </div>
        
                </div>
                <br />
              </>
            ))}
          </div>

          <div className="basket-container">
            <div className="basket-list">
              <div className="col-7 basket-list-info">
                <p className="basket-list-title">
                  <span></span>
                  {String("entrega").toUpperCase()}
                </p>
                <div className="description">
                  <div
                    style={{
                      color: "var(--secondary-color)",
                      marginTop: "-0.2rem",
                    }}
                  >
                    Tempo estimado de entrega
                  </div>
                  <div className="package">8 Dias</div>
                  <div
                    style={{
                      color: "var(--secondary-color)",
                      marginTop: "-0.2rem",
                    }}
                  >
                    Custo de envio
                  </div>
                  <div className="package">12€</div>
                </div>
              </div>
              <div className="col-3 basket-list-purchase">
                <div className="total" style={{marginRight:"2.5rem"}}>
                  <div>Total: </div>
                  <b style={{ fontSize: "32px" }}>{totalIncludingDelivery}€</b>
                </div>
              </div>
            </div>
            <button className="col-12 default-button" style={{marginTop:"0rem"}} onClick={handleShowModal}>Confirmar</button>
          </div>
          {ShowConfirmModal && <ConfirmModal isOpen={ShowConfirmModal} onClose={handleCloseModal} title={""} message={"Encomenda registada com sucesso!!!"}/>}
        </>
      ) : (
        <div className="dashboard-container" style={{color:"var(--secondary-color)", fontSize:"larger"}}>Sem Encomendas</div>
      )}
    </>
  );
};

export default MobileBasket;
