import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useFilter } from "../../FilterContext";
import "./SideMenu.css";

const SidebarProducer = ({ isMobile }) => {
  const menuItems = [
    {
      title: "Produtor",
      subItems: [
        { title: "Stock",  subMenus: [/*"Stock", "Encomendas", "Conta"*/], },
        { title: "Encomendas",  subMenus: [/*"Stock", "Encomendas", "Conta"*/], },
        // { title: "Registar",  subMenus: [], },
      ],
    }, 
    {
      title: "Mensagens",
      subItems: [
        { title: "Caixa de entrada",  subMenus: [], },
        // { title: "Registar",  subMenus: [], },
      ],
    },  
    
  ];

  const [expandedMenus, setExpandedMenus] = useState([]);
  const [activeMenuGroup, setActiveMenuGroup] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState({});
  const [activeMenuItems, setActiveMenuItems] = useState(menuItems);

  // filtro the contexto para filtrar o mapa na dashboard
  const { setFilter } = useFilter();
  const history = useHistory();
  const location = useLocation();

  const applyFilter = (filterData) => {
    if(filterData) setFilter(filterData);

  };

  const filterMenu = window.location.pathname.length>=2 ? window.location.pathname.split("/")[2] : null;

  const toggleMenu = (menuItem) => {
     
    if (activeMenuGroup === menuItem) {
      // history.push(`/${String(menuItem).toLocaleLowerCase()}`);
      applyFilter(`/${String(menuItem).toLocaleLowerCase()}`)
      setActiveMenuGroup(null);
      setActiveSubMenu({});
    } else {
      // history.push(`/${String(menuItem).toLocaleLowerCase()}`);
      applyFilter(`/${String(menuItem).toLocaleLowerCase()}`)
      setActiveMenuGroup(menuItem);
      setExpandedMenus([menuItem]);
      setActiveSubMenu({});
    }
    
  };

  const toggleSubMenu = (menuItem, subMenu) => {

  
   
   subMenu.includes("Caixa de entrada") && history.push("/notifications")
   subMenu.includes("Encomendas") && history.push(`/encomendas?id=${localStorage.getItem("id")}`)
   subMenu.includes("Stock") && history.push(`/producer?id=${localStorage.getItem("id")}`)

    setActiveSubMenu((prevActive) => ({      
      [menuItem]: subMenu,
    }));
  };
  

  const activateNestedSubItem = (subItemTitle, nestedSubItem) => {
    const menuItem = Object.keys(activeSubMenu).find(
      (key) => activeSubMenu[key] === subItemTitle
    );

    // console.log(nestedSubItem)
     //nestedSubItem.includes("Encomenda") && history.push(`/encomendas?id=${localStorage.getItem("id")}`)
    // !nestedSubItem.includes("Registar") && history.push("/users")
    
    
    // history.push(
    //   `/${String(menuItem).toLocaleLowerCase()}/${String(
    //     subItemTitle
    //   ).toLocaleLowerCase()}/${String(nestedSubItem).toLocaleLowerCase()}`
    // );

    applyFilter(`/${String(menuItem).toLocaleLowerCase()}/${String(
      subItemTitle
    ).toLocaleLowerCase()}/${String(nestedSubItem).toLocaleLowerCase()}`)

    setActiveSubMenu((prevActive) => ({
      ...prevActive,
      [subItemTitle]: nestedSubItem,
    }));

    
  };

  const handleDefaultDashboard = () => {
    setActiveSubMenu({});
    history.push(`/producer?id=${localStorage.getItem("id")}`);
  }

  const isMenuExpanded = (menuItem) =>
    expandedMenus.includes(menuItem) && activeMenuGroup === menuItem;

  const isSubMenuActive = (menuItem, subMenu) =>
    activeSubMenu[menuItem] === subMenu; 
 
    
    useEffect(() => {
        const currentPath = location.pathname;
        if (filterMenu !== undefined) {
            let filteredMenuItems = menuItems.filter((item) =>
            item.title.toLowerCase().includes(filterMenu)
            ).slice(); 
            setActiveMenuItems(filteredMenuItems);
        }
       
      
        
        setActiveMenuItems(menuItems)
       
    }, [filterMenu, location]);
    
  
  return (
    <div className={`sidebar ${isMobile ? "mobile" : ""}`}>
      <div className="sidebar-title" onClick={handleDefaultDashboard}>PRODUTOR</div><br/>
      {activeMenuItems.map((menuItem, index) => (
        <div className="menu-item" key={index}>
          <div
            className={`menu-item-title expanded`}
            onClick={() => toggleMenu(menuItem.title)}
          >
            <span className="square-item"></span> {menuItem.title}
          </div>
          <div className={`submenu expanded`}>
            {menuItem.subItems.map((subItem, subIndex) => (
              <div key={subIndex}>
                <div
                  className={`submenu-item expanded ${isSubMenuActive(menuItem.title, subItem.title) ? "active" : ""}`}
                  onClick={() => toggleSubMenu(menuItem.title, subItem.title)}
                 
                >
                  {subItem.title}
                </div>
                <div
                  className={`nested-submenu expanded`}
                >
                  {subItem.subMenus.map((nestedSubItem, nestedIndex) => (
                    <span
                      key={nestedIndex}
                      className={`submenu-sub-item ${isSubMenuActive(subItem.title, nestedSubItem) ? "active" : ""}`}
                      onClick={() => activateNestedSubItem(subItem.title, nestedSubItem)}
                      style={{
                        marginTop: nestedSubItem.includes("Registar") ? "2rem" :"",
                        fontWeight:  nestedSubItem.includes("Registar") ? "bold" :""
                    }}
                    >
                      {nestedSubItem}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SidebarProducer;
