import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import { TbMapPinCheck, TbGps } from "react-icons/tb";
import GeolocalAddress from "./GeoLocalAddress";
import { components } from "react-select";
import MySelect from "./MySelect.js";
import makeAnimated from "react-select/animated";



const NIF_VALIDATION_URL = "http://www.nif.pt/?json=1&q=509442013&key=eaefd8cc15a7bc5283f12347bc0d131e";

const RegisterClient = ({ onClientInfoChange }) => {
  const [isNifValid, setIsNifValid] = useState(true);
  const [isNifAvailable, setIsNifAvailable] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isToFly, setIsToFly] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);

 
  const optionsSpecies = [
    { value: 'Industria_Alimentar', label: 'Indústria Alimentar' },
    { value: 'Industria_Quimica', label: 'Indústria Química' },
    { value: 'Retalhista', label: 'Retalhista' },
    { value: 'Restauracao', label: 'Restauração' },
    { value: 'Padaria_Pastelaria', label: 'Padaria/Pastelaria' },
    { value: 'Biomassa', label: 'Biomassa' },
    { value: 'Outra', label: 'Outra' },
  ];


  const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'left', height: '100%', gap:10 }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null} 
            style={{ margin: 0, padding:0 }}
          />
          <label style={{ margin: 0 }}>{props.label}</label>
        </div>
      </components.Option>
    );
  };
  

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const [producerInfo, setProducerInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    region: "",
    distrito: "",
    postalCode: "",
    country: "",
    phoneNumber: "",
    lat: "",
    long: "",
    category:"",
  });


  

  const handleProducerInfoChange = (info) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      ...info
    }));
  };

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "nif") {
      const isValidNif = /^\d{9}$/.test(value);
      setIsNifValid(isValidNif);
    }

    if (name === "phoneNumber") {
      const isValidPhoneNumber = /^\d{9}$/.test(parseInt(value));
      setIsValidPhone(isValidPhoneNumber);
    }

    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleAddressChange = (field, value) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [field]: value,
    }));
  };
  const handleCategory = (value) => {
    setProducerInfo((prevInfo) => ({
      ...prevInfo,
      category: value,
    }));
  };
  
  const handleSpecies = selected => {
    setOptionSelected(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      category: selectedSpecies, // Salvando a string no estado
    }));
  };
  
  


  useEffect(() => {
    onClientInfoChange(producerInfo);
  }, [producerInfo, onClientInfoChange]);

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Contactos
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={producerInfo.name}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">
          NIF:{" "}
          {isNifAvailable && isNifValid && producerInfo.nif !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.nif !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}
        </label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={producerInfo.nif}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      {/* <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={producerInfo.email}
          onChange={handleInputChange}
          className="form-control"
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="phoneNumber">Telefone:{isValidPhone && producerInfo.phoneNumber !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.phoneNumber !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={producerInfo.phoneNumber}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
        
      </div>
      <div className="col-6 " style={{  paddingLeft:"0rem"}}>
            <label htmlFor="role">Área de Atividade:</label>
            <div  className="col-12" style={{  paddingLeft:"0rem"}}>
            <MySelect required
options={optionsSpecies}
isMulti
closeMenuOnSelect={false}
hideSelectedOptions={false}
components={{ Option, MultiValue, animatedComponents }}

onChange={handleSpecies}
allowSelectAll={true}
value={optionSelected}
/>
</div>
      </div>

      <div className="title-register-wrapper mt-3 mb-2">
        <TbMapPinCheck
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Morada
      </div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
        {/* <span  
        className="gps-pin-location-button" 
        onClick={() => setIsToFly(prevIsToFly => !prevIsToFly)}
        style={{background: !isToFly && "lightgrey"}}
        >
          <TbGps size={25} color="white"/>
        </span> */}
        </h5>
        <h6 style={{color:"var(--secondary-color)"}}>Clique no marcador para preenchimento automático da morada</h6>
        <GeolocalAddress onProducerInfoChange={handleProducerInfoChange} flyToMyLocation={true}/>
      </div>

      

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={producerInfo.lat}
            onChange={(e) => handleAddressChange("lat", e.target.value)}
            className="form-control"
            disabled={true}
            style={{background:"white"}}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={producerInfo.long}
            onChange={(e) => handleAddressChange("long", e.target.value)}
            className="form-control"
            disabled={true}
            style={{background:"white"}}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={producerInfo.street}
          onChange={(e) => handleAddressChange("street", e.target.value)}
          className="form-control"
        />
      </div>
     
      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="postalCode">Código Postal:</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={producerInfo.postalCode}
            onChange={(e) => handleAddressChange("postalCode", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group"  style={{ marginLeft: "0.5rem" }}>
        <label htmlFor="city">Localidade:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={producerInfo.city}
          onChange={(e) => handleAddressChange("city", e.target.value)}
          className="form-control"
        />
      </div>
      </div>
      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="postalCode">Distrito:</label>
          <input
            type="text"
            id="distrito"
            name="distrito"
            value={producerInfo.distrito}
            onChange={(e) => handleAddressChange("distrito", e.target.value)}
            className="form-control"
          />
        </div>
       
      </div>
      <div className="gps-group-wrapper">
      <div className="form-group">
          <label htmlFor="country">Zona:</label>
          <input
            type="text"
            id="region"
            name="region"
            value={producerInfo.region}
            onChange={(e) => handleAddressChange("region", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="country">País:</label>
          <input
            type="text"
            id="country"
            name="country"
            value={producerInfo.country}
            onChange={(e) => handleAddressChange("country", e.target.value)}
            className="form-control"
          />
        </div>

       
      
      </div>
      



      
    </div>
  );
};

export default RegisterClient;

