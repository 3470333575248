import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/svg/LogoSVG";
import { AiOutlineKey } from "react-icons/ai";
import { URL } from "../../config";

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Extracting the token from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const isValidPasswordTest = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
    return regex.test(password);
  };

  const handlePasswordReset = async () => {
    if (!isValidPasswordTest(password)) {
      setErrorMessage(
        "Password deve conter pelo menos 6 caracteres, 1 Maiúscula, 1 Minuscula e 1 especial"
      );
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords não coincidem");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, newPassword: password }),
    };

    try {
      const response = await fetch(
        `${URL}/webland-api/user/reset-password`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        // Redirect or show success message
        history.push("/");
      } else {
        setErrorMessage(data.message || "Error resetting password");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="col-12 title-wrapper" style={{ marginTop: "5rem" }}>
        <Logo />
      </div>
      <div className="col-12 dashboard-container">
        <div className="title-wrapper" style={{ marginTop: "3rem" }}>
          <AiOutlineKey size={65} color="var(--success-color)" />
        </div>
        <h2 style={{ color: "var(--primary-color)" }}>Redefinição de Senha</h2>

        <div
          className="col-8 container p-3"
          style={{ color: "var(--secondary-color)", textAlign: "center" }}
        >
           
          <p>Por favor, introduza a sua nova senha abaixo:</p>
          <div className="form-group">
            <input
              type="password"
              placeholder="Nova senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
            />
            {password &&
              (isValidPasswordTest(password) ? (
                <span style={{ color: "green" }}>
                  &#x2713; {/* Checkmark */}
                </span>
              ) : (
                <span style={{ color: "red" }}>&#x2717; {/* Cross */}</span>
              ))}
            <br />
            <input
              type="password"
              placeholder="Confirme a senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control mt-2"
            />
            {confirmPassword &&
              (password === confirmPassword &&
              isValidPasswordTest(confirmPassword) ? (
                <span style={{ color: "green" }}>&#x2713;</span>
              ) : (
                <span style={{ color: "red" }}>&#x2717;</span>
              ))}
          </div>
          {errorMessage !=="" && <div className="error-message">
        {errorMessage}
    </div>}
        </div>
        <button className="col-8 default-button" onClick={handlePasswordReset}>
          Redefinir Senha
        </button>
      </div>
    </>
  );
};

export default ResetPassword;
