import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFilter } from "../../FilterContext";
import "./SideMenu.css";

const Sidebar = ({ isMobile }) => {
  const menuItems = [
    {
      title: "Produtores",
      subItems: [
        { title: "Azinheira", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Carvalho", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Sobreiro", subMenus: ["Norte", "Centro", "Sul"] },
      ],
    },
    {
      title: "Transformadores",
      subItems: [
        { title: "Alimentar", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Química", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Biorefinarias", subMenus: ["Norte", "Centro", "Sul"] },
      ],
    },
    {
      title: "Clientes",
      subItems: [
        { title: "Alimentar", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Química", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Retalho", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Restauração", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Padaria", subMenus: ["Norte", "Centro", "Sul"] },
        { title: "Biomassa", subMenus: ["Norte", "Centro", "Sul"] },
      ],
    },
  ];

  const [expandedMenus, setExpandedMenus] = useState([]);
  const [activeMenuGroup, setActiveMenuGroup] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState({});
  const [activeMenuItems, setActiveMenuItems] = useState(menuItems);
  const [activeButton, setActiveButton] = useState("dashboard");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    history.push(`/${buttonName}`);
  };

  // filtro the contexto para filtrar o mapa na dashboard
  const { setFilter } = useFilter();
  const history = useHistory();
  const location = useLocation();

  const applyFilter = (filterData) => {
    if (filterData) setFilter(filterData);
  
  };

  const filterMenu =
    window.location.pathname.length >= 2
      ? window.location.pathname.split("/")[2]
      : null;

  const toggleMenu = (menuItem) => {
    setExpandedMenus((prevMenus) => {
      if (prevMenus.includes(menuItem)) {
        // Remove menuItem from the expandedMenus if it's already included
        return prevMenus.filter((menu) => menu !== menuItem);
      } else {
        // Add menuItem to the expandedMenus if it's not included
        return [...prevMenus, menuItem];
      }
    });

    // Optional: Apply a filter or change URL as needed
    applyFilter(`/${menuItem.toLowerCase()}`);
  };

  const toggleSubMenu = (menuItem, subMenu) => {
    setActiveSubMenu((prevActive) => ({
      ...prevActive,
      [menuItem]: prevActive[menuItem] === subMenu ? null : subMenu, // Toggle the submenu state
    }));
    applyFilter(`/${menuItem.toLowerCase()}/${subMenu.toLowerCase()}`);
  };

  const activateNestedSubItem = (subItemTitle, nestedSubItem) => {
    const menuItem = Object.keys(activeSubMenu).find(
      (key) => activeSubMenu[key] === subItemTitle
    );

    // history.push(
    //   `/${String(menuItem).toLocaleLowerCase()}/${String(
    //     subItemTitle
    //   ).toLocaleLowerCase()}/${String(nestedSubItem).toLocaleLowerCase()}`
    // );

    applyFilter(
      `/${String(menuItem).toLocaleLowerCase()}/${String(
        subItemTitle
      ).toLocaleLowerCase()}/${String(nestedSubItem).toLocaleLowerCase()}`
    );

    setActiveSubMenu((prevActive) => ({
      ...prevActive,
      [subItemTitle]: nestedSubItem,
    }));
  };

  const handleDefaultDashboard = () => {
    setFilter({});
    setExpandedMenus([]);
    setActiveSubMenu({});
    history.push("/dashboard");
  };

  const isMenuExpanded = (menuItem) => {
    return expandedMenus.includes(menuItem);
  };

  const isSubMenuActive = (menuItem, subMenu) => {
    // Check if the current subMenu is active within the activeMenuGroup
    return activeSubMenu[menuItem] === subMenu;
  };

  useEffect(() => {
    if (filterMenu !== undefined) {
      let filteredMenuItems = menuItems
        .filter((item) => item.title.toLowerCase().includes(filterMenu))
        .slice();
      setActiveMenuItems(filteredMenuItems);
    } else setActiveMenuItems(menuItems);
  }, [filterMenu, location]);

  return (
    <div className={`sidebar ${isMobile ? "mobile" : ""}`}>
      <div className="sidebar-title" onClick={handleDefaultDashboard}>
        DASHBOARD
      </div>
      <br />
      {activeMenuItems.map((menuItem, index) => (
        <div className="menu-item" key={index}>
          <div
            className={`menu-item-title ${
              isMenuExpanded(menuItem.title) ? "expanded" : ""
            }`}
            onClick={() => toggleMenu(menuItem.title)}
          >
            <span className="square-item"></span> {menuItem.title}
          </div>
          <div className={`submenu expanded`}>
            {menuItem.subItems.map((subItem, subIndex) => (
              <div key={subIndex}>
                <div
                  className={`submenu-item ${
                    isMenuExpanded(menuItem.title) &&
                    isSubMenuActive(menuItem.title, subItem.title)
                      ? "active"
                      : ""
                  }`}
                  onClick={() => toggleSubMenu(menuItem.title, subItem.title)}
                >
                  {subItem.title}
                </div>
                <div
                  className={`nested-submenu ${
                    isSubMenuActive(menuItem.title, subItem.title)
                      ? "expanded"
                      : ""
                  }`}
                >
                  {subItem.subMenus.map((nestedSubItem, nestedIndex) => (
                    <span
                      key={nestedIndex}
                      className={`submenu-sub-item ${
                        isSubMenuActive(subItem.title, nestedSubItem)
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        activateNestedSubItem(subItem.title, nestedSubItem)
                      }
                    >
                      {nestedSubItem}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="sidebar-buttons">
        {/* <div className="sidebar-title" onClick={handleDefaultDashboard}>DASHBOARD</div> */}
        <div
          className={`sidebar-title ${
            activeButton !== "orders" ? "fade-in" : "fade-out"
          }`}
          onClick={() => history.push("/orders")}
        >
          Encomendas
        </div>
        <div className="sidebar-title" onClick={() => history.push("/market")}>
          Market
        </div>
        <div className="sidebar-title" onClick={() => history.push("/users")}>
          Utilizadores
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
