import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "./AddItemModal.css";
import { useHistory } from "react-router-dom";

const ConfirmModal = ({ isOpen, onClose, message, title }) => {
  const history = useHistory();

  const handleContinueShopping = () => {
    onClose();  // Close the modal
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    isMobile && history.push("/market");  // Navigate to /market
    !isMobile && history.push("/client-market");  // Navigate to /market
  };

  return (
    isOpen && (
      <div className="generic-success-modal-overlay">
        <div className="col-8 generic-success-modal-content">
          <div className="modal-title">{title}</div>
          <button className="generic-success-modal-close" onClick={onClose}>
            X
          </button>
          <AiOutlineCheckCircle size={65} color="var(--success-color)" />
          <div className="generic-success-message">{message}</div>
          <button
            className="col-12 default-button continue-btn"
            onClick={handleContinueShopping}
          >
            Continuar a comprar
          </button>
        </div>
      </div>
    )
  );
};

export default ConfirmModal;
