import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import "./MobileAccount.css";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";

const MobileAccountDetalhes = () => {
  const [userData, setUserData] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const {token} = useContext(AuthContext)

  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/producer/${localStorage.getItem("id")}`,  {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          }});
        const data = await response.json();
        // console.log(data);
        setUserData(data[0]);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div className="col-12">
        <div className="title-wrapper">Detalhes</div>
      </div>
      <div className="col-5 go-back-btn">
        <p onClick={() => history.push("/account")}>
          <MdArrowBackIosNew
            style={{
              position: "relative",
              top: "0.15rem",
              marginRight: "0.5rem",
            }}
          />
          Retroceder
        </p>
      </div>
      <div className="col-12 dashboard-container">
        <button
          className="col-10 btn-account-detalhes"
          onClick={() => setActiveSection("personalInfo")}
        >
          Informação pessoal
        </button>
        {activeSection === "personalInfo" && userData && (
            <div className="col-10 account-detalhes-wrapper">
            <p>Nome: {userData.name}</p>
            <p>Email: {userData.email}</p>
            {/* Add more personal details here as required */}
          </div>
        )}
        <button
          className="col-10 btn-account-detalhes"
          onClick={() => setActiveSection("address")}
        >
          Morada
        </button>
        {activeSection === "address" && userData && (
          <div className="col-10 account-detalhes-wrapper">
            <p>Rua: {userData.address.street}</p>
            <p>Cidade: {userData.address.city}</p>
            <p>Código Postal: {userData.address.zip}</p>
            {/* Add more address details here as required */}
          </div>
        )}
        <button
          className="col-10 btn-account-detalhes"
          onClick={() => setActiveSection("billingAddress")}
        >
          Morada de Faturação
        </button>
        {activeSection === "billingAddress" && userData && (
             <div className="col-10 account-detalhes-wrapper">
            <p>Rua: {userData.address.street}</p>
            <p>Cidade: {userData.address.city}</p>
            <p>Código Postal: {userData.address.zip}</p>
            {/* Add more billing address details here as required */}
          </div>
        )}
      </div>
    </>
  );
};

export default MobileAccountDetalhes;
