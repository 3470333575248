import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import GeolocalAddress from "../Mobile/GeoLocalAddress.js";
import MySelect from "../Mobile/MySelect.js";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { URL } from "../../config";


const EditClient = ({ userData, userId, onUpdateClient }) => {
  const [clientInfo, setClientInfo] = useState(userData);
  const [clientDataFetched, setClientDataFetched] = useState(false);
  const [optionSelectedClient, setoptionSelectedClient] = useState([]);


  
  const optionsSpeciesClient = [
    { value: 'Industria_Alimentar', label: 'Indústria Alimentar' },
    { value: 'Industria_Quimica', label: 'Indústria Química' },
    { value: 'Retalhista', label: 'Retalhista' },
    { value: 'Restauracao', label: 'Restauração' },
    { value: 'Padaria_Pastelaria', label: 'Padaria/Pastelaria' },
    { value: 'Biomassa', label: 'Biomassa' },
    { value: 'Outra', label: 'Outra' },
  ];

 
  const fetchClientData = async () => {
    try {
      console.log("userDta",localStorage)
      const response = await fetch(`${URL}/webland-api/client/clientemail/${userData.email}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setClientInfo(data);
        setClientDataFetched(true);
        console.log("resultado", data);
      } else {
        console.error("Erro na requisição:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    }
  };

 
  useEffect(() => {
    if (!clientDataFetched) {
      fetchClientData();
      console.log("Client Info:", clientInfo, clientDataFetched);
      console.log("Mapped Species Client:", mappedSpeciesClient);

    }
  }, [userData.email, clientDataFetched]);

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, [name]: value };
      onUpdateClient(updatedInfo); 
      return updatedInfo;
    });
  };

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  

  const handleSpeciesClient = (selected) => {
    setoptionSelectedClient(selected);
    const selectedSpecies = selected.map((item) => item.value);
    setClientInfo((prevInfo) => {
      const updatedInfo = { ...prevInfo, category: selectedSpecies };
      onUpdateClient(updatedInfo);
      return updatedInfo;
    });
    console.log("Espécies selecionadas:", selectedSpecies);
  };

  const mappedSpeciesClient = clientInfo?.category?.map(activity_sector => {
    return optionsSpeciesClient.find(option => option.value === activity_sector);
  });

  const animatedComponents = makeAnimated();

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Editar Cliente
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={clientInfo?.name || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">NIF:</label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={clientInfo?.nif || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Telefone:</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={clientInfo?.phoneNumber || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="col-6" style={{ paddingLeft: "0rem" }}>
        <label htmlFor="role">Área de Atividade:</label>
        <div className="col-12" style={{ paddingLeft: "0rem" }}>
        <MySelect
  required
  options={optionsSpeciesClient}
  isMulti
  closeMenuOnSelect={false}
  hideSelectedOptions={false}
  components={{ MultiValue, ...animatedComponents }}
  onChange={handleSpeciesClient}
  allowSelectAll={true}
  value={mappedSpeciesClient}
/>


        </div>
      </div>

      <div className="title-register-wrapper mt-3 mb-2">Morada</div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
        </h5>
        <h6 style={{ color: "var(--secondary-color)" }}>
          Clique no marcador para preenchimento automático da morada
        </h6>
        <GeolocalAddress
          onProducerInfoChange={(info) =>
            setClientInfo((prevInfo) => {
              const updatedInfo = { ...prevInfo, ...info };
              onUpdateClient(updatedInfo);
              return updatedInfo;
            })
          }
          flyToMyLocation={true}
        />
      </div>

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={clientInfo?.lat || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={clientInfo?.long || ""}
            onChange={handleInputChange}
            className="form-control"
            disabled={true}
            style={{ background: "white" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={clientInfo?.street || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="postalCode">Código Postal:</label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          value={clientInfo?.postalCode || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">Localidade:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={clientInfo?.city || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="region">Zona:</label>
        <input
          type="text"
          id="region"
          name="region"
          value={clientInfo?.region || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">País:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={clientInfo?.country || ""}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default EditClient;
