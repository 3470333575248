import React, { useState, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import SuccessModal from "./SuccessModal";
import "./AddBolotaDesktop.css";
import { URL } from "../../config";

const AddBolotaDesktop = ({ isOpen, onClose, onAdd }) => {
  const [selectedSpecies, setSelectedSpecies] = useState("Selecione a espécie");
  const [quantity, setQuantity] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [priceRangeData, setPriceRangeData] = useState({});
  const [userData, setUserData] = useState(null); // Variável para armazenar os dados do usuário
  const [idProdutor, setIdProdutor] = useState(null);




  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token"); // Supondo que o token esteja armazenado no localStorage
      if (!token) {
        console.error("Token de autorização não encontrado");
        return;
      }
  
      const response = await fetch(`${URL}/webland-api/admin/${idProdutor}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Authorization": `Bearer ${token}`, // Incluindo o token de autorização no cabeçalho da solicitação
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data); // Armazene os dados do usuário na variável
        console.log("data",data[0].email)
      } else {
        console.error("Falha ao buscar dados do usuário");
      }
    } catch (error) {
      console.error("Erro:", error);
    }
  };
 
  let speciesOptions = [];
if (userData !== null) {
  speciesOptions = userData[0]?.species || [];
}


 
  const handleAddClick = async () => {
    if (selectedSpecies && quantity && price) {
      try {
        const response = await fetch(`${URL}/webland-api/admin/addStock`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
           body: JSON.stringify({
            email:userData[0].email,
            acornType: selectedSpecies,
            quantity: parseFloat(quantity),
            price: parseFloat(price),
          }),
        });

        if (response.ok) {
          setSuccessModalOpen(true);
          onAdd(selectedSpecies, quantity, price);
          setQuantity("");
          setPrice("");
        } else {
          console.error("Failed to add stock");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setIdProdutor(id);

    const fetchPriceRangeData = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/admin/price-range`);
        if (response.ok) {
          const data = await response.json();
          setPriceRangeData(data); // Store price range data in state
          console.log(priceRangeData);
        } else {
          console.error("Failed to fetch price range data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchUserData();
    fetchPriceRangeData();
    setQuantity("");
    setPrice("");
    setSelectedSpecies("Selecione a espécie");
  }, [isOpen]);

  const isPriceValid = () => {
    if (!price || price === "") {
      return false;
    }
  
    const selectedSpeciesPriceData = priceRangeData[selectedSpecies];
    if (!selectedSpeciesPriceData) {
      return false; // Não há dados de preço para a espécie selecionada
    }
  
    const { bottomPrice, topPrice } = selectedSpeciesPriceData;
    const parsedPrice = parseFloat(price);
    return parsedPrice >= 0 && parsedPrice <= topPrice;
  };
  
 
  return (
    <>
      {isOpen && (
        <div className="add-bolota-modal-overlay" style={{zIndex:100}}>
          <div className="col-3 add-bolota-modal-content">
            <button className="add-bolota-modal-close" onClick={onClose}>
              X
            </button>

            <div className="add-bolota-modal-title">Bolota</div>
            <div className="col-12 add-bolota-modal-form">
              <div className="add-bolota-dropdown">
                <div
                  className="add-bolota-dropdown-toggle"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedSpecies}
                  <RiArrowDropDownLine size={15} />
                </div>
                {dropdownOpen && (
                  <div className="add-bolota-dropdown-options">
                    {speciesOptions.map((option) => (
                      <div
                        key={option}
                        className="add-bolota-dropdown-option"
                        onClick={() => {
                          setSelectedSpecies(option);
                          setDropdownOpen(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12 input-group-item">
                <input
                  className="add-bolota-input"
                  type="number"
                  min={0}
                  placeholder="Quantidade (kg)"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <input
                  className="add-bolota-input"
                  type="number"
                  min={0}
                  placeholder={
                    !selectedSpecies.includes("Selecione a espécie")
                      ? `${priceRangeData[selectedSpecies]/*.bottomPrice/*} - ${priceRangeData[selectedSpecies]/*.topPrice*/} (€/kg)`
                     : "-.- (€/kg)"
                  }
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="add-bolota-modal-buttons">
              <button
                className="col-12 default-button"
                onClick={handleAddClick}
               /* disabled={
                  selectedSpecies.includes("Selecione a espécie") ||
                  !quantity ||
                  !isPriceValid()
                }*/
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      )}
      <SuccessModal
        isOpen={successModalOpen}
        onClose={closeSuccessModal}
        message="Quantidade adicionada com sucesso!"
        title="Bolota"
      />
    </>
  );
};

export default AddBolotaDesktop;