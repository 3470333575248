import React, { useState, useContext, useEffect } from 'react';
import './Bolota.css';
import { AuthContext } from "../AuthContext";
import { URL } from "../../config";
import SuccessModal from '../Desktop/SuccessModal';
import ErrorModal from '../Desktop/ErrorModal';

import DeleteConfirmationModal from '../Desktop/DeleteModal';

const Certifications = () => {
  useEffect(() => {
    showBolota(); 
  }, []);

  const { token } = useContext(AuthContext); 
  
  const [bolotaData, setBolotaData] = useState({
    type: '', 
    activitySector: '',
    serviceType: '',
    name:'',
  });
  const [showModal, setShowModal] = useState(false); 
  const [showModalError, setShowModalError] = useState(false); 
  const [bolotaRanges, setBolotaRanges] = useState([]); 
  const [selectedRange, setSelectedRange] = useState(null);
  const [rangeToDelete, setRangeToDelete] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleUpdate = (range) => {
    setSelectedRange(range);
    /*setBolotaData({
      name: name.type,
      type: range.type,
      activitySector: range.activitySector,
      serviceType: range.serviceType
    });*/
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBolotaData({ ...bolotaData, [name]: value });
  };

  
  const renderCertificationTable = (title, certifications, hasAdditionalFields) => (
    <>
      <h3 style={{color:"var(--secondary-color)"}}>{title}</h3>
      <table className="settings-table">
        <thead>
          <tr>
            <th>Tipo de certificado</th>
            {hasAdditionalFields && <th>Setor de Atividade</th>}
            {/* {hasAdditionalFields && <th>Tipo de Serviço</th>} */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {certifications.map((certification) => (
            <tr key={certification._id}>
              {hasAdditionalFields && <td>{certification.type_service}</td>}
              {hasAdditionalFields && <td>{certification.activity_sector}</td>}
              {!hasAdditionalFields && <td>{certification.name}</td>}
              <td className='action-cell'>
                <button className="modify-btn" disabled>Modificar</button>
                <button className="delete-btn" style={{marginLeft:"0.5rem"}} onClick={() => handleDelete(certification._id)}>Apagar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
  
  const renderCertifications = () => {
    const produtores = bolotaRanges.filter(c => c.type === 'produtor');
    const transformadores = bolotaRanges.filter(c => c.type === 'transformador');
  
    return (<>
      <div className="conversion-rate-container mt-2" style={{display:"flex", flexDirection: "column"}}>
        {renderCertificationTable('Produtores', produtores, false)}
        </div>
        <div className="conversion-rate-container mt-2" style={{display:"flex", flexDirection: "column"}}>
        {renderCertificationTable('Transformadores', transformadores, true)}
      </div>
    </>
    );
  };

  const handleDelete = async (_id) => {
    console.log("handleDelete foi chamado.");

    try {
      const response = await fetch(`${URL}/webland-api/certificates/delete_certificates/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao excluir certificado');
      }


      
      
  
      // Atualize a lista de intervalos de preço após a exclusão
      showBolota();
      // Feche o modal de exclusão
      setShowModalDelete(false);
      // Limpa o estado rangeToDelete após a exclusão
      setRangeToDelete(null);
    } catch (error) {
      console.error('Erro ao excluir o certificado:', error.message);
    }
  };

  const showBolota = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/certificates/get_certificates`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao obter os certificados');
      }

      const responseData = await response.json();
      console.log("Certificados", responseData);
      setBolotaRanges(responseData); 
    } catch (error) {
      console.error('Erro ao obter os intervalos de preço de bolota:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${URL}/webland-api/certificates/add_certificates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify({
          name: bolotaData.name,
          type: bolotaData.type,
          activity_sector: bolotaData.activitySector,
          type_service: bolotaData.serviceType
        }),
      });
console.log("valores",)
      if (!response.ok) {
        throw new Error('Erro ao submeter o PriceRange');
      }

      setShowModal(true); // Exibe o modal de sucesso após a submissão bem-sucedida
      showBolota();
      // Limpa o estado do formulário após a submissão
      setBolotaData({
        name: '',
        type: '',
        activitySector: '',
        serviceType: ''
      });
    } catch (error) {
      console.error('Erro ao submeter o PriceRange:', error.message);
    }
  };

  return (
    <div>
      <div className="conversion-rate-container bolota-card mt-2">
        <form className='col-12 form-wrapper' onSubmit={handleSubmit}>
          <fieldset className='col-10 fieldset-wrapper' >
            <legend>TIPO DE CERTIFICAÇÕES</legend>
  
           
            <select
              id="type"
              name="type"
              value={bolotaData.type}
              onChange={handleInputChange}
            >
              <option value="">Selecione um tipo de Utilizador</option>
              <option value="produtor">Produtor</option>
              <option value="transformador">Transformador</option>
            </select>
  
            {bolotaData.type === "transformador" ? (


              <div style={{ display: "flex", flexDirection: "row" }}>
                <label htmlFor="activitySector">Setor de Atividade:</label>
                <select
              id="activitySector"
              name="activitySector"
              value={bolotaData.activitySector}
              onChange={handleInputChange}
            >
              <option value="">Selecione o sector</option>
              <option value="Indústria Alimentar">Indústria Alimentar</option>
              <option value="Indústria Química">Indústria Química</option>
              <option value="Biorrefinarias">Biorrefinarias</option>
            </select>
  
                <label htmlFor="serviceType" style={{ marginLeft: "5rem" }}>Nome do certificado:</label>
                <input
                  id="serviceType"
                  type="text"
                  name="serviceType"
                  value={bolotaData.serviceType}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <label htmlFor="name">Nome do certificado</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={bolotaData.name}
                  onChange={handleInputChange}
                />
              </div>
            )}
  
          </fieldset>
  
          <div className="col-2 actions">
            <button type="submit" className="remove">Adicionar</button>
          </div>
        </form>
      </div>
      {showModalError && (
        <ErrorModal
          isOpen={showModalError}
          onClose={() => setShowModalError(false)}
          message={"Este tipo de Parametro já existe. Insira outro tipo!"}
          title={"Erro"}
        />
      )}
      {showModal && (
        <SuccessModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          message={"Parâmetros definidos com sucesso"}
          title={"Certificados"}
        />
      )}
       <div className="conversation-rate-container">{renderCertifications()}</div>
    {showModalDelete && (
      <DeleteConfirmationModal
        isOpen={showModalDelete}
        onConfirm={handleDelete}
        onCancel={() => setShowModalDelete(false)}
        message={"Are you sure you want to delete this certification?"}
      />
    )}
    </div>
  );
      };
  
export default Certifications;
