import Spinner from "../../assets/WeblandSpinner";
import "./LoadingModal.css"
export const LoadingModal = ({ isOpen }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content-loading">
          <Spinner /> {/* This could be a simple CSS spinner or a component */}
          <br/>
          <p>A guardar informação...</p>
        </div>
      </div>
    );
  };
  