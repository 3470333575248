import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { URL } from "../../config";
import "./AddProduct.css";
import Spinner from "../../assets/WeblandSpinner";
import SuccessModal from "../Desktop/SuccessModal";
import { AuthContext } from "../AuthContext";
import { useLocation} from "react-router-dom";



const EditProduct = () => {
  //const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  //const { id } = useParams();
  
  const [product, setproduct] = useState({
    type: "",
    description: "",
    packageType: "",
    price: "",
    weight: "",
    certification: "",
    nutritionalValue: "",
    expirationDate: "",
    origin: "",
    prv_pvp: "",
    stock: "",
    EAN_13: "",
    ITF_14: "",
    carbonFootprint: "",
    imageData: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [productId, setproductId] = useState("");

  

  const handleEditProduct = async () => {
    const formData = new FormData();
    for (const key in product) {
      formData.append(key, product[key]);
    }

    const options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${URL}/webland-api/product/editproducts/${productId}`, options);
      if (!response.ok) {
        throw new Error("Failed to edit product");
      }
      setSuccessMessage("Produto editado com sucesso!");
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error editing product:", error);
    }
  };

  const handleCloseSuccessModal = () => {
    // Fechar o modal de sucesso e redirecionar para a página /market/list
    setIsSuccessModalOpen(false);
    history.push("/market/list");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setproduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {

   

    const options2 = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
       
      };
     // Acessa o objeto search da localização para obter os parâmetros da URL
     const searchParams = new URLSearchParams(location.search);
     // Recupera o valor do parâmetro "id"
     setproductId (searchParams.get("id"));
    const fetchProductData = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/product/${productId}`, options2);
        
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
        const productData = await response.json();
        setproduct(productData);
        //setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchProductData();
  }, [productId]);

  return( /*isLoading ? (
    <div className="col-12 spinner-container-market">
      <Spinner />
    </div>
  ) : (*/
    <div className="admin-market-wrapper">
      <div className="col-12 card-table-header" style={{ background: "var(--secondary-color)" }}>
        EDITAR PRODUTO
      </div>

      <div className="col-12 admin-card-table-body">
        <div className="table-scroll-container">
          <ProductDetail
            product={product}
            onInputChange={handleInputChange}
            onSave={handleEditProduct}
            setImagePreview={setImagePreview} 
            imagePreview={imagePreview} 
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={handleCloseSuccessModal}
             title="Sucesso"
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

const ProductDetail = ({ product, onInputChange, onSave ,setImagePreview,imagePreview}) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setImagePreview(base64Image);
      };
      reader.readAsDataURL(file);
      onInputChange({ target: { name: "imageData", value: file } });
    }
  };

  return (
    <div className="col-12 product-detail-card">
      <div className="col-3 product-image">
        <div className="product-image-container">
          <form encType="multipart/form-data" >
            <div>
              <input type="file" onChange={handleImageChange} accept="image/*" />
            </div>
          </form>
          {product.imageData && (
            <div>
              <img src={imagePreview} alt="Imagem selecionada" width={"50%"} />
            </div>
          )}
        </div>
      </div>
      <div className="col-7 product-info-container">
  <div className="market-input-group">
    <label>Tipo</label>
    <input
      type="text"
      name="type"
      value={product.type}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group" style={{height:"90%"}}>
    <label>Descrição</label>
    <textarea 
      name="description"
      value={product.description}
      onChange={onInputChange}
    ></textarea>
  </div>
  <div className="market-input-group">
    <label>Tipo de embalagem</label>
    <input
      type="text"
      name="packageType"
      value={product.packageType}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Preço por Unidade</label>
    <input
      type="number"
      name="price"
      value={product.price}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Peso</label>
    <input
      type="number"
      name="weight"
      value={product.weight}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Certificação</label>
    <input
      type="text"
      name="certification"
      value={product.certification}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Valor nutricional</label>
    <input
      type="text"
      name="nutritionalValue"
      value={product.nutritionalValue}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Data de validade</label>
    <input
      type="text"
      name="expirationDate"
      value={product.expirationDate}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Origem</label>
    <input
      type="text"
      name="origin"
      value={product.origin}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>prv_pvp</label>
    <input
      type="text"
      name="prv_pvp"
      value={product.prv_pvp}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>Stock</label>
    <input
      type="number"
      name="stock"
      value={product.stock}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>EAN_13</label>
    <input
      type="text"
      name="EAN_13"
      value={product.EAN_13}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>ITF_14</label>
    <input
      type="text"
      name="ITF_14"
      value={product.ITF_14}
      onChange={onInputChange}
    />
  </div>
  <div className="market-input-group">
    <label>CarbonFootprint</label>
    <input
      type="text"
      name="carbonFootprint"
      value={product.carbonFootprint}
      onChange={onInputChange}
    />
  </div>
</div>
<div className="col-1 product-action-buttons">
        <button className="btn-save" onClick={onSave}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default EditProduct;
