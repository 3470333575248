import React, { useState, useEffect } from "react";
import "./EditUserModal.css"; // Importe seu arquivo CSS

const EditUserModal = ({ user, onClose, onSave, producers }) => {
  const [editedUser, setEditedUser] = useState(user);
  const [editedProducer, setEditedProducer] = useState(producers); // Edite para buscar o produtor do usuário, se existir

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({
      ...editedUser,
      [name]: value,
    });
  };

  const handleProducerChange = (e) => {
    const { name, value } = e.target;
    setEditedProducer({
      ...editedProducer,
      [name]: value,
    });
  };

  const handleSave = () => {
    const editedUserWithProducer = { ...editedUser, producer: editedProducer };
    onSave(editedUserWithProducer);
    onClose();
  };

  useEffect(() => {
    setEditedUser(user);
    setEditedProducer(user.role === "producer" ? user.producer : {});
  }, [user]);

  return (
    <div className="edit-user-modal-container">
      <div className="edit-user-modal">
        <button className="close-button2" onClick={onClose}>
          X
        </button>
        <h2>Editar Utilizador</h2>
        <form>
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              name="username"
              value={editedUser.username}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={editedUser.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Tipo de utilizador:</label>
            <select
              id="role"
              name="role"
              required
              className="form-control"
              onChange={handleInputChange}
              value={editedUser.role}
            >
              <option value="producer">Produtor</option>
              <option value="client">Cliente</option>
            </select>
          </div>
          {console.log("teste produtor",editedProducer, producers, user)}
          {/*editedUser.role === "producer" && (
            <div className="form-group">
              <label htmlFor="name">Nome / Empresa:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={editedProducer.name}
                onChange={handleProducerChange}
                className="form-control"
              />
            </div>
          )*/}
        </form>
        <button onClick={handleSave}>Salvar</button>
        <button style={{ marginLeft: "5%" }} onClick={onClose}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default EditUserModal;
