import React, { useState, useEffect } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
} from "react-icons/ri";

import "./mobile-order.css";

const MobileOrder = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [expandedOrderIndex, setExpandedOrderIndex] = useState(-1);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dummyOrders = [
    {
      date: "2023-08-17",
      orderNumber: "ORD001",
      bolotaSpecie: "Espécie Carvalho",
      quantity: 150,
      transporter: "Transporter Name 1",
      pickHour: "09:00 AM",
      itemsPicked: 3,
      pickLocation: "Pick Location 1",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-17",
      orderNumber: "ORD001",
      bolotaSpecie: "Espécie Carvalho",
      quantity: 150,
      transporter: "Transporter Name 1",
      pickHour: "09:00 AM",
      itemsPicked: 3,
      pickLocation: "Pick Location 1",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-17",
      orderNumber: "ORD001",
      bolotaSpecie: "Espécie Carvalho",
      quantity: 150,
      transporter: "Transporter Name 1",
      pickHour: "09:00 AM",
      itemsPicked: 3,
      pickLocation: "Pick Location 1",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-17",
      orderNumber: "ORD001",
      bolotaSpecie: "Espécie Carvalho",
      quantity: 150,
      transporter: "Transporter Name 1",
      pickHour: "09:00 AM",
      itemsPicked: 3,
      pickLocation: "Pick Location 1",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-17",
      orderNumber: "ORD001",
      bolotaSpecie: "Espécie Carvalho",
      quantity: 150,
      transporter: "Transporter Name 1",
      pickHour: "09:00 AM",
      itemsPicked: 3,
      pickLocation: "Pick Location 1",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
    {
      date: "2023-08-18",
      orderNumber: "ORD002",
      bolotaSpecie: "Espécie Sobreiro",
      quantity: 200,
      transporter: "Transporter Name 2",
      pickHour: "10:30 AM",
      itemsPicked: 2,
      pickLocation: "Pick Location 2",
    },
  ];

  const OrderRow = ({ order, index }) => {
    const isExpanded = expandedOrderIndex === index;

    return (
      <div className="col-12 order-row">
        <div
          className="col-12 order-summary"
          onClick={() => setExpandedOrderIndex(index)}
        >
          <div className="col-12 order-summary-left">
            <div className="col-3">{order.date}</div>

            <div className="col-5">{order.bolotaSpecie}</div>
            <div className="col-2 order-summary-quantity">
              {order.quantity} kg
            </div>
            <div className="col-2">
              <div className="btn-round-expand-order">
                <RiArrowDropDownLine size={15} />
              </div>
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="col-10 order-details">
            <div>N. Encomenda: {order.orderNumber}</div>
            <div>Transportadora: {order.transporter}</div>
            <div>Hora levantamento: {order.pickHour}</div>
            <div>Dimensões: {order.itemsPicked}</div>
            <div>Local de carga: {order.pickLocation}</div>
          </div>
        )}
      </div>
    );
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleOrders = dummyOrders.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const totalPages = Math.ceil(dummyOrders.length / itemsPerPage);

  const pageRange = {
    start: Math.max(1, currentPage - 2),
    end: Math.min(totalPages, currentPage + 2),
  };

  return (
    <>
      <div className="col-12">
        <div className="title-wrapper">Encomendas</div>
      </div>
      <div className=" order-list-container">
        {visibleOrders.map((order, index) => (
           <div
           key={index}
           style={{
             animation: `fadeIn ease 0.5s ${index * 0.05}s forwards`,
             opacity: 0,
           }}
         >
          <OrderRow key={index} order={order} index={index} />
          </div>
        ))}
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button
            className="page-button-arrow"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <RiArrowDropLeftLine size={65} />
          </button>
        )}
        {Array.from({ length: pageRange.end - pageRange.start + 1 }, (_, i) => {
          const page = pageRange.start + i;
          return (
            <span
              key={page}
              className={`page-button ${currentPage === page ? "active" : ""}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </span>
          );
        })}
        {currentPage < totalPages && (
          <button
            className="page-button-arrow"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <RiArrowDropRightLine size={65} />
          </button>
        )}
      </div>
    </>
  );
};

export default MobileOrder;
