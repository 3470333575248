import React, { useMemo, useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMapEvents } from "react-leaflet";
import PortugalDistricts from "../../../data/DistritosPortugal.json";
import MapMarker from "./MapMarker";
import "leaflet/dist/leaflet.css";
import "./Map.css";





const Map = ({ locations, selectedMarker, onDistrictClick }) => {

  const [selectedDistrict, setSelectedDistrict] = useState("null");

  const setLayerStyles = (layer) => {
   
    layer.setStyle({
      fillColor: layer.feature.geometry.nome === selectedDistrict ? "var(--focus-color)": "var(--background-color)",
      fillOpacity: 0.75,
      weight: 0.5,
      color: "var(--primary-color)",
      interactive: true,
    });
  };
  
  
  const handleLayerEvents = (distrito, layer, onDistrictClick) => {
    layer.on({
      mouseover: (event) => {
        event.target.setStyle({
          fillColor: "var(--focus-color)",
          fillOpacity: 0.75,
        });
      },
      mouseout: (event) => {
        event.target.setStyle({
          fillColor: "var(--background-color)",
          fillOpacity: 0.9,
        });
      },
      click: (event) => {
     
        onDistrictClick(distrito.nome); 
        setSelectedDistrict(distrito.nome);
        event.target.setStyle({
          fillColor: "var(--focus-color)",
          fillOpacity: 0.75,
        });
      },
    });
  };
  
  
  const Distrito = (distrito, layer, onDistrictClick) => {
    setLayerStyles(layer);
    handleLayerEvents(distrito, layer, onDistrictClick);
  };

  const memoizedGeoJSONData = useMemo(() => PortugalDistricts.features, []);


  useEffect(() => {
    const map1Container = document.getElementById('map1');
    if (map1Container) {
      const tilePane = map1Container.getElementsByClassName('leaflet-tile-pane')[0];
      tilePane.style.webkitFilter = 'grayscale(100%) brightness(1.2) saturate(1.2)';
      tilePane.style.filter = 'grayscale(100%) brightness(1.2) saturate(1.2)';
    }

  }, []);

  return (
    <MapContainer id="map1" className="map1 map-wrapper" center={[39.6, -8]} zoom={7} >
      <GeoJSON data={memoizedGeoJSONData} onEachFeature={(feature, layer) => Distrito(feature, layer, onDistrictClick)} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {locations.map((location, index) => (
        <MapMarker key={index} location={location} markerHighlight={selectedMarker ? selectedMarker._id : ""} />
      ))}
    </MapContainer>
  );
};

export default Map;
