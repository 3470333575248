import React, { useState, useEffect, useContext } from "react";
import { TbLogout } from "react-icons/tb";
import { MdNotifications } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalExit from "./ModalExit";
import "./TopBar.css";
import { URL } from "../../config";

import { useSelector } from 'react-redux';
import { setMessages, selectUnreadMessageCount } from '../../redux/messageSlice';
import { AuthContext } from "../AuthContext";

const TopBar = () => {
  const initializeMessages = useSelector(setMessages);
  const unreadMessageCount = useSelector(selectUnreadMessageCount);
  const [showModal, setShowModal] = useState(false);
  const {token} = useContext(AuthContext)
  //const [newMessages, setNewMessages] = useState(unreadMessageCount?.payload.message.count);
  //console.log( initializeMessages)

  const history = useHistory();

  const handleExitClick = () => {
    setShowModal(true);
  };

  const handleConfirmExit = () => {
    window.location.pathname = "/login";
    localStorage.clear();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() =>{
    fetchMessagesById();
  }, [])


  const fetchMessagesById = async () =>{
    const messages = await fetch((`${URL}/webland-api/user/messages/${localStorage.getItem("userId")}`),  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      }});
    const data = await messages.json();
    //console.log(data)
    if (!data){
      const unreadMessagesCount = data?.filter(message => !message.read).length;
    }
    else return
    //setNewMessages(unreadMessagesCount);
  }
    
  return (
    <div className="col-12 top-bar">
      <div className="notification-icon slide-from-left">
        {unreadMessageCount !==0 && <span className="new-messages-indicator">{unreadMessageCount}</span>}
        <MdNotifications size={35} onClick={() => history.push("/notifications")} />
      </div>
      <div className="graphical-center-item"></div>
      <div className="exit-icon slide-from-right">
        <TbLogout size={32} onClick={handleExitClick} />
      </div>

      {showModal && (
        <ModalExit onClose={handleCloseModal} onConfirm={handleConfirmExit} />
      )}
    </div>
  );
};

export default TopBar;
