import React, { useState, useEffect, useContext } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./OrdersAdmin.css";

const OrdersAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState({});
  const [searchProducerTerm, setSearchProducerTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchCustomerTerm, setSearchCustomerTerm] = useState(""); 
  const { token } = useContext(AuthContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchClients();
  }, [orders]);

  useEffect(() => {
    // Aplicar filtro quando o termo de pesquisa, o termo de pesquisa do cliente ou do produtor, ou os pedidos mudarem
    filterOrders();
  }, [searchTerm, searchCustomerTerm, searchProducerTerm, orders, startDate, endDate]);
  
  

 
  useEffect(() => {
    // Aplicar filtro quando o termo de pesquisa ou os pedidos mudarem
    filterOrders();
   // console.log(orders.reverse().slice(0,1))
  }, [searchTerm, orders]);

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/order/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
   
      setOrders(data.reverse());
    } catch (error) {
      console.error(error);
    }
  };
  

  const fetchClients = async () => {
    setIsLoading(true); // Ensure loading is true when starting to fetch clients
    try {
      const clientIds = orders.map((order) => order.customer);
      const uniqueClientIds = [...new Set(clientIds)]; // Remove duplicates
      const clientsData = {};
  
      const clientPromises = uniqueClientIds.map(async (clientId) => {
        const response = await fetch(`${URL}/webland-api/client/client/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        clientsData[clientId] = data.name;
      });
  
      await Promise.all(clientPromises); // Wait for all client data to be fetched
      setClients(clientsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false only after all client info is fetched
    }
  };
  
  

  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      const response = await fetch(`${URL}/webland-api/order/${orderId}/status`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newStatus }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      fetchOrders();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filterOrders = () => {
    const filtered = orders.filter((order) => {
      const customerName = clients[order.customer] || ""; // Obter o nome do cliente usando o ID
      const producerName = order.selectedProducer?.producerId?.name || ""; // Obter o nome do produtor
      const status = order.status || "";
      const orderDate = new Date(order.orderDate);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;
  
      // Verificar se o termo de pesquisa é "All" ou se o status da encomenda inclui o termo de pesquisa
      const statusFilterCondition = searchTerm === "All" || status.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Aplicar filtro para nome do cliente, nome do produtor e datas
      const customerFilterCondition = customerName.toLowerCase().includes(searchCustomerTerm.toLowerCase()) || !searchCustomerTerm;
      const producerFilterCondition = producerName.toLowerCase().includes(searchProducerTerm.toLowerCase()) || !searchProducerTerm;
      const startDateFilterCondition = !startDateObj || orderDate >= startDateObj;
      const endDateFilterCondition = !endDateObj || orderDate <= endDateObj;
  
      // Retornar verdadeiro se todas as condições de filtro forem atendidas
      return statusFilterCondition && customerFilterCondition && producerFilterCondition && startDateFilterCondition && endDateFilterCondition;
    });
  
    setFilteredOrders(filtered);
  };
  
  
  
  
  

  // Array de possíveis estados
  const statusOptions = {
    All: "Todas",
    Processing: "Em Processamento",
    Shipped: "Enviado",
    Delivered: "Entregue"
  };

  return (
    
   
       <><div className="bolota-card" style={{ margin:0, marginRight: "1.5rem" }}>

      <fieldset className='col-12 fieldset-wrapper' style={{ width: "98%", display: "flex", gap: "1rem", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around" }}>
        <legend>FILTROS</legend>

        <div className="col-1 search-container" style={{ flexGrow: 0.5, marginRight: "1%" }}>
          <label htmlFor="stateSelector">Estado de Encomenda:</label>
          <select id="stateSelector" value={searchTerm} onChange={handleSearch} style={{ width: "100%" }}>
            <option value="" disabled>Selecione um estado...</option>
            {Object.keys(statusOptions).map((statusKey) => (
              <option key={statusKey} value={statusKey}>
                {statusOptions[statusKey]}
              </option>
            ))}
          </select>
        </div>

        <div style={{ flexGrow: 0.8, marginRight: "1%" }} className="col-1 client">
          <label htmlFor="clientSearch">Pesquisar por cliente:</label>
          <input
            id="clientSearch"
            type="text"
            value={searchCustomerTerm}
            onChange={(e) => setSearchCustomerTerm(e.target.value)}
            style={{ width: "100%" }} />
        </div>

        <div style={{ flexGrow: 0.8, marginRight: "1rem" }} className="col-1">
          <label htmlFor="producerSearch">Pesquisar por produtor:</label>
          <input
            id="producerSearch"
            type="text"
            value={searchProducerTerm}
            onChange={(e) => setSearchProducerTerm(e.target.value)}
            style={{ width: "100%" }} />
        </div>

        <div style={{ flexGrow: 0.5, marginRight: "1rem" }} className="col-1 client">
          <label htmlFor="startDate">Data de Início:</label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ width: "100%" }} />
        </div>

        <div style={{ flexGrow: 0.5, marginRight: "2rem" }} className="col-1 client">
          <label htmlFor="endDate">Data de Fim:</label>
          <input
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ width: "100%" }} />
        </div>
      </fieldset>



    </div><div className="card-table-header mt-3" style={{marginRight: "1.5rem", padding: "0.5rem 0rem" }}>

        <div className="col-12 card-table-title">Histórico de Encomendas</div>

      </div>
      
      <div className="card-table-body" style={{marginRight: "1.5rem", height:"62vh", alignItems:"start"}}>
      { isLoading ? <div className={"order-spinner"}><Spinner/> </div> :  <div className="table-scroll-container" style={{height:"100%"}}>
          <table className="user-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Produtor</th>
                <th>Cliente</th>
                <th>Preço Total</th>
                <th>Estado de Encomenda</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr key={order._id}>
                  <td>{String(order._id).toUpperCase()}</td>
                  <td>{order.selectedProducer?.producerId?.name}</td>

                  <td>{clients[order.customer]}</td>
                  <td style={{width:"3rem"}}>{order.totalAmount}€</td>
                  <td>
                    <select
                      value={order.status}
                      onChange={(e) => handleUpdateStatus(order._id, e.target.value)}
                    >
                      <option value="Processing">Em Processamento</option>
                      <option value="Shipped">Enviado</option>
                      <option value="Delivered">Entregue</option>
                    </select>
                  </td>
                  <td>{String(order.orderDate).split('T')[0]}</td>
                  <td>
                    <button 
                      style={{ padding: "0.3rem 1.0rem" , fontSize:"x-small"}} 
                      className="card"
                      onClick={ ()=> history.push(`/orders/details?id=${order._id}&client=${order.customer}`)}
                    >
                        Detalhes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>}
      </div>
      </>
       
     
   
  );
};

export default OrdersAdmin;
