import React, { useState, useEffect } from "react";
import "./ChangePasswordModal.css";
import SuccessModal from "./SuccessModal";
import { URL } from "../../config";

const ChangePasswordModal = ({ isOpen, onClose, onConfirm }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const isValidPasswordTest = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
    return regex.test(password);
  };

  const handleConfirmClick = async () => {
    setErrorMessage("");
    if (!isValidPasswordTest(newPassword)) {
      setErrorMessage(
        "Password deve conter pelo menos 6 caracteres, 1 Maiúscula, 1 Minuscula e 1 especial"
      );
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("As novas passwords não coincidem");
      return;
    }
    try {
      const response = await fetch(`${URL}/webland-api/user/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: localStorage.getItem("userId"),
          oldPassword: oldPassword,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        setSuccessModalOpen(true);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        console.error(data.error);
        alert(data.error);
      }
    } catch (error) {
      console.error("Erro na alteração da password:", error);
    }

    onClose();
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    onClose(); // Move this here to close the modal after success modal
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="col-9 change-password-modal-content">
            <div className="change-password-modal-cancel-btn">
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={onClose}
              >
                X
              </button>
            </div>
            <div className="modal-title">Alterar Password</div>
            <div className="modal-form">
              <label style={{ padding: "0rem 0.5rem" }}>Password antiga</label>
              <input
                type="password"
                placeholder="Password antiga"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
              <br />
              <br />
              <label style={{ padding: "0rem 0.5rem" }}>Nova password</label>
              <input
                type="password"
                placeholder="Nova Password"
                value={newPassword}
                onChange={(e) => {
                  setErrorMessage("");
                  setNewPassword(e.target.value);
                }}
              />
              <input
                type="password"
                placeholder="Confirmar nova Password"
                value={confirmNewPassword}
                onChange={(e) => {
                  setErrorMessage("");
                  setConfirmNewPassword(e.target.value);
                }}
              />
            </div>
            {errorMessage !== "" && (
              <div
                className="col-12 error-message"
                style={{ color: "white", padding: "0.5rem 0" }}
              >
                {errorMessage}
              </div>
            )}
            <div className="col-12 modal-buttons">
              <button
                className="col-12 default-button "
                onClick={handleConfirmClick}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      <SuccessModal
        isOpen={successModalOpen}
        onClose={closeSuccessModal}
        message="Password alterada!"
        title="Password"
      />
    </>
  );
};

export default ChangePasswordModal;
