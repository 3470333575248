import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './components/AuthContext';

function ProtectedRoute({ component: Component, allowedRoles, ...rest }) {
  const { isAuthenticated, user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props => {
        // Check if the user is not authenticated
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        // Check if the user is authenticated but does not have the required role
        else if (isAuthenticated && !allowedRoles.includes(user)) {
          return <Redirect to="/forbidden" />;
        }
        // User is authenticated and has the required role
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
