
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NotFoundPage = () => {
    const {user, logoutUser} = useContext(AuthContext);

    const history = useHistory();

    const handleLogout = () => {
        // logoutUser();
         history.goBack();
    };


    return (
        <div>
            <h1>404 Not Found</h1>
            <p>A página solicitada não existe</p>
            <button onClick={handleLogout}>Regressar </button>
        </div>
    );
}

export default NotFoundPage;
