import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { AiFillHome, AiOutlineMinusSquare } from "react-icons/ai";
import { MdOutlineAddBox, MdOutlineMinusBox } from "react-icons/md";
import "./Metrics.css";
import { AuthContext } from "../AuthContext";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

function ClientMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [clientData, setclientData] = useState({});
  const [clientId, setclientId] = useState(null);
  const [, dashboard, , type] = history.location.pathname.split("/");
  const [clientOrders, setClientOrders] = useState([{}]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersType, setOrdersType] = useState({
    processing: 0,
    shipped: 0,
    delivered: 0
  });
  const [ordersTypeFilter, setOrdersTypeFilter] = useState("");
  const [allClientOrders, setAllClientOrders] = useState([]);


  const { token } = useContext(AuthContext);

  useEffect(() => {
    const loadData = async () => {
      if (!clientId) {
        const urlParams = new URLSearchParams(window.location.search);
        const idFromURL = urlParams.get("id");
        if (idFromURL) {
          setclientId(idFromURL);
        } else {
          // Handle case where no ID is found
          setIsLoading(false);
          return;
        }
      }

      setIsLoading(true);
      try {
        // Wait for both the client data and orders to be fetched
        await Promise.all([
          fetchClientData(clientId),
          fetchClientOrders(clientId), // Ensure fetchClientOrders accepts clientId as argument
        ]);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [clientId]); // Depend on clientId to refetch if it changes

  useEffect(() => {
    const filterOrders = () => {
      setClientOrders(prevOrders => prevOrders.filter(order => {
        if (ordersTypeFilter === "") {
          return true;
        } else {
          return order.status.toLowerCase() === ordersTypeFilter;
        }
      }));
    };
  
    filterOrders();
  }, [ordersTypeFilter]); // Re-apply filter when ordersTypeFilter changes
  
  // This effect updates the orders count based on different statuses.
  useEffect(() => {
    const filteredOrders = allClientOrders.filter(order => {
      if (ordersTypeFilter === "") {
        return true;
      } else {
        return order.status.toLowerCase() === ordersTypeFilter;
      }
    });
  
    // Now set `clientOrders` to the filtered list of orders
    setClientOrders(filteredOrders);
    
    // This is where you'd also update your orders count
    setOrdersType({
      processing: allClientOrders.filter(order => order.status === "Processing").length,
      shipped: allClientOrders.filter(order => order.status === "Shipped").length,
      delivered: allClientOrders.filter(order => order.status === "Delivered").length,
    });
  
  }, [ordersTypeFilter, allClientOrders]); 

  const renderClient = () => {
    return (
      <>
     
          <div className="col-xl-4 col-md-12 col-sm-12" >
            <br />
            <div>
              <strong>{clientData[0]?.name}</strong>
            </div>
            <div>
              <strong>NIF:</strong> {clientData[0]?.nif}
            </div>
            <div>
              <strong>Email:</strong> {clientData[0]?.email}
            </div>
            <div>
              <strong>Telefone:</strong> {clientData[0]?.phoneNumber}
            </div>
            <br />
            <div>
              <strong>Morada:</strong> {clientData[0]?.address}
            </div>
            <div>
              <strong>Zona:</strong> {clientData[0]?.distrito}
            </div>

           
            <br />
          </div>
          <div className="col-xl-8 col-md-12 col-sm-12" style={{display: 'flex', flexDirection:"row", gap: "10px", justifyContent:"flex-end"}}>
            <div className="col-4 metrics-card" onClick={()=>setOrdersTypeFilter('processing')}>
            <p>Em processamento</p>
                <span>{ordersType.processing}</span>
            </div>
            <div className="col-4 metrics-card" onClick={()=>setOrdersTypeFilter('shipped')}>
            <p>Em trânsito</p>
                <span>{ordersType.shipped}</span>
            </div>
            <div className="col-4 metrics-card" onClick={()=>setOrdersTypeFilter('delivered')}>
                <p>Entregues</p>
                <span>{ordersType.delivered}</span>
            </div>
            <div className="col-4 metrics-card" onClick={()=>setOrdersTypeFilter('')}>
            <p>Total</p>
                <span>{ordersCount ? ordersCount : 0}</span>
            </div>


          </div>
      
      </>
    );
  };

  const fetchClientOrders = async (id) => {
    try {
      const orderResponse = await fetch(
        `${URL}/webland-api/order/orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const orders = await orderResponse.json();
      setOrdersCount(orders.length); // Update the orderCount for each client
      setClientOrders(orders.reverse());
      setAllClientOrders(orders);
           
        const processingCount = orders.filter((item) => item.status === "Processing").length;
        const deliveredCount = orders.filter((item) => item.status === "Delivered").length;
        const shippedCount = orders.filter((item) => item.status === "Shipped").length;


        setOrdersType({ 
        processing: processingCount,
        delivered: deliveredCount,
        shipped: shippedCount
        });

    } catch (orderError) {
      console.error("Error fetching order data:", orderError);
    }
  };

  const fetchClientData = (id) => {
    fetch(`${URL}/webland-api/client/client/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data === "object" && !Array.isArray(data)) {
          const dataArray = [data];

          const clienteWithType = dataArray.map((cliente) => ({
            ...cliente,
            //name: "cliente",
            // processingSteps: cliente.processingSteps ? cliente.processingSteps.map((item) => item.processingSteps) : [],
          }));

          console.log(clienteWithType);
          setclientData(clienteWithType);
        } else {
          // Trate o caso em que os dados não estão no formato esperado.
        }
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };

  return isLoading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <div>
      <div className="col-12 breadcrumb-wrapper">
        <span className="col-12 breadcrumb-item">
          <AiFillHome size={15} style={{ marginLeft: "0.5rem" }} />
        </span>
        <span
          className="col-12 breadcrumb-item"
          onClick={() => history.push("/dashboard")}
        >
          {String(dashboard).toUpperCase()} &gt;{" "}
        </span>
        <span
          className="col-12 breadcrumb-item"
          onClick={() => history.push("/dashboard/clientes")}
        >
          Clientes &gt;
        </span>
        <span className="col-12 breadcrumb-item">{clientId}</span>
      </div>
      <div className="col-12 producer-metrics-container" style={{display: 'flex', flexDirection: 'row'}}>
        {clientOrders && renderClient()}
      </div>
      <div className="row">
        <div className="orders-history-container">
          <h5 className="card-title">Histórico Encomendas</h5>
          <div className="warehouse-history-header">
            <div className="warehouse-history-cell">Data da Encomenda</div>
            <div className="warehouse-history-cell">N. Encomenda</div>
            <div className="warehouse-history-cell">Produtor</div>
            <div className="warehouse-history-cell">Qt.</div>
            <div className="warehouse-history-cell">Estado</div>
            <div style={{width:"4.5rem"}}></div>
          </div>
          <div className="warehouse-history-body">
            {!isLoading && clientOrders.length > 0 ? (
              clientOrders.map((item, index) => (
                <div key={index} className="warehouse-history-item">
                  <span className="warehouse-history-cell">
                    {new Date(item.orderDate).toLocaleDateString()}
                  </span>
                  <span className="warehouse-history-cell">{item._id}</span>
                  <span className="warehouse-history-cell">
                    {item?.selectedProducer?.producerId?.name}
                  </span>
                  <span className="warehouse-history-cell">
                    {item.totalAmount}
                  </span>
                  <span className="warehouse-history-cell">{item.status}</span>
                  <button
                        style={{
                          padding: "0.5rem 1.0rem",
                          fontSize: "x-small",
                        }}
                        className="card"
                        onClick={() => {
                          //toggleSelectedOrder(order._id);
                          history.push(
                            `/orders/details?id=${item._id}&client=${clientId}`
                          );
                          //handleSelection({ ...order, clients });
                        }}
                      >
                        Detalhes
                      </button>
                </div>
              ))
            ) : (
              <div
                className="warehouse-history-item"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "2rem 0rem",
                }}
              >
                O Cliente ainda não possui encomendas
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientMetrics;
