import React, { useState } from 'react';
import {MdOutlineDesktopAccessDisabled, MdOutlineMobileFriendly} from "react-icons/md"
import "./Client.css"

function ClientLayout() {
  const [showPopup, setShowPopup] = useState(true);
  

  const handleLogout = () => {
    localStorage.clear();
    window.location.pathname = "/login";
  };

  return (
    <div>
      <div className="warning-popup">
        <h1>TESTES - CLIENTE</h1>

        <button className="default-button" onClick={handleLogout}>
          OK
        </button>
      </div>
    </div>
  );
}

export default ClientLayout;
