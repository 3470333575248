import React, { useState, useEffect, useContext } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
import "./OrdersAdmin.css";
import MapRoute from "./Map/MapRoute";
import Spinner from "../../assets/WeblandSpinner";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrdersWithMap = () => {
  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState({});
  const [clientsObject, setClientsObject] = useState({});
  const [producerCoordinates, setProducerCoordinates] = useState({});
  const [transformerCoordinates, setTransformerCoordinates] = useState({});
  const [clientCoordinates, setClientCoordinates] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const [selectedOrderId, setSelectedOrderId] = useState(null); // State to track the selected order ID

  // Function to toggle the selected order ID
  const toggleSelectedOrder = (orderId) => {
    setSelectedOrderId(selectedOrderId === orderId ? null : orderId);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchCustomerTerm, setSearchCustomerTerm] = useState("");
  const [routeDistance, setRouteDistance] = useState(null)
  const { token } = useContext(AuthContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    fetchOrders();
  }, [location]);

  useEffect(() => {
    fetchClients();
  }, [orders]);

 

  useEffect(() => {
    // Aplicar filtro quando o termo de pesquisa ou os pedidos mudarem
    filterOrders();
  }, [searchTerm, orders]);


  
  const fetchOrders = async () => {
   
    console.log("status",status)
    
    
    try {
      const response = await fetch(`${URL}/webland-api/order/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if(status!=="all"){
      const filterData = data.reverse().filter((item) =>
        item.status.includes(status)
      );
      setOrders(filterData);}
else{
      setOrders(data);
      console.log("entrei cá",data)}
    } catch (error) {
      console.error(error);
    }
    
  };

  const fetchClients = async () => {
    try {
      const clientIds = orders.map((order) => order.customer);
      const clientsData = {};

      await Promise.all(
        clientIds.map(async (clientId) => {
          const response = await fetch(
            `${URL}/webland-api/client/client/${clientId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = await response.json();
          clientsData[clientId] = data.name;
          clientsObject[clientId] = data;
        })
      );
      setClients(clientsData);
      setClientsObject(clientsObject);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      const response = await fetch(
        `${URL}/webland-api/order/${orderId}/status`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newStatus }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      fetchOrders();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelection = (value) => {
   

    setProducerCoordinates({
      lat: value.selectedProducer.producerId?.lat,
      long: value.selectedProducer.producerId?.long,
      name: value.selectedProducer.producerId?.name,
      nif: value.selectedProducer.producerId?.nif,
      region: value.selectedProducer.producerId?.region,
      address: value.selectedProducer.producerId?.address
    });

    setTransformerCoordinates({
      lat: value.selectedTransformer?.transformerId.lat,
      long: value.selectedTransformer?.transformerId.long,
      name: value.selectedTransformer?.transformerId.name,
      nif: value.selectedTransformer?.transformerId.nif,
      region: value.selectedTransformer?.transformerId.region,
      address: clientsObject[value.customer].street
    })
    setClientCoordinates({
      lat: clientsObject[value.customer].lat,
      long: clientsObject[value.customer].long,
      name: clientsObject[value.customer].name,
      nif: clientsObject[value.customer].nif,
      region: clientsObject[value.customer].region,
      address: clientsObject[value.customer].address
    });
    setShowMap(true);
  };
  const filterOrders = () => {
    const filtered = orders.filter((order) => {
      const customer = order.customer || "";
      const status = order.status || "";
      return (
        customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredOrders(filtered);
  };

  const handleRouteData = (orderRoute) => {

    setRouteDistance(orderRoute)
  }

  // Array de possíveis estados
  const statusOptions = ["Processing", "Shipped", "Delivered"];

  return (
    <div
      style={{
        overflow: "hidden",
        height: "87vh",
        width: "98.5%",
        background: "white",
        borderRadius: "0.5rem",
      }}
    >
      <div className="col-12 map-card-table-header">
        Histórico de Encomendas
      </div>

      {isLoading ? (
        <div className={"order-spinner"}>
          <Spinner />{" "}
        </div>
      ) : (
        <div className="map-table-scroll-container">
          <table className="col-12 map-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Produtor</th>
                <th>Cliente</th>
                <th>Preço Total</th>
                <th>Estado de Encomenda</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <>
                  <tr
                    key={order._id}
                    onClick={() => {
                      toggleSelectedOrder(order._id);
                      handleSelection({ ...order, clients });
                    }}
                    className={
                      selectedOrderId === order._id ? "selected-row" : ""
                    }
                  >
                    <td>{String(order._id).toUpperCase()}</td>
                    <td>{order.selectedProducer?.producerId?.name}</td>

                    <td>{clients[order.customer]}</td>
                    <td>{order.totalAmount}€</td>
                    <td>
                      <select
                        value={order.status}
                        onChange={(e) =>
                          handleUpdateStatus(order._id, e.target.value)
                        }
                      >
                        <option value="Processing">Em Processamento</option>
                        <option value="Shipped">Enviado</option>
                        <option value="Delivered">Entregue</option>
                      </select>
                    </td>
                    <td>{String(order.orderDate).split("T")[0]}</td>
                    <td>
                      <button
                        style={{
                          padding: "0.5rem 1.0rem",
                          fontSize: "x-small",
                        }}
                        className="card"
                        onClick={() => {
                          //toggleSelectedOrder(order._id);
                          history.push(
                            `/orders/details?id=${order._id}&client=${order.customer}`
                          );
                          //handleSelection({ ...order, clients });
                        }}
                      >
                        Detalhes
                      </button>
                    </td>
                  </tr>
                  {selectedOrderId === order._id && (
                    <>
                      <tr
                        className={
                          selectedOrderId === order._id ? "selected-row" : ""
                        }
                        style={{ padding: 0, borderTop: 0 }}
                      >
                        <td colSpan="7" style={{ padding: 0, borderTop: 0 }}>
                          {" "}
                          {/* Adjust colspan to match your table */}
                          <MapRoute
                            producer={producerCoordinates}
                            transformer={transformerCoordinates}
                            customer={clientCoordinates}
                            routeData={handleRouteData}
                          />
                        </td>
                      </tr>
                      <tr
                        className={
                          selectedOrderId === order._id ? "selected-row" : ""
                        }
                        style={{ padding: 0, borderTop: 0 }}
                      ></tr>
                      <td style={{ padding: 0, borderTop: 0 }}>
                        <div className="distance-info">
                          {routeDistance && (
                            <div>
                              <div className="route-legend">
                                <span className="route-color producer-to-transformer"></span>
                                <span>Produtor ao Transformador</span>
                                <span className="route-distance">
                                  {routeDistance.producerToTransformer}
                                </span>
                              </div>
                              <div className="route-legend">
                                <span className="route-color transformer-to-customer"></span>
                                <span>Transformador ao Cliente</span>
                                <span className="route-distance">
                                  {routeDistance.transformerToCustomer}
                                </span>
                              </div>
                              <div className="route-legend">
                                <span className="route-color"></span>
                                <span>Distância Total</span>
                                <span className="route-distance">
                                  <b>{parseFloat(routeDistance.transformerToCustomer.split("km")[0]) + parseFloat(routeDistance.producerToTransformer.split("km")[0])} km</b>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrdersWithMap;
