import React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import ProducerIcon from "./ProducerIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import ClientIcon from "./Client.svg";
import TransformerIcon from "./TransformerIcon";
import { FaIndustry, FaTree, FaStreetView ,FaShoppingBasket   } from "react-icons/fa";


const getCustomIcon = (locationType, locationId, markerHighlight) => {
  const isSelected = locationId === markerHighlight;

  const baseStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: `2px solid ${isSelected ? '#ffc107' : 'var(--secondary-color)'}`,
    boxShadow: `2px 2px 5px 0px var(--secondary-color)`,
  };

  let iconComponent;

  switch (locationType) {
    case "produtor":
      iconComponent = (
        <div style={baseStyle}>
          <FaTree size={14} color={isSelected ? 'var(--focus-color)' : 'green'} />
        </div>
      );
      break;

    case "transformador":
      iconComponent = (
        <div style={baseStyle}>
          <FaIndustry size={10} color={isSelected ? 'var(--focus-dark-color)' : 'var(--primary-color)'} />
        </div>
      );
      break;

    default: // Assuming 'client' and other types fall here
      iconComponent = (
        <div style={baseStyle} >
          <FaShoppingBasket  size={14} color={isSelected ? 'var(--focus-dark-color)' : '#ffc107'} />
        </div>
      );
      break;
  }
  const svgMarkup = renderToString(iconComponent);

  return L.divIcon({
    html: svgMarkup,
    iconSize: [30, 30],
    className: "custom-marker",
    popupAnchor: [-10, -10],
  });
};

const typeConverter = (value) =>{
  switch (value) {
    case "produtor": return "producer";
    case "transformador": return "transformadores";
    case "cliente": return "client"    
     
  
    default: return "transporter"
     
  }
}

const MapMarker = ({ location, markerHighlight }) => {
  const history=useHistory();
 
   console.log(typeConverter(location, markerHighlight))
  return (
    location.lat && location.long &&
    <Marker
      position={
        location.type.includes("produtor")
          ? [
              parseFloat(location.lat * 1.005 - 0.15),
              parseFloat(location.long * 0.9915),
            ]
          : [parseFloat(location.lat), parseFloat(location.long)]
      }
      icon={getCustomIcon(location.type, location._id, markerHighlight)}
    >
      <Popup className="map-popup-content-wrapper" keepInView={true}>
        <h3>{location.name}</h3>
        <div style={{ fontSize: "x-small" }}>{location.type}</div>
        <div className="col-12">
          <p>NIF: {location.nif}</p>
          <p>Zona: {location.region}</p>
          <p>Localidade: {String(location.address)}</p>
          <button className="default-button" onClick={() => history.push(`/dashboard/metrics/${typeConverter(location.type)}?id=${location._id}`)}>Selecionar</button>
        </div>
      </Popup>
    </Marker>
  );
};

export default MapMarker;
