import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import {useHistory} from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import {AiFillHome, AiOutlineMinusSquare} from "react-icons/ai";
import {MdOutlineAddBox, MdOutlineMinusBox,MdOutlineWarehouse, MdOutlineReadMore  } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import "./Metrics.css";
import { AuthContext } from "../AuthContext";
import "./OrderDetails.css";


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

function OrderDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [produtorData, setProdutorData] = useState({});
  const [orderId, setOrderId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [client, setClient] = useState({});

  
  const [, dashboard, , type] = history.location.pathname.split("/");

  const {token} = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const idFromURL = urlParams.get("id");
      const clientId = urlParams.get("client");
  
      console.log(idFromURL, clientId);
  
      if (idFromURL) {
        setOrderId(idFromURL);
  
        // Fetch in parallel since these operations are independent
        try {
          const orderPromise = fetchOrderById(idFromURL);
          const clientPromise = fetchClientById(clientId);
  
          // Await all promises to resolve in parallel
          await Promise.all([orderPromise, clientPromise]);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };
  
    fetchData();
  }, []);

  const fetchClientById = async (id) => {
    await fetch(`${URL}/webland-api/client/client/${id}` ,  {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
         
            setClient(data);
         
        })
        .catch((error) => {
          console.error("Error fetching producer data:", error);
          setIsLoading(false);
          setOrders(null);
        });
  }


  const fetchOrderById = async (id) => {
    await fetch(`${URL}/webland-api/order/details/${id}` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
       
        Array.isArray(data) && setOrders(data);
        !Array.isArray(data) &&  setOrders(null);
        console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
        setIsLoading(false);
        setOrders(null);
      });
  };


  const transformerDetails = orders[0]?.selectedTransformer?.transformerId;
  const producerDetails = orders[0]?.selectedProducer?.producerId;

  return isLoading ? (
    <div className={"order-spinner"}>
      <Spinner />{" "}
    </div>
  ) : (
    <div className="order-details-container">
      <Breadcrumb orderId={orderId} dashboard={dashboard} />
      <h2>Detalhes da Encomenda </h2>
      <div className="order-info">
        {/* Render customer information */}
        <div className="customer-info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <button
              className="info-button-detalhes"
              style={{ padding: "0.50rem 0.50rem" }}
              onClick={() =>
                history.push(`/dashboard/metrics/client?id=${client._id}`)
              }
            >
              ver +
            </button>
          </div>

          <h3>Cliente</h3>
          <p>{client.name}</p>
          <p>Nif: {client.nif}</p>
        </div>

        {/* Render product information */}
        <div className="product-info">
          <div style={{ marginTop: "5%" }}>
            {" "}
            <h3></h3>
          </div>
          <h3>Produtos</h3>
          {orders[0].products.map((product, index) => (
            <div key={index}>
              <p>Produto: {String(product.product?.type).toUpperCase()}</p>
              <p>Quantidade: {product.quantity}</p>
            </div>
          ))}
        </div>

        {/* ... similar sections for producer and transformer details ... */}

        <div className="customer-info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <button
              className="info-button-detalhes"
              style={{ padding: "0.50rem 0.50rem" }}
              onClick={() =>
                history.push(
                  `/dashboard/metrics/producer?id=${producerDetails._id}`
                )
              }
            >
              ver +
            </button>
          </div>

          <h3>Produtor</h3>
          <p>{producerDetails.name}</p>
          <p>Nif: {producerDetails.nif}</p>
        </div>

        <div className="order-total">
          <div style={{ marginTop: "5%" }}>
            {" "}
            <h3></h3>
          </div>
          <h3>Status</h3>
          <p>{orders[0].status}</p>
          <br />
          <br />
          <h3>Total da Encomenda</h3>
          <p>{orders[0].totalAmount}€</p>
        </div>

        <div className="customer-info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <button
              className="info-button-detalhes"
              style={{ padding: "0.50rem 0.50rem" }}
              onClick={() =>
                history.push(
                  `/dashboard/metrics/transformadores?id=${transformerDetails._id}`
                )
              }
            >
              ver +
            </button>
          </div>

          <h3>Transformador</h3>
          <p style={{ fontSize: "x-large" }}>
            <>{transformerDetails?.name}</>
          </p>
          <br />
          <p>Morada: {transformerDetails?.address}</p>
          <p>Distrito: {transformerDetails?.distrito}</p>
          <p>Email: {transformerDetails?.email}</p>
          {/* <p>Processos: {transformerDetails?.processType}</p> */}
          <p>Region: {transformerDetails?.region}</p>
          <div className="boolean-field">
            <div>
              <span>Secagem:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.secagem ? "yes" : "no"
                }`}
              >
                {transformerDetails?.secagem ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Descasque:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.descasque ? "yes" : "no"
                }`}
              >
                {transformerDetails?.descasque ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Limpeza:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.limpeza ? "yes" : "no"
                }`}
              >
                {transformerDetails?.limpeza ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Torra:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.torra ? "yes" : "no"
                }`}
              >
                {transformerDetails?.torra ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Moagem:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.moagem ? "yes" : "no"
                }`}
              >
                {transformerDetails?.moagem ? "Sim" : "Não"}
              </span>
            </div>
            <div>
              <span>Taninos:</span>
              <span
                className={`status-indicator ${
                  transformerDetails?.taninos ? "yes" : "no"
                }`}
              >
                {transformerDetails?.taninos ? "Sim" : "Não"}
              </span>
            </div>
          </div>
        </div>

        <div className="order-feedback">
          <div style={{ marginTop: "5%" }}>
            {" "}
            <h3></h3>
          </div>
          <h3>Feedback</h3>
          <p>{orders[0].feedback || "N/A"}</p>
        </div>
      </div>
      {/* Add any other details you need to display */}
    </div>
  );
}

const Breadcrumb = ({ orderId, dashboard }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="breadcrumb-wrapper">
      {/* <span className="breadcrumb-item" onClick={() => navigateTo('/')}>
        Home
      </span>
      <span> &gt; </span> */}
      <span className="breadcrumb-item" onClick={() => navigateTo('/orders')}>
        {dashboard ? dashboard.toUpperCase() : 'DASHBOARD'}
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item" onClick={() => navigateTo('/orders/map')}>
        Histórico
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item">
        {orderId}
      </span>
    </div>
  );
};





export default OrderDetails;
