import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import ProducerIcon from "../Admin/Map/ProducerIcon";
import { renderToString } from "react-dom/server";
import "./GeoLocalAddress.css";
import { isElementOfType } from "react-dom/test-utils";

const MAP_LAYER_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const GEOCODE_URL =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

  const regionMap = {
    'Viana do Castelo': 'Norte',
    'Braga': 'Norte',
    'Vila Real': 'Norte',
    'Bragança': 'Norte',
    'Porto': 'Norte',
    'Aveiro': 'Centro',
    'Guarda': 'Centro',
    'Viseu': 'Centro',
    'Coimbra': 'Centro',
    'Castelo Branco': 'Centro',
    'Leiria': 'Centro',
    'Lisboa': 'Sul',
    'Santarém': 'Sul',
    'Setúbal': 'Sul',
    'Beja': 'Sul',
    'Évora': 'Sul',
    'Portalegre': 'Sul',
    'Faro': 'Sul'
  };

const GeoLocalAddress = ({ onProducerInfoChange }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [fullAddress, setFullAddress] = useState({});
  const [isToFly, setIsToFly] = useState(true)
  const [address, setAddress] = useState({
    street: '',
    city: '',
    postalCode: '',
    country: ''
  });
  const [latLong, setLatLong] = useState({});
  useEffect(()=>{

    //console.log(isToFly)

  }, [isToFly])

  const setMarker = (mapClickInfo) => {
    setMarkerPosition(mapClickInfo.latlng);
    reverseGeoCoding(mapClickInfo.latlng);    
    //console.log(mapClickInfo.latlng);
  };

  const getRegionFromDistrict = (district) => regionMap[district] || 'Unknown';

  

  const reverseGeoCoding = async (coordinates) => {
    const data = await (
      await fetch(`${GEOCODE_URL}${coordinates.lng},${coordinates.lat}`)
    ).json();

    const addressLabel = data.address ? data.address.LongLabel : "Unknown";
    const district = data.address && data.address.Region ? data.address.Region : "Unknown";
    const region = regionMap[district] || "Unknown";

    setFullAddress({
      ...data.address,
      region: region,
    });
    setAddress(addressLabel);
    setLatLong(coordinates);
  };

  

  const getMarker = () => {
    if (markerPosition) {
      return (
        <Marker position={markerPosition} icon={getCustomIcon()}>
          <Popup offset={[0, -18]} className="font-weight-bold">
            {getStringMarkerPosition()}
            <button className="default-button" onClick={handlePassInfoToParent}>
              Definir Morada
            </button>
          </Popup>
        </Marker>
      );
    }
  };

  const getStringMarkerPosition = () => {
   // console.log(fullAddress)
   const region = getRegionFromDistrict(fullAddress.Region);
    return (
      <div style={{display:"flex", flexDirection:"column"}}>
        <span><b>Rua:</b> {fullAddress.Address}</span>
        <span><b>Cod. Postal: </b>{fullAddress.Postal}</span>
        <span><b>Localidade:</b> {fullAddress.City}</span>
        <span><b>Distrito:</b> {fullAddress.Region}</span>
        <br/>
        <span><b>Região:</b> {region}</span>
        <span><b>País:</b> {fullAddress.CntryName}</span>
        <br />
        <span><b>Coord. GPS:</b></span>{markerPosition.lat.toFixed(8) + ", " + markerPosition.lng.toFixed(8)}
      </div>
    );
  };

  const getCustomIcon = () => {
    const svgMarkup = renderToString(
      <ProducerIcon color={"var(--focus-dark-color)"} />
    );

    return L.divIcon({
      html: svgMarkup,
      iconSize: [40, 40],
      className: "custom-marker",
      popupAnchor: [-10, -10],
    });
  };
 

  const LocationMarker = ({ initialLocate }) => {
    const map = useMapEvents({
      click: (e) => {
        const latlng = e.latlng;
        reverseGeoCoding(latlng);
        setMarkerPosition(latlng);
        getMarker();
        setIsToFly(false);
        map.flyTo(latlng, 13);
      },
      locationfound: (e) => {
        if (initialLocate) {
          const latlng = e.latlng;
          if (!markerPosition || (Math.abs(markerPosition.lat - latlng.lat) > 0.001 || Math.abs(markerPosition.lng - latlng.lng) > 0.001)) {
            reverseGeoCoding(latlng);
            setMarkerPosition(latlng);
            getMarker();
            map.setView(latlng, 12);
            setIsToFly(false);
          }
        }
      },
      
    });
  
    useEffect(() => {
      if (initialLocate) {
        map.locate();
      }
    }, []);
  
    return null;
  };

  

  const handlePassInfoToParent = () => {
    const region = getRegionFromDistrict(fullAddress.Region);
    // Pass the information to the parent component
    onProducerInfoChange({
      street: fullAddress.Address,
      postalCode: fullAddress.Postal,
      region: region,
      distrito: fullAddress.Region,
      city: fullAddress.City,
      country: fullAddress.CntryName,
      lat: latLong.lat,
      long: latLong.lng,
    });
  };

  return (
    <MapContainer
      className="map-geocoder-wrapper"
      center={[39.6, -8]}
      zoom={7}
    >
      <TileLayer
        url={MAP_LAYER_URL}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker initialLocate={isToFly} />
      {getMarker()}
    </MapContainer>
  );
};

export default GeoLocalAddress;
