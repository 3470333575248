import React, { useState, useEffect } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
//import Spinner from "../../components/"; // Importe o componente Spinner, se aplicável
import { URL } from "../../config";
import { AiFillEdit, AiOutlineCheck } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { BiSolidUserDetail } from "react-icons/bi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useFilter } from "../../FilterContext";

import DeleteConfirmationModal from "./DeleteModal";
import "./UsersAdmin.css";
import RegisterModal from "./RegisterUser";
import EditUserModal from "./EditModal";

const UsersAdmin = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [producers, setProducer] = useState([]);
  const [filterValue, setFilterValue] = useState("")
  const [infoID, setinfoID] = useState([]);

  const filter = useFilter();

  const [usersdesactive, setUsersDesactive] = useState([]);
  const [userToEdit, setUserToEdit] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const history = useHistory();

  const fetchClientData = async (user) => {
    try {
      const response = await fetch(`${URL}/webland-api/client/clientemail/${user.email}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setinfoID(data);
        console.log("resultado", data);
      } else {
        console.error("Erro na requisição:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    }
  };


  const handleUserEdit = (editedUser) => {
    fetch(`${URL}/webland-api/user/${editedUser._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedUser),
    })
      .then((response) => {
        if (response.status === 200) {
          fetchUserData();

          console.log("Usuário editado com sucesso");
        } else {
          console.error("Erro ao atualizar o usuário");
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação para atualizar o usuário:", error);
      });
  };

  const handleProducerEdit = (editedProducer) => {
    console.log("Entrei cá");

    fetch(`${URL}/webland-api/producer/producer/${editedProducer._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedProducer),
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("");
          fetchProducerData();

          console.log("Usuário editado com sucesso");
        } else {
          console.error("Erro ao atualizar o usuário");
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação para atualizar o usuário:", error);
      });
  };

  const updateUserStatus = (user) => {
    fetch(`${URL}/webland-api/user/${user._id}/desactivate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active: user.active,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          // O usuário foi atualizado com sucesso no back-end
          console.log("Usuário atualizado com sucesso");

          // Recarregue a página para refletir as alterações
          window.location.reload();
        } else {
          // Lide com outros códigos de status, se necessário
          console.error("Erro ao atualizar o usuário");
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação para atualizar o usuário:", error);
      });
  };

  const handleStatusChange = (user, newStatus) => {
    console.log("User Active:", user.active);
    console.log("New Status:", newStatus);
    const updatedUsers = users.map((u) => {
      if (u._id === user._id) {
        u.active = newStatus === "Ativo";
      }
      return u;
    });

    if (user.active && newStatus === "false") {
      const updatedUsersAtivos = updatedUsers.filter((u) => u._id !== user._id);
      setUsers(updatedUsersAtivos);

      setUsersDesactive((prevUsersDesactive) => [...prevUsersDesactive, user]);
    } else if (!user.active && newStatus === "true") {
      const updatedUsersDesactive = usersdesactive.filter(
        (u) => u._id !== user._id
      );
      setUsersDesactive(updatedUsersDesactive);

      setUsers((prevUsers) => [...prevUsers, user]);
    }
    updateUserStatus(user);
  };

  const activateUser = (userId) => {
    console.log("Entrei cá");

    fetch(`${URL}/webland-api/user/${userId}/activate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const successMessage = "Usuário ativado com sucesso";
          setSuccessMessage(successMessage);

          const updatedUsers2 = users.map((user) => {
            if (user._id === userId) {
              return { ...user, active: true };
            }
            return user;
          });
          setUsers(updatedUsers2);

          const updatedUsers = usersdesactive.filter(
            (user) => user._id !== userId
          );
          setUsersDesactive(updatedUsers);

          console.log("Usuário ativado com sucesso");
          window.location.reload();
        } else if (response.status === 404) {
          console.error("Usuário não encontrado");
        } else {
          console.error("Erro ao ativar o usuário");
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação para ativar o usuário:", error);
      });
  };
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };



  const handleEditClick = (user) => {
    history.push(`/Edituser?id=${user._id}&email=${user.email}`);


    console.log("user",user)
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const requestData = {
        _id: userToDelete._id,
        email: userToDelete.email,
        role:userToDelete.role,
        
        // Adicione outros dados que deseja enviar aqui
      };
  
      fetch(`${URL}/webland-api/user/deleteUser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Usuário excluído com sucesso",requestData);
            fetchUserData();
            fetchUserDesactiveData();
          } else {
            console.error("Erro ao excluir o usuário",requestData);
          }
        })
        .catch((error) => {
          console.error("Erro na solicitação para excluir o usuário:", error);
        });
    }
    setShowDeleteModal(false);
  };
  



  const handleProducerId = () => {
    
  };

  const handleCancelDelete = () => {
    // Feche o modal sem realizar a exclusão.
    setShowDeleteModal(false);
  };
  const handleRegisterModalCancel = () => {
    setShowRegisterModal(false);
  };

  const handleRegisterModal = (user) => {
    console.log("entrei");
    setShowRegisterModal(true);
    setUserToEdit(user);
  };

  const checkRole = (key) => {
    switch (key) {
        case "clientes":
            return "client";
        case "produtores":
            return "producer";
        case "transformadores":
            return "transformer";
        case "administradores":
            return "admin";
        default:
            return "";
    }
};



  useEffect(() => {
console.log("selectedUser",selectedUser)
   
    fetchUserData();
    fetchUserDesactiveData();
    if(filter.filter[0]){

      const value= filter.filter.split("/")[3]
      const roleFilter = checkRole(value);
    
      console.log(value)
      console.log("teste",users)
      setFilterValue(roleFilter)
      console.log("roleFilter",roleFilter)
    }
    fetchClientData(selectedUser?.email);
  }, [filter,infoID]);

  const fetchProducerData = () => {
    fetch(`${URL}/webland-api/user/active-users`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setProducer(data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };



  const fetchUserData = () => {
    fetch(`${URL}/webland-api/user/active-users`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const fetchUserDesactiveData = () => {
    fetch(`${URL}/webland-api/user/desactive-users`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setUsersDesactive(data);
        } else {
        }
      })

      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  return (
    
      <>
      
        <div className="card-table-header" style={{marginRight:"1rem" }}>
          <div className="col-12 card-table-title" style={{justifyContent:"center"}}>
            <AiOutlineUserAdd
              size={20}
              style={{
                position: "relative",
                top: "0.3rem",
                marginRight: "0.5rem",

              }} />
            Utilizadores Ativos
          </div>
          {/* <div className="col-2 card-table-button">
      <button className="card-table-button-add">+</button>
</div>*/}
        </div>
        <div className="card-table-body mb-3" style={{height:"34vh", minHeight:"34vh", maxHeight:"34vh", marginRight:"1rem" }}>
          <div className="table-scroll-container" style={{columnSpan:"all"}}>
            <table className="user-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Tipo</th>
                  <th>Estado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {users?.filter(({ role }) => role.includes(filterValue)).map((user, index) => (
                  <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td className="role"><span role={user.role}>{user.role}</span></td>
                    <td>
                      <select
                        value={user.active ? "true" : "false"}
                        onChange={(e) => handleStatusChange(user, e.target.value)}
                      >
                        <option value="true">Ativo</option>
                        <option value="false">Desativado</option>
                      </select>
                    </td>
                    <td>
                      <AiFillEdit className="button-table-users check" size={18} onClick={() => handleEditClick(user)} />
                      <BsFillTrashFill className="button-table-users delete" size={14} onClick={() => handleDeleteClick(user)} />

                      {user.role === 'producer' && (
  <BiSolidUserDetail
    className="button-table-users info"
    size={18}
    onClick={() => history.push(`/producer?email=${user.email}`)}
  />
)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {isEditModalOpen && (
          <EditUserModal
            user={selectedUser}
            onClose={() => setIsEditModalOpen(false)} />
        )}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete} />
     
   
        
          <div
            className="card-table-header mt-1"
            style={{ backgroundColor: "#FFB006", marginRight:"1rem" }}
          >
            <div className="col-12 card-table-title">
              <AiOutlineUserAdd
                size={20}
                style={{
                  position: "relative",
                  top: "0.3rem",
                  marginRight: "0.5rem",
                }} />
              Utilizadores por Ativar
            </div>
            {/*<div className="col-2 card-table-button">
      <button className="card-table-button-add"  onClick={() => handleRegisterModal()} >+</button>
      {showRegisterModal && <RegisterModal  onClose2={handleRegisterModalCancel}/>}
    </div>*/}
          </div>

          <div className="card-table-body"  style={{height:"34vh", minHeight:"34vh", maxHeight:"34vh", marginRight:"1rem" }}>
            <div className="table-scroll-container">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Tipo</th>
                    <th>Estado</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {usersdesactive?.filter(({ role }) => role.includes(filterValue)).map((user, index) => (
                    <tr key={index}>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td className="role"><span role={user.role}>{user.role}</span></td>
                      <td>{user.active ? "Ativo" : "Desativado"}</td>
                      <td>

                        <FaCheck className="button-table-users-check" size={12} onClick={() => activateUser(user._id)} />


                        <ImCross className="button-table-users-delete" size={10} onClick={() => handleDeleteClick(user)} />
                       
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
            <DeleteConfirmationModal
              show={showDeleteModal}
              onClose={handleCancelDelete}
              onConfirm={handleConfirmDelete} />
         
      
      </div></>
    
  );
};

export default UsersAdmin;
